import { doc, serverTimestamp, updateDoc } from '@firebase/firestore';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import getOrgRef from 'src/utils/getOrgRef';

const ChoosePostResponsiblesDialog = ({ open, onClose, post }) => {
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const { setSnackbarState, snackbarState } = useSnackbar();
  const { employeesData } = useEmployeesData();

  const [responsibles, setResponsibles] = useState(
    post.responsible_employees || []
  );

  useEffect(() => {
    setResponsibles(post?.responsible_employees || []);
  }, [post.responsible_employees]);

  const handleSave = async () => {
    // Save only the doc_id of the selected employees in the post.responsible_employees
    post.responsible_employees = responsibles;
    const { knowledge_test_id } = post;
    const orgRef = getOrgRef(currentUser, selectedOrg);
    const postRef = doc(
      db,
      `orgs/${orgRef}/knowledgeTests/${knowledge_test_id}`
    );
    updateDoc(postRef, {
      responsible_employees: responsibles,
      responsibles_updated_at: serverTimestamp(),
      responsibles_updated_by: currentUser.user_doc_id
    }).then(() =>
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'success',
        msg: 'true'
      })
    );

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>בחירת מנהלי מבדק</DialogTitle>
      <Divider />
      <DialogContent>
        <Autocomplete
          multiple
          options={employeesData}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          renderOption={(props, option, { selected }) => (
            <li {...props} key={`responsible-employees-${option.doc_id}`}>
              <div
                style={{
                  display: 'flex',
                  // alignItems: 'center',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected}
                      onChange={() => {}}
                      name="checkedB"
                    />
                  }
                  label={
                    <div>
                      <div>{`${option.first_name} ${option.last_name}`}</div>
                      <div style={{ fontSize: '0.8em', color: 'gray' }}>
                        {`(${option.id})`}
                      </div>
                    </div>
                  }
                />
              </div>
            </li>
          )}
          value={employeesData.filter((employee) =>
            responsibles.includes(employee.doc_id)
          )}
          onChange={(event, newValue) => {
            setResponsibles(newValue.map((employee) => employee.doc_id));
          }}
          isOptionEqualToValue={(option, value) =>
            option.doc_id === value.doc_id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="בחר מנהלי מבדק"
              placeholder="הקלד שם עובד"
            />
          )}
          disableCloseOnSelect
        />
        <Divider sx={{ my: 2 }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          שמור
        </Button>
        <Button variant="outlined" onClick={onClose}>
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChoosePostResponsiblesDialog;

ChoosePostResponsiblesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  post: PropTypes.object
};
