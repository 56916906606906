import { InfoOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StyledListItem = ({ numOfPersons, roomType, roomPrice }) => {
  StyledListItem.propTypes = {
    numOfPersons: PropTypes.number,
    roomType: PropTypes.string,
    roomPrice: PropTypes.any
  };
  const { t } = useTranslation('translation', { keyPrefix: 'orgPage' });
  const ts = moment().toISOString();
  return (
    <ListItem
      secondaryAction={
        <Tooltip title={t('thePricesIsTheOrgsResponsibilty')}>
          <Icon color="action">
            <InfoOutlined />
          </Icon>
        </Tooltip>
      }
      sx={{
        width: '100%',
        px: 0,
        py: 2
      }}
    >
      <ListItemAvatar>
        <Stack justifyContent="flex-end" direction="row">
          {numOfPersons &&
            typeof numOfPersons === 'number' &&
            Array(numOfPersons).fill(
              <PersonOutlineOutlined
                key={`${uniqueId(ts)}-${roomPrice}${roomType}`}
                color="info"
                fontSize="small"
              />
            )}
        </Stack>
      </ListItemAvatar>
      <ListItemText
        sx={{ mx: 1 }}
        primary={roomType}
        secondary={
          typeof roomPrice === 'string' ? roomPrice : `${roomPrice} ש"ח`
        }
      />
    </ListItem>
  );
};

const PricningCard = ({ tag, tagPrices }) => {
  PricningCard.propTypes = {
    tagPrices: PropTypes.object,
    tag: PropTypes.string
  };
  const { t } = useTranslation('translation', { keyPrefix: 'orgPage' });
  // console.log(tagPrices);
  return (
    <Card
      sx={{
        flex: 1,
        border: 'unset',
        boxShadow: '2px 2px 20px rgba(2,2,2,0.15)',
        my: 2,
        '&:hover': {
          boxShadow: (theme) => theme.shadows[6]
        }
      }}
    >
      <CardHeader
        title={useTranslation().t(`orgTags.${tag}`)}
        sx={{
          py: 0,
          transform: 'translate(50%,-50%)',
          position: 'absolute'
        }}
        slotProps={{
          title: {
            sx: {
              backgroundImage: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
              width: '100%',
              minWidth: '100px',
              textAlign: 'center',
              color: (theme) => theme.palette.primary.contrastText,
              py: 1,
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[6],
              userSelect: 'none'
            }
          }
        }}
      />
      <CardContent>
        {tagPrices ? (
          <List sx={{ width: '100%' }}>
            {tagPrices &&
              tagPrices.single_room &&
              tagPrices.single_room.single && (
                <StyledListItem
                  numOfPersons={1}
                  roomPrice={
                    tagPrices &&
                    tagPrices.single_room &&
                    tagPrices.single_room.single
                      ? tagPrices.single_room.single
                      : t('orgDidntTellUs')
                  }
                  roomType={t('singleRoom')}
                  key={`single-room-prices-${tag}`}
                />
              )}
            <Divider width="50%" sx={{ margin: 'auto' }} />
            {tagPrices &&
              tagPrices.double_room &&
              tagPrices.double_room.single && (
                <StyledListItem
                  numOfPersons={2}
                  roomType={t('doubleRoom')}
                  roomPrice={
                    tagPrices &&
                    tagPrices.double_room &&
                    tagPrices.double_room.single
                      ? tagPrices.double_room.single
                      : t('orgDidntTellUs')
                  }
                  key={`double-room-prices-${tag}`}
                />
              )}
          </List>
        ) : (
          <Typography sx={{ mt: 3 }}>טרם נוספו מחירים</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PricningCard;
