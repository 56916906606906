import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc
} from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'src/_firebase/firebase';
import defaultProcesses from 'src/common/defaultOnBoardings';
import onBoardingProcessInit from 'src/components/Account/org/onBoardingProcessInit';
import jobsTitles from '../common/jobsTitles';

const handleAddDefaultOnBoardingProcessesPerJobForNewOrg = async (org_ref) => {
  const employeeProcessRef = collection(
    db,
    'orgs',
    org_ref,
    'onBoardingProcesses'
  );

  const promises = Object.keys(defaultProcesses).map((eachProcess) => {
    const jobLabelIndex = jobsTitles.findIndex((el) => el.name === eachProcess);
    const jobLabelToSave =
      jobLabelIndex > -1 ? jobsTitles[jobLabelIndex].label : eachProcess;

    return addDoc(employeeProcessRef, {
      type: 'jobProcess',
      jobs: [{ name: eachProcess }],
      process_name: `ברירת מחדל - ${jobLabelToSave || eachProcess}`,
      process: defaultProcesses[eachProcess] ?? onBoardingProcessInit
    }).catch((err) => {
      console.error('Error adding onboarding process:', err);
    });
  });

  // Run all addDoc calls in parallel
  await Promise.all(promises);
};

const handleCreateNewOrg = async (values, event) => {
  event.preventDefault();

  try {
    const userDocRef = doc(db, 'users', values.user_doc_id);
    const orgsCollectionRef = doc(db, 'orgs', values.user_doc_id);
    const sendNewOrgJoinEmail = httpsCallable(
      functions,
      'secondGensendNewOrgJoinEmail'
    );

    await handleAddDefaultOnBoardingProcessesPerJobForNewOrg(
      values.user_doc_id
    );

    const { id, org_name, phone, email } = values;
    await sendNewOrgJoinEmail({ org_id: id, org_name, phone, email });

    await setDoc(userDocRef, {
      ...values,
      type: 'org',
      created_at: serverTimestamp(),
      org_status: 'pending'
    });

    await setDoc(
      orgsCollectionRef,
      { org_ref: values.user_doc_id },
      { merge: true }
    );
  } catch (err) {
    console.error('Error creating new org:', err);
  }
};

export default handleCreateNewOrg;
