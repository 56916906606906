import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/_firebase/firebase';

const sendVerificationSMS = async (phone) => {
  const sendVerificationSMSFunction = httpsCallable(
    functions,
    'secondGensendVerificationSMS'
  );
  sendVerificationSMSFunction({ phone })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default sendVerificationSMS;
