import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import MgdalorSystemFeaturesCards from 'src/components/MgdalorManagementSystem/MgdalorSystemFeaturesCards';
import OurSystemHeroSection from 'src/components/MgdalorManagementSystem/OurSystemHeroSection';
import OurSystemIntroduction from 'src/components/MgdalorManagementSystem/OurSystemIntroduction';
import ScaleWithMgdalor from 'src/components/MgdalorManagementSystem/ScaleWithMgdalor';
import StackingCards from 'src/components/MgdalorManagementSystem/StackingCards';

const MgdalorManagementSystem = () => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Helmet>
        <title>{t('navbar.ourSystem')}</title>
      </Helmet>
      <Box sx={{ position: 'relative', height: 'auto', mx: 0 }} id="ourSystem">
        <OurSystemHeroSection />
        <OurSystemIntroduction />
        <ScaleWithMgdalor />
        <MgdalorSystemFeaturesCards />
        <StackingCards />
      </Box>
    </>
  );
};

export default MgdalorManagementSystem;
