import { Box, CircularProgress, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import theme from 'src/theme';

const ProgressComponent = ({
  value,
  innerText,
  color,
  size = 40,
  customSx = {},
  className
}) => {
  ProgressComponent.propTypes = {
    value: PropTypes.number,
    innerText: PropTypes.any,
    color: PropTypes.string,
    size: PropTypes.number,
    customSx: PropTypes.object,
    className: PropTypes.string
  };
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        ...customSx
      }}
      className={className}
    >
      <CircularProgress
        value={value}
        color={color}
        variant="determinate"
        sx={{ zIndex: 2 }}
        size={size}
      />
      <CircularProgress
        value={100}
        size={size}
        sx={{
          // transform: 'translate(-50%)',
          position: 'absolute',
          zIndex: 1,
          color: alpha(theme.palette.action.hover, 0.1)
        }}
        variant="determinate"
      />
      {typeof innerText === 'string' ? (
        <Box
          sx={{
            top: '2px',
            left: 0,
            // bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100%'
          }}
        >
          <Typography
            textAlign="center"
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ zIndex: 2, fontSize: '0.65rem' }}
          >
            {innerText}
          </Typography>
        </Box>
      ) : (
        innerText
      )}
    </Box>
  );
};

export default ProgressComponent;
