import {
  DeleteForeverOutlined,
  MoreVertOutlined,
  QueryBuilderOutlined,
  SettingsOutlined,
  VisibilityOutlined
} from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import IOSSwitch from 'src/components/shared/IOSSwitch/IOSSwitch';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import isAuthorized from 'src/utils/isAuthorized';

export default function CommunityPostItemMoreMenu({
  post,
  handleOpenEditDialog,
  handleOpenDeletePostDialog,
  isPublished,
  handleChangePublishState,
  handleOpenChooseKnowledgeTestVisibileForDialog,
  handleOpenChooseManagementResponsible,
  handleOpenEditPostDuration,
  iAmResponsible,
  isMyPost
}) {
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isAuth = useMemo(() => {
    if (currentUser) {
      const { type } = currentUser;
      if (type === 'org') return true;

      if (selectedOrg) {
        if (isAuthorized(currentUser, 'practices:write', selectedOrg)) {
          if (post.author_ref === currentUser.user_doc_id) return true;
          if (iAmResponsible) return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }, [currentUser, selectedOrg, post, iAmResponsible]);

  // Determine if menu should be shown based on post type
  const isKnowledgeTest = !!post.knowledgeTest;
  const canManagePost = isMyPost || currentUser?.type === 'org';
  const hasMenuItems = () => {
    if (isKnowledgeTest) {
      return isAuth || iAmResponsible;
    }

    if (!isKnowledgeTest && canManagePost && post.signature_required) {
      return true;
    }

    if (
      !post.signature_required &&
      currentUser &&
      (isMyPost || currentUser.type === 'org')
    ) {
      return true;
    }

    return false;
  };

  return (
    <Stack gap={0} direction="row" alignItems="center">
      {isKnowledgeTest ? (
        (isAuth || iAmResponsible) && (
          <Button
            onClick={() => handleOpenChooseKnowledgeTestVisibileForDialog(post)}
            endIcon={<VisibilityOutlined />}
            variant="outlined"
          >
            מי יראה?
          </Button>
        )
      ) : (
        <FormControlLabel
          checked={isPublished}
          sx={{ mx: 0 }}
          control={<IOSSwitch />}
          onChange={(e, newState) => handleChangePublishState(newState)}
          id={`${post.post_id}-post-publish-switch`}
        />
      )}
      {hasMenuItems() && (
        <IconButton
          id={`more-menu${post.post_id}-btn`}
          aria-controls={`more-menu${post.post_id}`}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertOutlined />
        </IconButton>
      )}

      {/* <IconButton
        id={`more-menu${post.post_id}-btn`}
        aria-controls={`more-menu${post.post_id}`}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertOutlined />
      </IconButton> */}
      {hasMenuItems() && (
        <Menu
          id={`more-menu${post.post_id}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {isKnowledgeTest &&
            currentUser &&
            (isMyPost || currentUser.type === 'org') && (
              <ListItemButton
                onClick={() => handleOpenChooseManagementResponsible(post)}
              >
                <ListItemIcon
                  sx={{ width: 'fit-content !important', minWidth: 40 }}
                >
                  <SettingsOutlined />
                </ListItemIcon>
                <ListItemText primary="מי יכול לנהל?" sx={{ minWidth: 60 }} />
              </ListItemButton>
            )}
          {!post.signature_required &&
            currentUser &&
            (isMyPost || currentUser.type === 'org') && (
              <ListItemButton onClick={() => handleOpenDeletePostDialog(post)}>
                <ListItemIcon
                  sx={{ width: 'fit-content !important', minWidth: 40 }}
                >
                  <DeleteForeverOutlined color="error" />
                </ListItemIcon>
                <ListItemText
                  primary="הסתרה/מחיקה"
                  sx={{ minWidth: 60, color: theme.palette.error.main }}
                />
              </ListItemButton>
            )}
          {!isKnowledgeTest && canManagePost && post.signature_required && (
            <ListItemButton onClick={() => handleOpenEditPostDuration(post)}>
              <ListItemIcon
                sx={{ width: 'fit-content !important', minWidth: 40 }}
              >
                <QueryBuilderOutlined />
              </ListItemIcon>
              <ListItemText primary="עדכון שעות פרסום" sx={{ minWidth: 60 }} />
            </ListItemButton>
          )}
        </Menu>
      )}
    </Stack>
  );
}

CommunityPostItemMoreMenu.propTypes = {
  post: PropTypes.object.isRequired,
  handleOpenEditDialog: PropTypes.func,
  handleOpenDeletePostDialog: PropTypes.func,
  isPublished: PropTypes.bool,
  handleChangePublishState: PropTypes.func,
  handleOpenChooseKnowledgeTestVisibileForDialog: PropTypes.func,
  handleOpenChooseManagementResponsible: PropTypes.func,
  handleOpenEditPostDuration: PropTypes.func,
  iAmResponsible: PropTypes.bool,
  isMyPost: PropTypes.bool
};
