import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import theme from 'src/theme';

const LeftImgRightTextStep = ({ img, title, text, chipImg, titleColor }) => {
  LeftImgRightTextStep.propTypes = {
    img: PropTypes.any,
    title: PropTypes.any,
    text: PropTypes.any,
    titleColor: PropTypes.any,
    chipImg: PropTypes.any
  };
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      key="first-step-container"
      container
      spacing={3}
      item
      md={12}
    >
      <Grid
        key="step-textPart"
        item
        xs={12}
        md={6}
        sx={{
          width: '90%',
          position: 'relative',
          borderRadius: 2,
          maxWidth: '450px !important',
          mr: !matchMdDown && 3,
          pl: matchMdDown && '18px !important',
          pr: !matchMdDown && 2
        }}
      >
        <Stack gap={1} sx={{ my: 2 }}>
          <Card
            sx={{
              //   background: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
              borderRadius: 4,
              maxWidth: 180,
              height: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              //   border: '2px solid #244c8b'
              border: `2px solid ${titleColor}`
            }}
          >
            <img
              alt="icon"
              //   src=""
              src={chipImg}
              style={{ width: '100%', height: 'calc(100% -24px)' }}
            />
          </Card>
          <Stack>
            <Typography sx={{ color: titleColor }} variant="h3">
              {title}
            </Typography>
            <Typography sx={{ textAlign: 'justify' }} variant="body1">
              {text}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        key="step-imgPart"
        item
        xs={12}
        md={6}
        sx={{
          height: 350,
          width: '90%',
          position: 'relative',
          borderRadius: 2,
          maxWidth: '450px !important',
          px: '0 !important'
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(135deg,#f53939,#fbcf33)',
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 0,
            borderRadius: 'inherit'
          }}
        />
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',
            zIndex: 1,
            transform: 'translate(-15px, 35px)',
            borderRadius: 'inherit'
          }}
          component="img"
          //   src=""
          src={img}
        />
      </Grid>
    </Grid>
  );
};

export default LeftImgRightTextStep;
