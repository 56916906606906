import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckOutlined,
  ContentCopyOutlined,
  DeleteOutlineOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grow,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { cloneDeep, isUndefined } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import theme from 'src/theme';
import getScoreOfQuestion from './getScoreOfQuestion';
import BooleanTypeQuestion from './QuestionsTypes/BooleanTypeQuestion';
import CheckboxTypeQuestion from './QuestionsTypes/CheckboxTypeQuestion';
import ImageTypeQuestion from './QuestionsTypes/ImageTypeQuestion';
import RatingTypeQuestion from './QuestionsTypes/RatingTypeQuestion';
import SignatureTypeQuestion from './QuestionsTypes/SignatureTypeQuestion';
import TextTypeQuestion from './QuestionsTypes/TextTypeQuestion';

const QuestionTitle = ({
  title,
  handleChangeQuestionTitle,
  pageIndex,
  questionIndex
}) => {
  QuestionTitle.propTypes = {
    title: PropTypes.string,
    handleChangeQuestionTitle: PropTypes.func,
    pageIndex: PropTypes.number,
    questionIndex: PropTypes.number
  };

  const headerId = `page${pageIndex}-question${questionIndex}`;

  return (
    <CardHeader
      title={title}
      sx={{ pb: 0 }}
      slotProps={{
        title: {
          className: 'contentEditable',
          contentEditable: true,
          suppressContentEditableWarning: true,
          sx: {
            width: 'fit-content',
            fontSize: '18px'
          },
          onBlur: (e) => {
            // console.log(e.textContent);
            const el = document.getElementById(headerId);
            handleChangeQuestionTitle(el.textContent);
          },
          id: headerId
        }
      }}
    />
  );
};

const SurveyQuestionElement = ({
  question,
  evalProcessValues,
  setEvalValues,
  pageIndex,
  questionIndex,
  surveyType
}) => {
  SurveyQuestionElement.propTypes = {
    question: PropTypes.object,
    evalProcessValues: PropTypes.object,
    setEvalValues: PropTypes.func,
    pageIndex: PropTypes.number,
    questionIndex: PropTypes.number,
    surveyType: PropTypes.string
  };

  const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCorrectAnswersModeActive, setCorrectAnswersMode] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const handleSetIsQuestionRequired = () => {
    let temp = { ...evalProcessValues };
    temp.pages[pageIndex].elements[questionIndex].isRequired =
      !temp.pages[pageIndex].elements[questionIndex].isRequired;
    temp = getScoreOfQuestion(temp.scoringMethod?.type || 'onlyRequired', temp);
    setEvalValues(temp);
  };

  const handleDuplicateQuestion = () => {
    let temp = { ...evalProcessValues };
    // Deep clone the question to duplicate
    const questionToDuplicate = cloneDeep(
      temp.pages[pageIndex].elements[questionIndex]
    );

    // Create a new unique timestamp for the duplicated question
    const timestamp = moment().unix();
    const newQuestionIndex = questionIndex + 1;

    // Create the duplicated question with updated name
    const duplicatedQuestionName = `question${newQuestionIndex}-page${pageIndex}-${timestamp}`;
    const duplicatedQuestion = {
      ...questionToDuplicate,
      name: duplicatedQuestionName
    };

    // Handle correct answers if they exist
    if (
      questionToDuplicate.correctAnswer &&
      Array.isArray(questionToDuplicate.correctAnswer)
    ) {
      // Map each correct answer to the new question index
      duplicatedQuestion.correctAnswer = questionToDuplicate.correctAnswer.map(
        (answer) => {
          // If the answer format is "p0-q1-ch2" style
          if (typeof answer === 'string' && answer.includes('-')) {
            const parts = answer.split('-');
            if (parts.length === 3) {
              // Keep the same choice index, but update page and question indices
              const choiceIndex = parts[2].replace(/\D/g, ''); // Extract numeric part
              return `p${pageIndex}-q${newQuestionIndex}-ch${choiceIndex}`;
            }
          }
          // If answer isn't in the expected format, return it unchanged
          return answer;
        }
      );
    } else if (questionToDuplicate.correctAnswer) {
      // Handle non-array correctAnswer (e.g., for boolean questions)
      duplicatedQuestion.correctAnswer = questionToDuplicate.correctAnswer;
    }

    // Insert the duplicated question after the original
    temp.pages[pageIndex].elements.splice(
      newQuestionIndex,
      0,
      duplicatedQuestion
    );

    // Update names for all questions in the page (for consistency)
    temp.pages[pageIndex].elements = temp.pages[pageIndex].elements.map(
      (questionEl, i) => {
        // Generate a unique name for each element based on its new position
        const questionName = `question${i}-page${pageIndex}-${timestamp}`;

        // Update choices array if it exists
        let updatedChoices = questionEl.choices;
        if (updatedChoices && Array.isArray(updatedChoices)) {
          updatedChoices = updatedChoices.map((choice, choiceIndex) => {
            if (
              choice.value &&
              typeof choice.value === 'string' &&
              choice.value.includes('-')
            ) {
              // Update choice values to reflect new question index
              return {
                ...choice,
                value: `p${pageIndex}-q${i}-ch${choiceIndex}`
              };
            }
            return choice;
          });
        }

        return {
          ...questionEl,
          name: questionName,
          choices: updatedChoices || questionEl.choices
        };
      }
    );

    // If needed, update correctAnswer for all questions to reflect their new names
    temp.pages[pageIndex].elements = temp.pages[pageIndex].elements.map(
      (questionEl, i) => {
        if (
          questionEl.correctAnswer &&
          Array.isArray(questionEl.correctAnswer)
        ) {
          return {
            ...questionEl,
            correctAnswer: questionEl.correctAnswer.map((answer) => {
              if (typeof answer === 'string' && answer.includes('-')) {
                const parts = answer.split('-');
                if (parts.length === 3) {
                  const choiceIndex = parts[2].replace(/\D/g, '');
                  return `p${pageIndex}-q${i}-ch${choiceIndex}`;
                }
              }
              return answer;
            })
          };
        }
        return questionEl;
      }
    );

    // Recalculate scoring
    temp = getScoreOfQuestion(temp.scoringMethod?.type || 'onlyRequired', temp);

    // Update the state
    setEvalValues(temp);
  };
  const handleDeleteQuestion = () => {
    const temp = { ...evalProcessValues };
    const pageToDeleteQuestionFrom = temp.pages[pageIndex];
    if (pageToDeleteQuestionFrom) {
      if (questionIndex === 0) {
        pageToDeleteQuestionFrom.elements.shift();
      } else if (
        questionIndex ===
        pageToDeleteQuestionFrom.elements.length - 1
      ) {
        pageToDeleteQuestionFrom.elements.pop();
      } else if (
        questionIndex > 0 &&
        questionIndex < pageToDeleteQuestionFrom.elements.length - 1
      ) {
        pageToDeleteQuestionFrom.elements.splice(questionIndex, 1);
      }

      // Update the names of the remaining questions
      pageToDeleteQuestionFrom.elements = pageToDeleteQuestionFrom.elements.map(
        (questionEl, i) => ({
          ...questionEl,
          name: `question${i}-page${pageIndex}-${moment().unix()}`
        })
      );

      // Update correct answers if necessary
      pageToDeleteQuestionFrom.elements.forEach((questionEl, i) => {
        if (questionEl.correctAnswer) {
          questionEl.correctAnswer = questionEl.correctAnswer.map((answer) => {
            const [pagePart, questionPart, choicePart] = answer.split('-');
            const choiceI = parseInt(
              choicePart.replace('ch', ''),
              10 // Add radix parameter
            );
            return `p${pageIndex}-q${i}-ch${choiceI}`;
          });
        }
      });

      setEvalValues(temp);
    }
  };

  const handleChangeQuestionTitle = (newTitle) => {
    const temp = { ...evalProcessValues };
    if (!newTitle.trim()) {
      temp.pages[pageIndex].elements[
        questionIndex
      ].title = `נא לכתוב כותרת לשאלה ${questionIndex + 1}`;
    } else {
      temp.pages[pageIndex].elements[questionIndex].title = newTitle.trim();
    }
    setEvalValues(temp);
  };
  const handleToggleQuestionGotComment = () => {
    const temp = { ...evalProcessValues };
    const questionToModifiy = temp.pages[pageIndex].elements[questionIndex];
    questionToModifiy.showCommentArea = !questionToModifiy.showCommentArea;
    if (questionToModifiy.showCommentArea) {
      questionToModifiy.commentText = 'דוגמא';
    } else {
      delete questionToModifiy.commentText;
    }
    setEvalValues(temp);
  };

  const toggleSetCorrectAnswersMode = () => {
    if (question.type === 'radiogroup' || question.type === 'checkbox') {
      if (isCorrectAnswersModeActive) {
        const tempVals = { ...evalProcessValues };
        const tempQ = { ...question };

        // Filter correct answers to ensure only valid ones remain
        const validCorrectAnswers = correctAnswers.filter((answer) =>
          tempQ.choices.some((choice) => choice.value === answer)
        );

        // If there are no valid answers left, set to empty array (or null/undefined if preferred)
        tempQ.correctAnswer =
          validCorrectAnswers.length > 0 ? validCorrectAnswers : [];

        tempVals.pages[pageIndex].elements[questionIndex] = tempQ; // Save updated question
        setEvalValues(tempVals); // Persist changes
      }
    } else if (question.type === 'boolean') {
      if (isCorrectAnswersModeActive) {
        const tempVals = { ...evalProcessValues };
        const tempQ = { ...question };

        // Similar handling for boolean type
        tempQ.correctAnswer = correctAnswers.length > 0 ? correctAnswers : [];

        tempVals.pages[pageIndex].elements[questionIndex] = tempQ;
        setEvalValues(tempVals);
      }
    }
    setCorrectAnswersMode(!isCorrectAnswersModeActive); // Toggle mode
  };

  const handleMarkAnswerAsCorrect = (checked, answerVal) => {
    if (question.type === 'radiogroup' || question.type === 'checkbox') {
      const newCorrectAnswer = [...correctAnswers];

      if (checked) {
        if (!newCorrectAnswer.includes(answerVal)) {
          newCorrectAnswer.push(answerVal);
        }
      } else {
        const index = newCorrectAnswer.indexOf(answerVal);
        if (index !== -1) {
          if (index === 0) {
            newCorrectAnswer.shift();
          } else if (index === newCorrectAnswer.length - 1) {
            newCorrectAnswer.pop();
          } else {
            newCorrectAnswer.splice(index, 1);
          }
        }
      }

      setCorrectAnswers(newCorrectAnswer);
    } else if (question.type === 'boolean') {
      // console.log(answerVal);
      const tempVals = { ...evalProcessValues };
      const tempQ = { ...question };

      tempQ.correctAnswer = correctAnswers;
      tempVals.pages[pageIndex].elements[questionIndex] = tempQ;
      setCorrectAnswers(answerVal);
    }
  };

  useEffect(() => {
    if (question && !isUndefined(question.correctAnswer)) {
      setCorrectAnswers(question.correctAnswer);
    }
  }, [question.correctAnswer]);

  return (
    <Card
      elevation={0}
      sx={{
        outline:
          isCorrectAnswersModeActive &&
          `2px dashed ${alpha(theme.palette.success.light, 0.6)}`,
        '&:hover': {
          outline: `2px solid ${alpha(theme.palette.warning.light, 0.3)}`,
          boxShadow: theme.shadows[2]
        },
        overflow: 'auto'
      }}
      key={`question-${questionIndex}-page-${pageIndex}`}
      className={`question-${questionIndex}-page-${pageIndex}`}
    >
      <QuestionTitle
        title={question.title}
        handleChangeQuestionTitle={handleChangeQuestionTitle}
        pageIndex={pageIndex}
        questionIndex={questionIndex}
      />
      <CardContent sx={{ pt: '4px' }}>
        {question.type === 'text' && <TextTypeQuestion question={question} />}
        {question.type === 'rating' && (
          <RatingTypeQuestion
            question={question}
            pageIndex={pageIndex}
            questionIndex={questionIndex}
            evalValues={evalProcessValues}
            setEvalValues={setEvalValues}
          />
        )}
        {question.type === 'boolean' && (
          <BooleanTypeQuestion
            question={question}
            pageIndex={pageIndex}
            questionIndex={questionIndex}
            evalValues={evalProcessValues}
            setEvalValues={setEvalValues}
            isCorrectAnswersModeActive={isCorrectAnswersModeActive}
            correctAnswers={correctAnswers}
            handleMarkAnswerAsCorrect={handleMarkAnswerAsCorrect}
          />
        )}
        {Boolean(
          question.type === 'checkbox' || question.type === 'radiogroup'
        ) && (
          <CheckboxTypeQuestion
            question={question}
            pageIndex={pageIndex}
            questionIndex={questionIndex}
            evalValues={evalProcessValues}
            setEvalValues={setEvalValues}
            isCorrectAnswersModeActive={isCorrectAnswersModeActive}
            correctAnswers={correctAnswers}
            setCorrectAnswers={setCorrectAnswers}
            handleMarkAnswerAsCorrect={handleMarkAnswerAsCorrect}
          />
        )}
        {question.type === 'image' && (
          <ImageTypeQuestion
            question={question}
            pageIndex={pageIndex}
            questionIndex={questionIndex}
            evalValues={evalProcessValues}
            setEvalValues={setEvalValues}
          />
        )}
        {question.type === 'signaturepad' && (
          <SignatureTypeQuestion
            question={question}
            pageIndex={pageIndex}
            questionIndex={questionIndex}
            evalValues={evalProcessValues}
            setEvalValues={setEvalValues}
          />
        )}
        {question.showCommentArea && (
          <Grow
            in={question.showCommentArea}
            exit={Boolean(true)}
            timeout="auto"
          >
            <TextField
              multiline
              aria-readonly={Boolean(true)}
              fullWidth
              sx={{
                mt: 1
              }}
              id={`${question.name}-comment-area`}
              // disabled
              rows={2}
              label="דוגמא/הערה"
              placeholder={`דוגמא/הערה ל- ${question.title} שאפשר לכתוב בעת המילוי`}
              slotProps={{
                input: {
                  readOnly: Boolean(true)
                }
              }}
            />
          </Grow>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        {Boolean(
          question &&
            question.type &&
            question.type !== 'text' &&
            question.type !== 'image'
        ) && (
          <Button
            startIcon={<CheckOutlined />}
            variant={isCorrectAnswersModeActive ? 'contained' : 'text'}
            sx={{ transition: 'all ease-in-out 0.15s' }}
            onClick={() => toggleSetCorrectAnswersMode()}
          >
            סמן תשובות נכונות
          </Button>
        )}
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          {surveyType !== 'knowledgeTest' && (
            <>
              {question.type !== 'image' && (
                <Button
                  startIcon={
                    question.showCommentArea ? (
                      <CheckBoxOutlined />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )
                  }
                  color="warning"
                  onClick={() => handleToggleQuestionGotComment()}
                >
                  הוספת דוגמא
                </Button>
              )}
              {question.type !== 'image' && (
                <Button
                  startIcon={
                    question.isRequired ? (
                      <CheckBoxOutlined />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )
                  }
                  color="warning"
                  onClick={() => handleSetIsQuestionRequired()}
                >
                  חובה
                </Button>
              )}
            </>
          )}
          <Tooltip title="שכפול שאלה">
            {matchSmDown ? (
              <IconButton
                onClick={() => handleDuplicateQuestion()}
                color="warning"
              >
                <ContentCopyOutlined />
              </IconButton>
            ) : (
              <Button
                startIcon={<ContentCopyOutlined />}
                color="warning"
                onClick={() => handleDuplicateQuestion()}
              >
                שכפל
              </Button>
            )}
          </Tooltip>
          <Tooltip title="מחיקת שאלה">
            {matchSmDown ? (
              <IconButton
                onClick={() => handleDeleteQuestion()}
                color="warning"
              >
                <DeleteOutlineOutlined />
              </IconButton>
            ) : (
              <Button
                startIcon={<DeleteOutlineOutlined />}
                color="warning"
                onClick={() => handleDeleteQuestion()}
              >
                {!matchSmDown && 'מחיקת שאלה'}
              </Button>
            )}
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};

export default SurveyQuestionElement;
