import commonConfig from 'src/common/config';
import routesConst from 'src/routesConst';
import createGeoJSONCircle from './createGeoJSONCircle';

const handleAlgoliaGeodistanceSearch = async (
  center,
  radius,
  searchIndex,
  setSearchResults,
  navigate,
  setResultsAreLoading,
  mapCurrent,
  searchQuery
) => {
  // array of coords is [lng, lat] the order is opposite in string
  const [lng, lat] = center;

  const searchQueryOptions = {
    analytics: false,
    aroundLatLng: `${lat},${lng}`,
    aroundPrecision: 2000,
    aroundRadius: radius,
    attributesToRetrieve: ['*'],
    attributesToSnippet: ['*:20'],
    enableABTest: false,
    explain: ['*'],
    facets: ['*'],
    facetFilters: ['org_status:approved'],
    getRankingInfo: true,
    hitsPerPage: 50,
    page: 0,
    responseFields: ['*'],
    snippetEllipsisText: '…'
  };
  // console.log(searchQueryOptions);
  // console.log('qeury ==> ', searchQuery);
  if (
    searchQuery &&
    searchQuery.query &&
    Object.keys(searchQuery.query).length > 0
  ) {
    const queryKeys = Object.keys(searchQuery.query);
    // console.log(searchQuery.query);
    const facetFilters = [];
    for (let index = 0; index < queryKeys.length; index++) {
      const key = queryKeys[index];
      switch (key) {
        case 'available_funds':
          {
            const fundFacetFilter = searchQuery.query[key].map(
              (fund) => `available_funds:${fund}`
            );
            facetFilters.push(fundFacetFilter);
            console.log(fundFacetFilter);
          }
          break;
        case 'tags':
          {
            const tagsFacetFilter = searchQuery.query[key].map(
              (tag) => `tags:${tag}`
            );
            facetFilters.push(tagsFacetFilter);
          }
          break;
        case 'cctv':
          {
            const isTrue = searchQuery.query.cctv === 'weHave';
            if (searchQuery.query.cctv !== 'notImportant') {
              facetFilters.push(`cctv:${isTrue}`);
            }
          }
          break;
        case 'fall_prevention':
          {
            const isTrue = searchQuery.query.fall_prevention === 'weHave';
            if (searchQuery.query.fall_prevention !== 'notImportant') {
              facetFilters.push(`fall_prevention:${isTrue}`);
            }
          }
          break;
        case 'iv':
          {
            const isTrue = searchQuery.query.iv === 'weHave';
            if (searchQuery.query.iv !== 'notImportant') {
              facetFilters.push(`iv_treatment:${isTrue}`);
            }
          }
          break;
        case 'kosher':
          if (searchQuery.query.kosher !== 'notSpecified') {
            facetFilters.push(`kosher:${searchQuery.query.kosher}`);
          }
          break;
        case 'kitchen':
          if (searchQuery.query.kitchen !== 'notImportant') {
            // console.log(searchQuery.query.kitchen);
            facetFilters.push(`kitchen:${searchQuery.query.kitchen}`);
          }
          break;
        default:
          break;
      }
    }
    searchQueryOptions.facetFilters.push(...facetFilters);
    // console.log(searchQueryOptions);
  }

  searchIndex
    .search('', searchQueryOptions)
    .then(
      (res) => {
        // console.log('res', res);
        setSearchResults(res.hits);
        setResultsAreLoading(false);
      },
      (rejected) => console.log('rejected', rejected)
    )
    .then(() => {
      if (window.location.pathname !== `/${routesConst.results}`) {
        navigate(`/${routesConst.results}`, {
          state: {
            searchData: {
              center: [lng, lat],
              radius
            },
            initialSearch: true
          }
        });
        // console.log('not');
      }
    })
    .then(async () => {
      const radiusCircle = mapCurrent
        .getMap()
        .getSource('circle-radius-search');
      // console.log(radiusCircle);
      const geoJSONCircle = createGeoJSONCircle(
        [lng, lat],
        radius / (1000 * commonConfig.radiusMultiplyFactor)
      );
      if (radiusCircle) {
        mapCurrent
          .getMap()
          .getSource('circle-radius-search')
          .setData(geoJSONCircle.data);
        // mapCurrent.repaint = true;
      } else {
        mapCurrent
          .getMap()
          .addSource('circle-radius-search', geoJSONCircle)
          .addLayer({
            id: 'circle-radius-search',
            type: 'fill',
            source: 'circle-radius-search',
            paint: {
              'fill-color': 'blue',
              'fill-opacity': 0.1,
              'fill-antialias': true,
              'fill-outline-color': 'black'
            }
          });
        mapCurrent.getMap().repaint = true;
      }
      if (geoJSONCircle && geoJSONCircle.data && geoJSONCircle.data.geometry) {
        const coords = geoJSONCircle.data.geometry.coordinates[0];
        // take southwestern and northeastern corners of the bounds
        const radiusInKm = radius / (1000 * commonConfig.radiusMultiplyFactor);
        mapCurrent.fitBounds([coords[8], coords[40]], {
          center: [lng, lat],
          maxZoom:
            radiusInKm >= 80
              ? 7
              : radiusInKm < 80 && radiusInKm >= 60
              ? 8
              : radiusInKm < 60 && radiusInKm >= 45
              ? 8.5
              : radiusInKm < 50 && radiusInKm >= 20
              ? 9
              : radiusInKm < 20 && radiusInKm >= 10
              ? 10
              : 11
        });
      } else {
        mapCurrent.flyTo({
          center: [lng, lat],
          duration: 2000,
          zoom: 15
        });
      }
    })
    .catch((err) => {
      setResultsAreLoading(false);
      console.log(err);
    });
};

export default handleAlgoliaGeodistanceSearch;
