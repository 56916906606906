import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { db, functions } from 'src/_firebase/firebase';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const CompensationContext = createContext();

export const CompensationEngineProvider = ({ children }) => {
  CompensationEngineProvider.propTypes = {
    children: PropTypes.any
  };
  const [compensationEngine, setCompensationEngine] = useState();
  const { currentUser } = useCurrentUserContext();
  // const { globalCoursewares } = useGlobalCoursewares();

  const calculateOrgCompensationModule = async () => {
    const orgCompensationModuleCalculator = httpsCallable(
      functions,
      'secondGenorgCompensationModuleCalculator'
    );
    await orgCompensationModuleCalculator({ currentUser })
      .then((res) => {
        setCompensationEngine({
          ...compensationEngine,
          ...res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculatePersonalReward = (data) => {
    let totalCounter = 0;
    let counter = 0;
    const { completedCoursewares, education, workExperience } = data;
    if (!completedCoursewares) {
      totalCounter += 12;
    } else {
      totalCounter += 12;
      counter += completedCoursewares.length;
    }

    if (!education) {
      totalCounter += 1;
    } else {
      totalCounter += 1;
      counter += 1;
    }

    if (!workExperience) {
      totalCounter += 1;
    } else {
      totalCounter += 1;
      counter += 1;
    }
    return {
      total: totalCounter,
      userCounter: counter
    };
  };

  const retrieveGlobalBadgesFromDb = async () => {
    const badgesRef = collection(db, 'badges');
    return getDocs(badgesRef)
      .then((badgesDocs) => {
        if (!badgesDocs.empty) {
          const badgesData = badgesDocs.docs.map((badge) => ({
            ...badge.data()
          }));
          return badgesData;
        }
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };

  const retrieveUserBadges = async () => {
    const userBadgesRef = collection(
      db,
      `users/${currentUser.user_doc_id}/badges`
    );
    return getDocs(userBadgesRef)
      .then((userBadgesDocs) => {
        if (userBadgesDocs.empty) {
          return [];
        }
        const userBadges = userBadgesDocs.docs.map((userBadge) => ({
          ...userBadge.data(),
          user_badge_id: userBadge.id
        }));
        return userBadges;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };

  const retrieveUserLogins = async () => {
    const userLoginsRef = collection(
      db,
      `users/${currentUser.user_doc_id}/logins`
    );
    const q = query(userLoginsRef, orderBy('login_date', 'desc'), limit(45));
    return getDocs(q)
      .then((userLoginsDoc) => {
        if (userLoginsDoc.empty) {
          return { logins: [] };
        }
        const userLogins = userLoginsDoc.docs.map((userLogin) => ({
          ...userLogin.data(),
          user_login_id: userLogin.id
        }));
        // TODO::: add server timestamp of today to this fields in order to compare the server time
        // when user tries to do anything
        return { logins: userLogins };
      })
      .catch((err) => {
        console.log(err);

        return { logins: [] };
      });
  };
  const retrieveUserQuests = async () => {
    const userQuestsRef = collection(
      db,
      `users/${currentUser.user_doc_id}/quests`
    );
    return getDocs(userQuestsRef)
      .then((userQuestsDocs) => {
        if (userQuestsDocs.empty) {
          return [];
        }
        const userQuests = userQuestsDocs.docs.map((userQuest) => ({
          ...userQuest.data(),
          quest_id: userQuest.id
        }));
        // TODO::: add server timestamp of today to this fields
        return userQuests;
      })
      .catch((err) => ({ logins: [] }));
  };

  const retrieveGlobalQuestFromDb = async () => {
    const questsRef = collection(db, 'quests');
    const q = query(questsRef, orderBy('order', 'asc'));
    return getDocs(q)
      .then((questsDocs) => {
        if (!questsDocs.empty) {
          const questsData = questsDocs.docs.map((quest) => ({
            ...quest.data()
          }));
          return questsData;
        }
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };
  const calculatePersonalCompensationModule = async () => {
    // console.log(currentUser);
    const personalCompensationModuleCalculator = httpsCallable(
      functions,
      'secondGenpersonalCompensationModuleCalculator'
    );
    await personalCompensationModuleCalculator({ currentUser })
      .then(async (res) => {
        // by now, workExperience, education, coursewares and jobsSuggestions has resolved
        console.log(res);
        const globalBadges = await retrieveGlobalBadgesFromDb();
        const userBadges = await retrieveUserBadges();
        const userLogins = await retrieveUserLogins();
        const globalQuests = await retrieveGlobalQuestFromDb();
        const userQuests = await retrieveUserQuests();
        setCompensationEngine({
          ...compensationEngine,
          ...res.data,
          score: calculatePersonalReward(res.data),
          globalBadges,
          userBadges,
          userLogins,
          globalQuests,
          userQuests
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(userLogins);
  };

  useEffect(() => {
    if (currentUser && !compensationEngine) {
      if (currentUser.type === 'org') {
        calculateOrgCompensationModule(currentUser);
      } else if (currentUser.type === 'personal') {
        calculatePersonalCompensationModule(currentUser).catch((err) =>
          console.log(err)
        );
      }
    }
  }, [currentUser, compensationEngine]);

  // useEffect(() => {
  //   console.log(compensationEngine);
  // }, [compensationEngine]);`

  const currentCompensationData = useMemo(
    () => ({ compensationEngine, setCompensationEngine }),
    [compensationEngine, setCompensationEngine]
  );
  return (
    <CompensationContext.Provider value={currentCompensationData}>
      {children}
    </CompensationContext.Provider>
  );
};
export const useCompensationEngine = () => useContext(CompensationContext);
