import {
  ArrowBackOutlined,
  BusinessOutlined,
  CheckCircleOutline,
  PersonOutlineOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  Fade,
  Grid,
  Grow,
  Icon,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { auth } from 'src/_firebase/firebase';
import OrgRegisterForm from 'src/components/Register/OrgRegisterForm';
import PersonalRegisterForm from 'src/components/Register/PersonalRegisterForm';
import VerifyUser from 'src/components/Register/VerifyUser/VerifyUserDialog';
import sendVerificationSMS from 'src/utils/sendVerificationSMS';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const types = [
  {
    title: 'org',
    icon: BusinessOutlined,
    description: 'בית אבות / דיור מוגן / מוסד סיעודי',
    warning: 'למוסדות בלבד - נדרש ח.פ / ע.מ',
    examples: ['בית אבות', 'דיור מוגן', 'מוסד סיעודי'],
    color: '#ff6b6b',
    bgColor: '#fff8f8',
    benefits: [
      'גישה למידע על דיירים',
      'ניהול פרופיל מוסד',
      'הצעת שירותים ייחודיים'
    ]
  },
  {
    title: 'personal',
    icon: PersonOutlineOutlined,
    description: 'עובד/ת במוסד או אדם פרטי',
    warning: 'לעובדים פרטיים בלבד - נדרשת ת.ז',
    examples: ['עובד/ת מוסד', 'מטפל/ת', 'אח/ות'],
    color: '#4dabf7',
    bgColor: '#f3f9ff',
    benefits: [
      'חיפוש מוסדות מותאם אישית',
      'השוואת מחירים ושירותים',
      'תקשורת ישירה עם נציגים'
    ]
  }
];

const Register = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const theme = useTheme();
  const [userType, setUserType] = useState();
  const location = useLocation();
  const initialView = 'firstStep';
  const [activeView, setActiveView] = useState(initialView);
  const [createUserWithEmailAndPassword, user, loading] =
    useCreateUserWithEmailAndPassword(auth);
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [newUserValues, setNewUserValues] = useState();
  const [selectedAnimationComplete, setSelectedAnimationComplete] =
    useState(false);

  const [minimalMapAutoCompleteOpen, setMinimalMapAutoCompleteOpen] =
    useState(false);

  const handleOpenVerifyCodeDialog = async (values, setLoading) => {
    setNewUserValues(values);
    const { phone } = values;
    await sendVerificationSMS(`+972${phone}`)
      .then(() => {
        setVerifyDialogOpen(true);
      })
      .catch((err) => {
        console.log('err while sending verification sms', err);
        setVerifyDialogOpen(true);
      });
  };

  const handleCloseVerifyCodeDialog = () => {
    setVerifyDialogOpen(false);
  };

  const handleChangeUserType = (type) => {
    const { title } = type;
    setUserType(title);
    setSelectedAnimationComplete(false);
    setTimeout(() => setSelectedAnimationComplete(true), 500);

    if (title === 'org') {
      setActiveView(initialView);
    }
  };

  useEffect(() => {
    if (location.pathname) {
      const splitted = location.pathname.split('/');
      if (splitted.length > 2) {
        const referralDocRaw = splitted[2];
        const referralIdRaw = splitted[4];
        const referralDoc = referralDocRaw.split('=')[1];
        const referralId = referralIdRaw.split('=')[1];
        const referralData = JSON.stringify({
          referralDoc,
          referralId
        });
        window.sessionStorage.setItem('referralData', referralData);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (searchParams) {
      const typeTitle = searchParams.get('type');
      handleChangeUserType({ title: typeTitle });
    }
  }, [searchParams]);

  const getTypeData = (typeTitle) =>
    types.find((type) => type.title === typeTitle) || {};

  const selectedTypeData = userType ? getTypeData(userType) : null;

  return (
    <>
      <Helmet>
        <title>{t('register')}</title>
        <meta
          name="description"
          content="הרשמה למערכת מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta name="robots" content="index" />
        <meta name="googlebot" content="index, follow" />
        <link rel="canonical" href="https://www.mgdalor.com/register" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: 'max-height',
          justifyContent: 'center',
          pt: '64px',
          backgroundImage: userType
            ? `linear-gradient(to bottom, ${selectedTypeData.bgColor}, #ffffff)`
            : 'none',
          transition: 'background-image 0.5s ease'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            hieght: !userType ? '60vh' : '100vh',
            my: '64px',
            pb: '64px',
            boxShadow: (defaultTheme) => defaultTheme.shadows[6],
            bgColor: (dtheme) => dtheme.palette.divider,
            borderRadius: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative',
            backgroundColor: 'white'
          }}
        >
          {userType && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '8px',
                backgroundColor: selectedTypeData.color,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4
              }}
            />
          )}
          <Stack justifyContent="space-between" direction="row">
            <Box sx={{ mt: 4 }}>
              <Typography color="textPrimary" variant="h2">
                {t('createYourAccount')}
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                {userType
                  ? `${t('useYourDetails')} כ${t(userType)}`
                  : t('useYourDetails')}
              </Typography>
            </Box>
            {userType === 'org' && activeView === 'secondStep' && (
              <Box
                sx={{
                  mt: 4,
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography color="textPrimary" variant="h2">
                  {`${t('step')} 2/2`}
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  component={Link}
                  variant="body2"
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                  }}
                  onClick={() => setActiveView('firstStep')}
                >
                  {t('back')}
                  <ArrowBackOutlined fontSize="small" />
                </Typography>
              </Box>
            )}
          </Stack>
          {!userType ? (
            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
              sx={{ mt: 2, mb: 3 }}
            >
              בחר/י את סוג החשבון המתאים לך
            </Typography>
          ) : (
            <Fade in={selectedAnimationComplete}>
              <Typography
                variant="subtitle1"
                color="primary"
                align="center"
                sx={{
                  mt: 1,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  bgcolor: alpha(selectedTypeData.color, 0.1),
                  color: selectedTypeData.color,
                  py: 1,
                  borderRadius: 1
                }}
              >
                <CheckCircleOutline fontSize="small" />
                {`בחרת להירשם כ${t(userType)}`}
              </Typography>
            </Fade>
          )}
          <Grid
            container
            sx={{
              alignItems: 'stretch',
              justifyContent: 'center',
              display: 'flex',
              pt: 2,
              mb: 4
            }}
            spacing={3}
          >
            {types.map((type) => (
              <Grid key={type.title} item xs={12} sm={6}>
                <Grow
                  in={Boolean(true)}
                  timeout={type.title === 'org' ? 500 : 700}
                >
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      borderRadius: 2,
                      boxShadow:
                        userType === type.title
                          ? `0 8px 24px ${alpha(type.color, 0.25)}`
                          : theme.shadows[6],
                      p: 3,
                      height: '100%',
                      backgroundColor:
                        userType === type.title
                          ? alpha(type.color, 0.1)
                          : theme.palette.background.default,
                      border:
                        userType === type.title
                          ? `2px solid ${type.color}`
                          : '1px solid #eee',
                      transition: 'all ease-in 0.25s',
                      cursor: 'pointer',
                      transform:
                        userType === type.title ? 'scale(1.03)' : 'scale(1)',
                      '&:hover': {
                        boxShadow: `0 8px 24px ${alpha(type.color, 0.2)}`,
                        backgroundColor: alpha(type.color, 0.05),
                        transform: 'scale(1.03)'
                      }
                    }}
                    onClick={() => handleChangeUserType(type)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 2
                      }}
                    >
                      <Icon
                        sx={{
                          color:
                            userType === type.title
                              ? type.color
                              : 'action.main',
                          fontSize: '2rem',
                          p: 0.5,
                          borderRadius: '50%',
                          backgroundColor:
                            userType === type.title
                              ? alpha(type.color, 0.15)
                              : 'transparent'
                        }}
                      >
                        <type.icon />
                      </Icon>
                      <Typography
                        variant="h5"
                        sx={{
                          color:
                            userType === type.title
                              ? type.color
                              : 'text.primary',
                          fontWeight: userType === type.title ? 700 : 600
                        }}
                      >
                        {t(type.title)}
                      </Typography>
                    </Box>

                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="center"
                      sx={{ mb: 2 }}
                    >
                      {type.description}
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{
                        mb: 2,
                        fontWeight: 600,
                        color: type.color,
                        backgroundColor: alpha(type.color, 0.1),
                        px: 1.5,
                        py: 0.7,
                        borderRadius: 1,
                        display: 'inline-block'
                      }}
                    >
                      {type.warning}
                    </Typography>

                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                          textAlign: 'center',
                          mb: 1.5
                        }}
                      >
                        יתרונות:
                      </Typography>
                      {type.benefits.map((benefit, index) => (
                        <Box
                          key={uniqueId(benefit)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 1
                          }}
                        >
                          <CheckCircleOutline
                            sx={{
                              fontSize: '0.9rem',
                              color: type.color
                            }}
                          />
                          <Typography variant="body2" color="textSecondary">
                            {benefit}
                          </Typography>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'center',
                        mt: 'auto'
                      }}
                    >
                      <Typography variant="caption" color="textSecondary">
                        מתאים ל:
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          gap: 1
                        }}
                      >
                        {type.examples.map((example) => (
                          <Typography
                            key={example}
                            variant="body2"
                            sx={{
                              backgroundColor: alpha(type.color, 0.1),
                              color: type.color,
                              px: 2,
                              py: 0.5,
                              borderRadius: 10,
                              border: `1px solid ${alpha(type.color, 0.3)}`
                            }}
                          >
                            {example}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <CardActions
                      sx={{
                        mt: 1,
                        width: '100%'
                      }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          backgroundColor: type.color,
                          width: '100%'
                        }}
                      >
                        {type.title === 'org' ? 'הצטרף כארגון' : 'הצטרף כעובד'}
                      </Button>
                    </CardActions>
                  </Card>
                </Grow>
              </Grid>
            ))}
          </Grid>
          {userType && (
            <Fade in={selectedAnimationComplete}>
              <Typography
                variant="subtitle1"
                color="primary"
                align="center"
                sx={{
                  mt: 1,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  bgcolor: alpha(selectedTypeData.color, 0.1),
                  color: selectedTypeData.color,
                  py: 1,
                  borderRadius: 1
                }}
              >
                <CheckCircleOutline fontSize="small" />
                {`בחרת להירשם כ${t(userType)}`}
              </Typography>
            </Fade>
          )}
          {userType && (
            <Fade in={Boolean(true)} timeout={800}>
              <Box>
                {userType === 'personal' ? (
                  <PersonalRegisterForm
                    loading={loading}
                    handleOpenVerifyCodeDialog={handleOpenVerifyCodeDialog}
                    accentColor={selectedTypeData.color}
                  />
                ) : userType === 'org' ? (
                  <OrgRegisterForm
                    loading={loading}
                    activeView={activeView}
                    setActiveView={setActiveView}
                    handleOpenVerifyCodeDialog={handleOpenVerifyCodeDialog}
                    minimalMapAutoCompleteOpen={minimalMapAutoCompleteOpen}
                    setMinimalMapAutoCompleteOpen={
                      setMinimalMapAutoCompleteOpen
                    }
                    accentColor={selectedTypeData.color}
                  />
                ) : null}
              </Box>
            </Fade>
          )}
          {verifyDialogOpen && (
            <VerifyUser
              closeDialog={handleCloseVerifyCodeDialog}
              isOpen={verifyDialogOpen}
              newUserValues={newUserValues}
              userType={userType}
              accentColor={selectedTypeData.color}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Register;
