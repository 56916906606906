import {
  AlternateEmailOutlined,
  PhoneOutlined,
  WhatsApp
} from '@mui/icons-material';
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import commonConfig from 'src/common/config';
import ContactMgdalorForm from 'src/components/ContactUs/ContactMgdalorForm';
// import 'src/contact.css';
import PropTypes from 'prop-types';
import theme from 'src/theme';

const ContactFormAndDetailsSection = ({
  setYourMsgWasSent,
  yourMsgWasSent
}) => {
  ContactFormAndDetailsSection.propTypes = {
    setYourMsgWasSent: PropTypes.func,
    yourMsgWasSent: PropTypes.string
  };
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      key="details and form section"
      sx={{
        minHeight: '100vh',
        backgroundImage:
          'linear-gradient(120deg, rgb(255, 255, 255) 0%, rgb(237, 241, 247) 100%)'
      }}
      item
      xs={12}
      container
    >
      <Grid
        key="form section"
        id="form"
        item
        container
        xs={12}
        md={6}
        sx={{ p: 2, alignContent: 'flex-start', px: 4 }}
        spacing={3}
      >
        <Grid sx={{ height: 'fit-content' }} item xs={12}>
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <Typography variant="h2">השאר לנו הודעה</Typography>
            <Typography variant="h5" color="GrayText">
              בזכות הרשת המקיפה שלנו ושיתופי הפעולה עם בתי האבות המובילים, אנחנו
              יכולים להבטיח לכם שנמצא לכם את הבית האידיאלי שיתאים לדרישות שלכם
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ContactMgdalorForm
            // handleSaveNewContactForm={handleSaveNewContactForm}
            setYourMsgWasSent={setYourMsgWasSent}
            yourMsgWasSent={yourMsgWasSent}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
      >
        <Stack
          sx={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            mt: !matchMdDown && -15
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: '2rem', textAlign: 'center' }}
          >
            הפרטים שלנו
          </Typography>
          <List>
            <ListItem sx={{ justifyContent: 'center' }}>
              <ListItemAvatar>
                <Avatar
                  component="a"
                  href="tel:0557111106"
                  sx={{ bgcolor: alpha(theme.palette.primary.light, 0.8) }}
                >
                  <PhoneOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="טלפון נייד"
                secondary="0557111106"
                slotProps={{
                  secondary: {
                    fontSize: '1.1rem',
                    component: 'a',
                    href: 'tel:0557111106'
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: alpha(theme.palette.error.light, 0.8) }}>
                  <AlternateEmailOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='כתובת דוא"ל'
                secondary={`${commonConfig.supportMail[0]}`}
                slotProps={{
                  secondary: {
                    fontSize: '1.1rem'
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  component="a"
                  href="https://wa.me/972557111106"
                  sx={{ bgcolor: alpha(theme.palette.success.light, 0.8) }}
                >
                  <WhatsApp />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="לשליחת הודעת Whatsapp"
                secondary="0557111106"
                slotProps={{
                  secondary: {
                    fontSize: '1.1rem',
                    component: 'a',
                    href: 'https://wa.me/972557111106',
                    target: '_blank',
                    rel: 'noreferrer noopener'
                  }
                }}
              />
            </ListItem>
          </List>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ContactFormAndDetailsSection;
