import { Box, Icon, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

function RotatingCardFront({
  color = 'info',
  image,
  icon = '',
  title,
  description,
  customSx = {}
}) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      // coloredShadow={color}
      width="100%"
      position="relative"
      zIndex={2}
      sx={{
        backgroundImage: `linear-gradient(to top, ${alpha(
          theme.palette.grey[600],
          0.6
        )} 15%, ${alpha(theme.palette.primary.main, 0.65)}),
    url(${image})`,
        backgroundAttachment: 'fixed',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        backfaceVisibility: 'hidden',
        borderRadius: 2,
        ...customSx
      }}
    >
      <Box
        // sx={{ ...customSx }}
        py={12}
        px={3}
        textAlign="center"
        lineHeight={1}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {icon && (
          <Typography variant="h2" color="white" my={2}>
            {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
          </Typography>
        )}
        <Typography variant="h2" color="white" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="white">
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

// Typechecking props for the RotatingCardFront
RotatingCardFront.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light'
  ]),
  image: PropTypes.string.isRequired,
  icon: PropTypes.node,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  customSx: PropTypes.object
};

export default RotatingCardFront;
