import {
  ContactPhoneOutlined,
  DescriptionOutlined,
  EmojiEventsOutlined
} from '@mui/icons-material';
import { Grid, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import PersonalAssessmentRequestDescription from './AsssessmentRequestSteps/PersonalAssessmentRequestDescription';
import PersonalAssessmentRequestDetails from './AsssessmentRequestSteps/PersonalAssessmentRequestDetails';
import PersonalAssessmentRequestSummary from './AsssessmentRequestSteps/PersonalAssessmentRequestSummary';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}));
const ColorlibStepIcon = ({ active, completed, className, icon }) => {
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
  };
  const icons = {
    1: <ContactPhoneOutlined />,
    2: <DescriptionOutlined />,
    3: <EmojiEventsOutlined />
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
};

const AssessmentRequestForm = ({
  activeStep,
  setActiveStep,
  steps,
  requestValues,
  setRequestValues
}) => {
  AssessmentRequestForm.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    steps: PropTypes.array,
    requestValues: PropTypes.object,
    setRequestValues: PropTypes.func
  };

  const handleChangeRequestValues = (e) => {
    setRequestValues({ ...requestValues, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, i) => (
            <Step active={activeStep === i} key={label}>
              <StepLabel
                slots={{
                  stepIcon: ColorlibStepIcon
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        {activeStep === 0 ? (
          <PersonalAssessmentRequestDetails
            handleChange={handleChangeRequestValues}
            requestValues={requestValues}
          />
        ) : activeStep === 1 ? (
          <PersonalAssessmentRequestDescription
            handleChange={handleChangeRequestValues}
            requestValues={requestValues}
            setRequestValues={setRequestValues}
          />
        ) : (
          <PersonalAssessmentRequestSummary
            requestValues={requestValues}
            setActiveStep={setActiveStep}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AssessmentRequestForm;
