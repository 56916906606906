import { doc, serverTimestamp, updateDoc } from '@firebase/firestore';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { db, functions } from 'src/_firebase/firebase';
import saveKnowledgeTest from 'src/components/KnowledgeCommunity/InternalCommunity/KnowledgeTestDialog/saveKnowledgeTest';
import IntroCardForTodayClaim from 'src/components/PersonalCompensation/components/IntroCardForTodayClaim';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';

const VerifyPurchaseDialog = ({
  open,
  onClose,
  product,
  setMarketplaceProducts,
  marketplaceProducts
}) => {
  VerifyPurchaseDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    product: PropTypes.object,
    setMarketplaceProducts: PropTypes.func,
    marketplaceProducts: PropTypes.array
  };
  const { snackbarState, setSnackbarState } = useSnackbar();
  const [isLoading, setLoading] = useState();
  const { currentUser, setCurrentUser } = useCurrentUserContext();

  const purchaseDisabled = () =>
    !currentUser ||
    (currentUser &&
      Number(product.product_price) > Number(currentUser.diamonds));
  const verifyPurchase = async () => {
    if (purchaseDisabled()) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'אין ביתרתך מספיק יהלומים'
      });
    } else {
      setLoading(true);
      const performPurchaseRequest = httpsCallable(
        functions,
        'secondGenperformPurchaseRequest'
      );
      const { first_name, last_name, id, user_doc_id, email } = currentUser;
      const res = await performPurchaseRequest({
        ...product,
        buyer_id: user_doc_id,
        buyer_nid: id,
        buyer_first_name: first_name,
        buyer_last_name: last_name,
        buyer_email: email
      });
      setLoading(false);
      const { didExchange, didAddToPurchases, didSendEmail } = res.data;
      if (didExchange && didAddToPurchases && didSendEmail) {
        // we need to transfer the pages to the buyer
        // author_ref is the buyer, created_by is the seller, knowledgeTest remains the same
        // didAddToPurchases is the purchase_id
        const purchase_id = didAddToPurchases;
        const { knowledge_test_for_sale, seller_id } = product;
        if (knowledge_test_for_sale) {
          const {
            org_ref,
            knowledge_test_id,
            knowledgeTest: { org_ref: inner_org_ref, ...restOfKnowledgeTest },
            ...rest
          } = knowledge_test_for_sale;
          const procductPurchasesRef = doc(
            db,
            `marketplace/${product.product_id}/purchases/${purchase_id}`
          );
          const time = serverTimestamp();
          // author ref now is the new author
          await updateDoc(procductPurchasesRef, {
            author_ref: user_doc_id,
            purchased_at: time,
            created_at: time,
            original_details: {
              author_ref: seller_id,
              org_ref,
              test_details: knowledge_test_for_sale
            },
            type: 'knowledgeTest'
          });
          const pages = product?.knowledgeTestPages?.pages || [];
          // console.log(product.knowledgeTestPages);
          // console.log(pages);

          await saveKnowledgeTest(
            {
              knowledgeTest: {
                ...restOfKnowledgeTest,
                pages
              },
              ...rest,
              author_ref: user_doc_id,
              created_by: user_doc_id,
              knowledge_test_id: purchase_id,
              original_knowledge_test_id: knowledge_test_id,
              purchased_at: time,
              created_at: time
            },
            org_ref,
            user_doc_id,
            `marketplace/${product.product_id}/purchases/${purchase_id}/knowledgeTest`
          );
        }
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'success',
          msg: 'הרכישה בוצעה בהצלחה '
        });
        const temp = [...marketplaceProducts];
        const index = temp.findIndex(
          (el) => el.product_id === product.product_id
        );
        const tempUser = { ...currentUser };
        tempUser.diamonds -= product.product_price;
        setCurrentUser(tempUser);
        if (index > -1) {
          temp[index].num_of_purchases = temp[index].num_of_purchases
            ? temp[index].num_of_purchases + 1
            : 1;
          setMarketplaceProducts(temp);
        }
        onClose();
      } else {
        console.log('couldnt complete purchasing', Object.entries(res.data));
      }
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>
          <Typography component="span" variant="inherit">
            רכישת
            <Typography fontWeight={600} component="span" variant="inherit">
              {` ${product.product_name}`}
            </Typography>
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid item xs={12} sx={{ maxWidth: '500px !important' }}>
              <IntroCardForTodayClaim showOnlyDiamonds={Boolean(true)} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                my: 2,
                fontSize: '18px'
              }}
            >
              <Typography
                sx={{
                  fontSize: 'inherit',
                  maxWidth: '600px',
                  textAlign: 'justify'
                }}
              >
                נא לשים לב, שלאחר הרכישה יתקבל מייל אישור עם קישור למוצר שהינך
                רוכש.
                <br />
                לכן מוצרים אשר נרכשו עם יהלומים לא ניתן לבקש החזר יהלומים או
                זיכוי עליהם.
              </Typography>
              <Typography sx={{ fontSize: 'inherit' }} fontWeight={600}>
                האם אתה מאשר את הרכישה?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={purchaseDisabled()}
            onClick={() => verifyPurchase()}
          >
            אישור רכישה
          </Button>
          <Button variant="outlined" onClick={onClose}>
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <Backdrop
          sx={{ zIndex: theme.zIndex.modal + 1 }}
          in={Boolean(isLoading)}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default VerifyPurchaseDialog;
