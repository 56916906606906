import {
  CellTowerOutlined,
  KeyboardArrowDownOutlined
} from '@mui/icons-material';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import gsap from 'gsap';
import { func } from 'prop-types';
import { useEffect, useRef } from 'react';
import theme from 'src/theme';
import './vision.css';

// Hero Section
const OurVisionHeroSection = ({ onScrollDown }) => {
  OurVisionHeroSection.propTypes = {
    onScrollDown: func
  };
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const heroRef = useRef(null);
  const textRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.from(heroRef.current, {
      opacity: 0,
      duration: 1,
      ease: 'power3.out'
    });

    tl.from(
      textRef.current.children,
      {
        y: 100,
        opacity: 0,
        duration: 1,
        stagger: 0.2,
        ease: 'back.out(1.7)',
        transform: 'translateY(-30px)'
      },
      '-=0.5'
    );

    tl.from(
      imageRef.current,
      {
        x: 100,
        opacity: 0,
        duration: 1,
        ease: 'power3.out'
      },
      '-=0.8'
    );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <Box
      ref={heroRef}
      sx={{
        minHeight: '100vh',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        background: `linear-gradient(135deg, ${
          theme.palette.primary.dark
        } 0%, ${alpha(theme.palette.primary.main, 0.85)} 100%)`,
        color: 'white',
        pt: 8,
        pb: 10
      }}
    >
      {/* Wave background */}
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0.1,
          zIndex: 0,
          background:
            'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="%23ffffff" fill-opacity="1" d="M0,224L48,213.3C96,203,192,181,288,154.7C384,128,480,96,576,90.7C672,85,768,107,864,128C960,149,1056,171,1152,170.7C1248,171,1344,149,1392,138.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"%3E%3C/path%3E%3C/svg%3E")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover'
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack spacing={4} ref={textRef}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CellTowerOutlined size={48} />
                <Typography
                  variant="h6"
                  sx={{
                    ml: 2,
                    fontWeight: 600,
                    letterSpacing: '3px',
                    opacity: 0.9
                  }}
                >
                  מגדלור
                </Typography>
              </Box>

              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 800,
                  textShadow: '0 2px 10px rgba(0,0,0,0.2)'
                }}
              >
                החזון שלנו
              </Typography>

              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  fontWeight: 400,
                  lineHeight: 1.6,
                  maxWidth: '600px',
                  opacity: 0.9
                }}
              >
                כי אכפת לנו מהדור המבוגר ורוצים להחזיר על מה שעשה למעננו לאורך
                השנים הרבות, הקמנו את האתר הזה, דרכו נוכל להכשיר צוותים ולמקצע
                אותם על מנת להעלות את איכות הטיפול המוענק לגיל השלישי.
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: 'flex', md: 'block' },
              justifyContent: 'center'
            }}
          >
            <Box
              ref={imageRef}
              sx={{
                position: 'relative',
                width: '100%',
                maxWidth: { xs: '350px', md: '100%' },
                height: { xs: '350px', md: '450px' }
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
                  background: alpha(theme.palette.primary.light, 0.25),
                  border: `3px solid ${alpha(theme.palette.common.white, 0.3)}`,
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  boxShadow: `0 20px 40px ${alpha(
                    theme.palette.common.black,
                    0.2
                  )}`
                }}
              >
                <img
                  src="https://static.vecteezy.com/system/resources/previews/007/395/184/non_2x/blue-sea-scenery-free-vector.jpg"
                  alt="Ocean view with lighthouse"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%'
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 8,
            position: 'absolute',
            bottom: 30,
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <IconButton
            onClick={onScrollDown}
            sx={{
              color: 'white',
              border: '2px solid white',
              borderRadius: '50%',
              p: 1,
              animation: 'bounce 2s infinite',
              '@keyframes bounce': {
                '0%, 20%, 50%, 80%, 100%': {
                  transform: 'translateY(0)'
                },
                '40%': {
                  transform: 'translateY(-20px)'
                },
                '60%': {
                  transform: 'translateY(-10px)'
                }
              }
            }}
          >
            <KeyboardArrowDownOutlined />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default OurVisionHeroSection;
