import { doc, getDoc } from '@firebase/firestore';
import {
  BookmarkOutlined,
  ShoppingCartCheckoutOutlined
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import cardPic from 'src/assets/moreToRead/process.webp';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';

const SavedProductListItemButton = ({
  saved,
  handleOpenVerifyPurchaseRequest,
  handleBookmarkProduct
}) => {
  SavedProductListItemButton.propTypes = {
    saved: PropTypes.object,
    handleOpenVerifyPurchaseRequest: PropTypes.func,
    handleBookmarkProduct: PropTypes.func
  };

  const [itemDetails, setItemDetails] = useState();
  const { currentUser } = useCurrentUserContext();

  const retrieveItemDetails = async (product_id) => {
    const itemRef = doc(db, `marketplace/${product_id}`);
    getDoc(itemRef).then((snap) => {
      if (!snap.exists()) {
        setItemDetails({});
      } else {
        setItemDetails({ ...snap.data() });
      }
    });
  };
  const purchaseDisabled = () =>
    !currentUser ||
    (currentUser && Number(saved.product_price) > Number(currentUser.diamonds));

  useEffect(() => {
    if (saved && !itemDetails) {
      retrieveItemDetails(saved.product_id);
    }
  }, [saved]);
  return (
    <ListItem
      key={`saved-li-${saved.product_id}`}
      secondaryAction={
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          endIcon={<ShoppingCartCheckoutOutlined />}
          sx={{ maxWidth: 160 }}
          disabled={purchaseDisabled()}
          onClick={() => handleOpenVerifyPurchaseRequest(itemDetails)}
        >
          רכישה
        </Button>
      }
      disablePadding
    >
      <ListItemButton
        role={undefined}
        key={`saved-li-button-${saved.product_id}`}
        onClick={() => handleBookmarkProduct(saved.product_id)}
      >
        <ListItemIcon>
          <BookmarkOutlined
            color="error"
            edge="start"
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': saved.product_id }}
          />
        </ListItemIcon>
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={
              itemDetails && itemDetails.preview ? itemDetails.preview : cardPic // TODO:: handle preview
            }
            sx={{ width: 62, height: 62 }}
          >
            {itemDetails ? itemDetails.product_name : 'item name'}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          id={saved.product_id}
          primary={
            itemDetails ? (
              itemDetails.product_name
            ) : (
              <Skeleton variant="text" width="100%" sx={{ minWidth: 140 }} />
            )
          }
          secondary={
            itemDetails ? (
              itemDetails.description
            ) : (
              <Skeleton variant="text" width="100%" sx={{ minWidth: 140 }} />
            )
          }
          slotProps={{
            primary: {
              fontSize: '16px'
            },

            secondary: {
              sx: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word'
              }
            }
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SavedProductListItemButton;
