import {
  ExpandLessOutlined,
  ExpandMoreOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const PriceCompareTagRow = ({
  prices,
  tag,
  numOfPersonsIcon,
  room_type,
  roomTypeLocale
}) => {
  PriceCompareTagRow.propTypes = {
    prices: PropTypes.object,
    tag: PropTypes.string,
    numOfPersonsIcon: PropTypes.number,
    room_type: PropTypes.string,
    roomTypeLocale: PropTypes.string
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  return (
    <Grid
      container
      item
      xs={12}
      className="grid-container-item-for-each-row-of-prices"
    >
      <Grid container item xs={8} sm={6} sx={{ alignItems: 'center' }}>
        <Grid item xs={7} md={5}>
          <Typography sx={{ px: 1, fontSize: 'small' }}>
            {t(roomTypeLocale)}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          {numOfPersonsIcon &&
            typeof numOfPersonsIcon === 'number' &&
            Array(numOfPersonsIcon).fill(
              <PersonOutlineOutlined fontSize="small" />
            )}
        </Grid>
      </Grid>
      <Grid item xs={4} sm={6}>
        <Typography sx={{ textAlign: 'end', fontSize: 'small' }}>
          {prices[tag] &&
          prices[tag][room_type] &&
          prices[tag][room_type].single &&
          prices[tag][room_type].single
            ? `${prices[tag][room_type].single} ש"ח`
            : 0}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PriceCompareCard = ({
  prices,
  tag,
  pricesCardOpen,
  setPricesCardOpen
}) => {
  PriceCompareCard.propTypes = {
    prices: PropTypes.object,
    pricesCardOpen: PropTypes.object,
    setPricesCardOpen: PropTypes.func,
    tag: PropTypes.string
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });
  const handleToggleCollapseCard = () => {
    if (pricesCardOpen.state && pricesCardOpen.tag === tag) {
      setPricesCardOpen({ state: !pricesCardOpen.state, tag: '' });
    } else {
      setPricesCardOpen({ state: true, tag });
    }
  };
  return (
    <Card
      className="price-tag-main-card"
      sx={{
        width: '100%',
        minWidth: '270px',
        mb: 2,
        '&:hover': {
          boxShadow: (theme) => theme.shadows[8]
        }
      }}
    >
      <CardHeader
        title={t(tag)}
        onClick={() => handleToggleCollapseCard()}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.action.focus, 0.025)
          }
        }}
        action={
          <IconButton onClick={() => handleToggleCollapseCard()}>
            {pricesCardOpen.state && pricesCardOpen.tag === tag ? (
              <ExpandLessOutlined />
            ) : (
              <ExpandMoreOutlined />
            )}
          </IconButton>
        }
        slotProps={{
          title: {
            sx: {
              fontSize: 'small',
              fontWeight: 800
            }
          }
        }}
      />
      <Collapse in={pricesCardOpen.state && pricesCardOpen.tag === tag}>
        <CardContent sx={{ py: 0 }}>
          <Grid
            className="grid-container-item-tag-and-prices"
            container
            item
            xs={12}
          >
            <PriceCompareTagRow
              key={`single-room-price-row-${uniqueId(tag)}`}
              prices={prices}
              tag={tag}
              numOfPersonsIcon={1}
              room_type="single_room"
              roomTypeLocale="singleRoom"
            />
            <Divider width="75%" sx={{ margin: 'auto', my: 1 }} />
            <PriceCompareTagRow
              key={`double-room-price-row-${uniqueId(tag)}`}
              prices={prices}
              tag={tag}
              numOfPersonsIcon={2}
              room_type="double_room"
              roomTypeLocale="doubleRoom"
            />
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const PricesCompare = ({ StyledTableCell, list, userscrolledhorizontally }) => {
  PricesCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  const [pricesCardOpen, setPricesCardOpen] = useState({
    state: false,
    tag: ''
  });

  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="prices" />
        {t('prices')}
      </StyledTableCell>
      {list.names && (
        <>
          {list.pricing ? (
            <>
              {list.pricing.map((prices) => {
                if (prices) {
                  const tagsKeys = Object.keys(prices);
                  const sorted =
                    tagsKeys &&
                    tagsKeys.length > 0 &&
                    tagsKeys.sort((a, b) => a.localeCompare(b));
                  return (
                    <StyledTableCell
                      lengthOfList={list.names.length}
                      sx={{
                        justifyContent: 'center',
                        verticalAlign: 'top'
                      }}
                    >
                      <Grid container>
                        {sorted &&
                          sorted.length > 0 &&
                          sorted.map((tag) => (
                            <Grid
                              className="price-tag-main-grid-container"
                              container
                              item
                              xs={12}
                              sx={{ pb: 0 }}
                            >
                              <PriceCompareCard
                                prices={prices}
                                pricesCardOpen={pricesCardOpen}
                                setPricesCardOpen={setPricesCardOpen}
                                tag={tag}
                                key="tag-price-compare"
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </StyledTableCell>
                  );
                }
                return (
                  <StyledTableCell
                    lengthOfList={list.names.length}
                    sx={{
                      justifyContent: 'center',
                      verticalAlign: 'middle',
                      textAlign: 'center'
                    }}
                  >
                    {t('noData')}
                  </StyledTableCell>
                );
              })}
            </>
          ) : (
            <p>nodata</p>
          )}
        </>
      )}
    </>
  );
};

export default PricesCompare;
