import { Backdrop, CircularProgress } from '@mui/material';
import i18next from 'i18next';
import { isUndefined } from 'lodash';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'src/components/MainLayout';
import NotFound from 'src/pages/NotFound';
import ArticlePage from './components/Blog/ArticlePage';
import KnowledgeCommunity from './components/KnowledgeCommunity/KnowledgeCommunity';
import Loadable from './components/Loadable';
import DefaultNavbar from './components/MainNavbar';
import RedeemDiamonds from './components/PersonalCompensation/components/RedeemDiamonds';
import navbarRoutes from './components/navbar/navbarRoutes';
import Blog from './pages/Blog';
// import CommunityIntroPage from './pages/CommunityIntroPage';
import CompareOrgsPage from './pages/CompareOrgsPage';
import ContactUs from './pages/ContactUs';
import HomePage from './pages/Home';
import InternalOrgCommunity from './pages/InternalOrgCommunity';
import MohBranches from './pages/MOHBranches';
import MedicalAssessment from './pages/MedicalAssessment';
import MgdalorCommunityMarketplace from './pages/MgdalorCommunityMarketplace';
import MgdalorManagementSystem from './pages/MgdalorManagementSystem';
import MohCode from './pages/MohCode';
import OrgPage from './pages/OrgPage';
import OurVision from './pages/OurVision';
import Register from './pages/Register';
import SearchResults from './pages/SearchResults';
import routesConst from './routesConst';
import isAuthorized from './utils/isAuthorized';

const MohAssessment = lazy(() =>
  import('./components/KnowledgeCommunity/MohAssessment/MohAssessment')
);
const UserCV = lazy(() => import('./components/UserCV/UserCV'));
const Coursewares = lazy(() => import('./pages/Coursewares'));
const LoadableCoursewares = Loadable(Coursewares);
const EmployeesList = lazy(() => import('./pages/EmployeesList'));
const FamilyAccount = lazy(() => import('./pages/FamilyAccount'));
const FamilyLogin = lazy(() => import('./pages/FamilyLogin'));
const FormsToDownload = lazy(() => import('./pages/FormsToDownlaod'));
const JobsListPage = lazy(() => import('./pages/JobsListPage'));
const LeaderboardPage = lazy(() => import('./pages/LeaderboardPage'));
const MohReports = lazy(() => import('./pages/MohReports'));
const NewContact = lazy(() => import('./pages/NewContact'));
const NewTicketsForOrg = lazy(() => import('./pages/NewTicketsForOrg'));
const OrgAccount = lazy(() => import('./pages/OrgAccount'));
const OrgCompensation = lazy(() => import('./pages/OrgCompensation'));
const PersonalAccount = lazy(() => import('./pages/PersonalAccount'));
const PersonalCompensation = lazy(() => import('./pages/PersonalCompensation'));
const PersonalEmployeesList = lazy(() =>
  import('./pages/PersonalEmployeesList')
);
const Practices = lazy(() => import('./pages/Practices'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const AccessibilityDeclaration = lazy(() =>
  import('./pages/AccessibilityDeclaration')
);
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ThankYouForPaying = lazy(() => import('./pages/ThankYouForPaying'));
const UserGuide = lazy(() => import('./pages/UserGuide'));
const DashboardLayout = lazy(() => import('src/components/DashboardLayout'));
const Dashboard = lazy(() => import('src/pages/Dashboard'));
const Login = lazy(() => import('src/pages/Login'));
const PersonalAuthorizedDepartmentsManagement = lazy(() =>
  import(
    './components/Employees/AuthorizedDepartmentsManagement/PersonalAuthorizedDepartmentsManagement'
  )
);
const PersonalOnBoardingProcessSettings = lazy(() =>
  import(
    './components/Employees/AuthorizedOnBoardingSetup/PersonalOnBoardingProcessSettings'
  )
);
const PersonalAuthorizedRolesManagement = lazy(() =>
  import(
    './components/Employees/AuthorizedRolesManagement/PersonalAuthorizedRolesManagement'
  )
);
const PersonalAuthorizedEvaluationProcesses = lazy(() =>
  import(
    './components/Employees/PersonalAuthorizedEvaluationProcesses/PersonalAuthorizedEvaluationProcesses'
  )
);
const CommunityIntroPage = lazy(() =>
  import('./pages/CommunityIntroPage').then((module) => ({
    default: module.default
  }))
);

const routes = (props) => {
  const { user, loading, currentUser, selectedOrg } = props;
  const isAuthorizedForTickets = isAuthorized(
    currentUser,
    'tickets:read',
    selectedOrg
  );
  return [
    {
      path: `/${routesConst.app}`,
      element: <DashboardLayout {...props} />,
      children: [
        {
          path: `/${routesConst.app}/${routesConst.dashboard}`,
          element: <Dashboard />
        },
        {
          path: `/${routesConst.app}/${routesConst.practices}`,
          element: <Practices />
        },
        {
          path: `/${routesConst.app}/${routesConst.coursewares}`,
          element: <LoadableCoursewares />
        },
        {
          path: `/${routesConst.app}/${routesConst.compensation}`,
          element:
            user && currentUser && currentUser.type === 'org' ? (
              (<OrgCompensation />) // org
            ) : user && !currentUser ? (
              <Backdrop open={user && !currentUser}>
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.compensation}`,
          element:
            user && currentUser && currentUser.type === 'personal' ? (
              (<PersonalCompensation />) // personal
            ) : user && !currentUser ? (
              <Backdrop open={user && !currentUser}>
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.tickets}`,
          element:
            user &&
            currentUser &&
            currentUser.type === 'personal' &&
            selectedOrg &&
            isAuthorizedForTickets ? (
              (<NewTicketsForOrg key="personal-tickets" />) // personal
            ) : (user && !currentUser) ||
              (currentUser &&
                currentUser.type === 'personal' &&
                !selectedOrg &&
                isUndefined(isAuthorizedForTickets)) ? (
              <Backdrop open={user && !currentUser}>
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.tickets}`,
          // element: <NewTicketsForOrg />,
          element:
            user && currentUser && currentUser.type === 'org' ? (
              (<NewTicketsForOrg key="org-tickets" />) // org
            ) : user && !currentUser ? (
              <Backdrop open={user && !currentUser}>
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.org}/${routesConst.account}`,
          element:
            user && currentUser && currentUser.type === 'org' ? (
              (<OrgAccount />) // org
            ) : user && !currentUser ? (
              <Backdrop open={user && !currentUser}>
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.account}`,
          element:
            user && currentUser && currentUser.type === 'personal' ? (
              (<PersonalAccount />) // personal
            ) : user && !currentUser ? (
              <Backdrop open={user && !currentUser}>
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.employees}`,
          element:
            user && currentUser && currentUser.type === 'org' ? (
              (<EmployeesList />) // org
            ) : user && !currentUser ? (
              <Backdrop
                key="employees-list-backdrop"
                open={user && !currentUser}
              >
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}`,
          element:
            user && currentUser && currentUser.type === 'personal' ? (
              (<PersonalEmployeesList />) // personal
            ) : user && !currentUser ? (
              <Backdrop
                key="personal-employees-list-backdrop"
                open={user && !currentUser}
              >
                <CircularProgress />
              </Backdrop>
            ) : (
              <NotFound />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.onboarding}`,
          element:
            user &&
            currentUser &&
            currentUser.type === 'personal' &&
            selectedOrg &&
            isAuthorized(
              currentUser,
              'employees:onboarding-management',
              selectedOrg
            ) ? (
              (<PersonalOnBoardingProcessSettings />) // personal
            ) : user && !currentUser ? (
              <Backdrop
                key="personal-employees:onboarding-backdrop"
                open={user && !currentUser}
              >
                <CircularProgress />
              </Backdrop>
            ) : (
              <PersonalEmployeesList />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.roles}`,
          element:
            user &&
            currentUser &&
            currentUser.type === 'personal' &&
            selectedOrg &&
            isAuthorized(currentUser, 'roles:read', selectedOrg) ? (
              (<PersonalAuthorizedRolesManagement key="personal-roles:read-RolesManagement" />) // personal
            ) : user && !currentUser ? (
              <Backdrop
                key="personal-roles:read-backdrop"
                open={user && !currentUser}
              >
                <CircularProgress />
              </Backdrop>
            ) : (
              <PersonalEmployeesList />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.departments}`,
          element:
            user &&
            currentUser &&
            currentUser.type === 'personal' &&
            selectedOrg &&
            isAuthorized(currentUser, 'departments:read', selectedOrg) ? (
              (<PersonalAuthorizedDepartmentsManagement key="personal-departments:read-PersonalAuthorizedDepartmentsManagement" />) // personal
            ) : user && !currentUser ? (
              <Backdrop
                key="personal-departments:read-backdrop"
                open={user && !currentUser}
              >
                <CircularProgress />
              </Backdrop>
            ) : (
              <PersonalEmployeesList />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.evaluation}`,
          element:
            user &&
            currentUser &&
            currentUser.type === 'personal' &&
            selectedOrg &&
            isAuthorized(currentUser, 'roles:read', selectedOrg) ? (
              (<PersonalAuthorizedEvaluationProcesses key="personal-departments:read-PersonalAuthorizedDepartmentsManagement" />) // personal
            ) : user && !currentUser ? (
              <Backdrop
                key="personal-departments:read-backdrop"
                open={user && !currentUser}
              >
                <CircularProgress />
              </Backdrop>
            ) : (
              <PersonalEmployeesList />
            )
        },
        {
          path: `/${routesConst.app}/${routesConst.preview}/${routesConst.resume}`,
          element: <UserCV />
        },
        {
          path: `/${routesConst.app}/${routesConst.leaderboard}`,
          element: <LeaderboardPage />
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.knowledgeCommunity}`,
          element: <KnowledgeCommunity />
        },
        {
          path: `/${routesConst.app}/${routesConst.org}/${routesConst.knowledgeCommunity}`,
          element: <KnowledgeCommunity />
        },
        {
          path: `/${routesConst.app}/${routesConst.mohAssessment}`,
          element: <MohAssessment />
        },
        {
          path: `/${routesConst.app}/${routesConst.redeemDiamonds}`,
          element: <RedeemDiamonds />
        },
        {
          path: `/${routesConst.app}/${routesConst.community}`,
          element: <CommunityIntroPage />
        },
        {
          path: `/${routesConst.app}/${routesConst.community}/mgdalor`,
          element: <MgdalorCommunityMarketplace />
        },
        {
          path: `/${routesConst.app}/${routesConst.community}/internal`,
          element: <InternalOrgCommunity />
        },
        {
          path: `/${routesConst.app}/${routesConst.user}/${routesConst.jobs}`,
          element: <JobsListPage />
        },
        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: '',
      element: <MainLayout {...props} />,
      children: [
        {
          path: `/${routesConst.login}`,
          element: <Login />
        },
        {
          path: `/${routesConst.register}`,
          element: <Register />
        },
        {
          path: `/${routesConst.register}/:referralDoc/$/:id`,
          element: <Register />
        },
        { path: `${routesConst.error404}`, element: <NotFound /> },
        { path: '/', element: <HomePage /> },
        { path: '/org/:id', element: <OrgPage /> },
        { path: '/resetPassword', element: <ResetPassword /> },
        { path: '/results', element: <SearchResults /> },
        { path: '/search', element: <SearchResults /> },
        { path: '/compare', element: <CompareOrgsPage /> },
        { path: '/mohCode', element: <MohCode /> },
        { path: '/accessibility', element: <AccessibilityDeclaration /> },
        { path: `${routesConst.vision}`, element: <OurVision /> },
        { path: `${routesConst.blog}`, element: <Blog /> },
        { path: `${routesConst.blog}/:blogId`, element: <ArticlePage /> },
        {
          path: `/${routesConst.medicalAssessment}`,
          element: <MedicalAssessment />
        },
        { path: `${routesConst.mohReports}`, element: <MohReports /> },
        { path: `${routesConst.mohBranches}`, element: <MohBranches /> },
        { path: `${routesConst.contact}`, element: <ContactUs /> },
        { path: `${routesConst.forms}`, element: <FormsToDownload /> },
        {
          path: `${routesConst.aboutMgdalor}`,
          element: <MgdalorManagementSystem />
        },
        { path: `${routesConst.privacy}`, element: <PrivacyPolicy /> },
        { path: `${routesConst.familyLogin}`, element: <FamilyLogin /> },
        { path: `${routesConst.familyAccount}`, element: <FamilyAccount /> },
        { path: '*', element: <Navigate to={`/${routesConst.error404}`} /> }
      ]
    },
    {
      path: `/${routesConst.resume}/:userCred`,
      element: <UserCV />
    },
    {
      path: `/${routesConst.newContact}`,
      element: <NewContact />
    },
    {
      path: '/userGuide',
      element: (
        <>
          <DefaultNavbar
            navbarRoutes={navbarRoutes}
            action={{
              type: 'external',
              route: `${routesConst.contact}`,
              label: i18next.t('navbar.helpCTA'),
              color: 'info'
            }}
            brand="Mgdalor"
            sticky
          />
          <UserGuide />
        </>
      )
    },
    {
      path: `/${routesConst.thankyou}`,
      element: <ThankYouForPaying />
    }
  ];
};
export default routes;
