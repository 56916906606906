import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import loadPdf from './loadPdf';
import './viewPractice.css';

const RenderFileComponent = ({ file_name, url }) => {
  useEffect(() => {
    if (url) {
      loadPdf(url)
        .then((result) => {
          if (result === 'error') {
            console.error('Failed to load PDF');
          }
        })
        .catch((err) => {
          console.error('Error loading PDF:', err);
          window.open(url, '_blank');
        });
    }
  }, [url]);

  return (
    <div
      style={{
        height: '100%'
      }}
    >
      <Typography sx={{ position: 'relative', mb: 1 }}>
        אם אינך רואה את הקובץ כראוי&nbsp;
        <Typography
          sx={{ textDecoration: 'underline' }}
          component="a"
          target="_blank"
          rel="noreferrer noopener"
          href={url}
        >
          לחץ כאן
        </Typography>
      </Typography>
      <div id="pdfPracticeViewer" style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

RenderFileComponent.propTypes = {
  file_name: PropTypes.string,
  url: PropTypes.string
};

export default RenderFileComponent;
