import { uniqueId } from 'lodash';
import { workerClass } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropType from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import Map, { Marker } from 'react-map-gl';
import workerLoader from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
// import OrgAvatarPin from './OrgAvatarPin';
// import { useEffect } from 'react';
import OrgAvatarPin from 'src/components/map/OrgAvatarPin';
import './interactiveBackgroundMap.css';

workerClass = workerLoader;

const initMapCenter = {
  lng: 35.11,
  lat: 32.71
};
const InteractiveBackgroundMap = ({
  markers = [],
  customMapCenter = initMapCenter
}) => {
  InteractiveBackgroundMap.propTypes = {
    markers: PropType.array,
    customMapCenter: PropType.object
  };

  const mapRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [lng, setLng] = useState(35.11);
  const [lat, setLat] = useState(32.71);
  const [zoom, setZoom] = useState(2);
  const [mapCenter, setMapCenter] = useState([
    initMapCenter.lng,
    initMapCenter.lat
  ]);
  const pins = useMemo(() => {
    if (markers) {
      // console.log(markers);
      const pinsMap = markers.map((pinLocation) => (
        <Marker
          key={`marker-${pinLocation.user_doc_id}-${uniqueId()}`}
          longitude={pinLocation._geoloc.lng}
          latitude={pinLocation._geoloc.lat}
          offset={[0, -50 / 2]}
          anchor="bottom"
        >
          {/* org logo or mgdalor logo */}
          <OrgAvatarPin isSelected={false} src={pinLocation.avatar} />
        </Marker>
      ));
      return pinsMap;
    }
  }, [markers, popupInfo]);
  // console.log()
  useEffect(() => {
    if (customMapCenter) {
      setMapCenter(customMapCenter);
      // console.log(customMapCenter);
    } else {
      setMapCenter(initMapCenter);
    }
  }, [customMapCenter]);
  return (
    <>
      <Map
        ref={mapRef}
        id="bgMap"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom
        }}
        onLoad={(e) => {
          e.target.addSource('cities', {
            type: 'geojson',
            data: 'mapbox://data/moradabed/moradabed.clks39djv00we2as0aqv8slql-5c2e6'
          });
        }}
        pitchWithRotate
        pitch={85}
        bearing={340}
        scrollZoom="true"
        center={initMapCenter}
        maxBounds={[
          [34.0654333839, 29.5013261988],
          [36.056, 33.436]
        ]}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '60vh'
        }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN}
        mapStyle="mapbox://styles/moradabed/cldexw2ei001d01o92a1ijqd5"
      >
        {markers && pins}
      </Map>
    </>
  );
};

export default InteractiveBackgroundMap;
