import { doc, getDoc, serverTimestamp, setDoc } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';

const handleCreateNewPersonalAccount = async (
  values,
  createUserWithEmailAndPassword,
  userType
) => {
  try {
    const userRef = doc(db, 'users', values.id);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      return { exists: true, id: values.id };
    }

    const { password, ...rest } = values;

    // Use setDoc instead of addDoc
    await setDoc(userRef, {
      ...rest,
      type: userType,
      created_at: serverTimestamp()
    });

    // Create user authentication
    await createUserWithEmailAndPassword(
      `${values.id}@${commonConfig.emailAuthSuffix}`,
      password
    ).catch((err) => {
      console.error('Error creating user in auth:', err);
    });
  } catch (err) {
    console.error('Error handling personal account creation:', err);
  }
};

export default handleCreateNewPersonalAccount;
