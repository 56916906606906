import {
  ContactMailOutlined,
  DashboardOutlined,
  DiamondOutlined,
  ForumOutlined,
  GavelOutlined,
  Groups2Outlined,
  PersonOutlineOutlined,
  SchoolOutlined
} from '@mui/icons-material';
import i18next from 'i18next';
import routesConst from 'src/routesConst';
import { arrayMoveImmutable } from 'src/utils/array-move';

const renderPersonalNavItems = (employeeTabChildren, ticketsTabVisible) => {
  const initial = [
    {
      href: `/${routesConst.app}/${routesConst.dashboard}`,
      icon: DashboardOutlined,
      title: i18next.t('appSidebar.mainPage'),
      order: 1,
      id: 'mainPage'
    },
    {
      href: `/${routesConst.app}/${routesConst.coursewares}`,
      icon: SchoolOutlined,
      title: i18next.t('appSidebar.coursewares'),
      order: 2,
      id: 'coursewares'
    },
    {
      href: `/${routesConst.app}/${routesConst.practices}`,
      icon: GavelOutlined,
      title: i18next.t('appSidebar.practices'),
      order: 3,
      id: 'practices'
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}`,
      icon: Groups2Outlined,
      title: i18next.t('appSidebar.employees'),
      children: employeeTabChildren || null,
      order: 5,
      id: 'employees'
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.compensation}`,
      icon: DiamondOutlined,
      title: i18next.t('appSidebar.compensation'),
      order: 6,
      id: 'compensation'
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.knowledgeCommunity}`,
      icon: ForumOutlined,
      title: i18next.t('appSidebar.knowledgeCommunity'),
      order: 7,
      id: 'knowledgeCommunity'
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.account}`,
      icon: PersonOutlineOutlined,
      title: i18next.t('appSidebar.account'),
      order: 8,
      id: 'account'
    }
  ];
  if (ticketsTabVisible) {
    const newArr = [
      ...arrayMoveImmutable(
        [
          ...initial,
          {
            href: `/${routesConst.app}/${routesConst.user}/${routesConst.tickets}`,
            icon: ContactMailOutlined,
            title: i18next.t('appSidebar.tickets'),
            order: initial.length
          }
        ],
        initial.length,
        3
      )
    ];
    const orderedArr = newArr.map((newOrderedEl, index) => {
      newOrderedEl.order = index + 1;
      return newOrderedEl;
    });
    // console.log(orderedArr);
    // initial.push();
    return orderedArr;
  }
  const sorted = initial.sort((a, b) => a.order - b.order > 0);
  return sorted;
};
export default renderPersonalNavItems;
