import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Suspense } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MapProvider } from 'react-map-gl';
import { useRoutes } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import routes from 'src/routes';
import theme from 'src/theme';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { auth } from './_firebase/firebase';
import SearchLoader from './components/shared/SearchLoader/SearchLoader';
import { CompensationEngineProvider } from './hooks/useCompensationEngine/useCompensationEngine';
import { EmployeesDataProvider } from './hooks/useEmployeesData/useEmployeesData';
import { GlobalCoursewaresProvider } from './hooks/useGlobalCoursewares/useGlobalCoursewares';
import { OrgTicketsProvider } from './hooks/useOrgTickets/useOrgTickets';
import { PracticesProvider } from './hooks/usePractices/usePractices';
import { ReportsProvider } from './hooks/useReports/useReports';
import { SearchDataProvider } from './hooks/useSearchData/useSearchData';
import { useSelectedOrg } from './hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from './hooks/useUserContext/UserContext';
import checkRtl from './utils/checkRtl';

const cacheLtr = createCache({
  key: 'muiltr'
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [stylisRTLPlugin]
});

const App = () => {
  const [user, loading] = useAuthState(auth);
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const routing = useRoutes(
    routes({ user, loading: Boolean(loading), currentUser, selectedOrg })
  );

  return (
    <CacheProvider value={checkRtl() === 'rtl' ? cacheRtl : cacheLtr}>
      <Suspense fallback={<SearchLoader />}>
        <CompensationEngineProvider>
          <MapProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <SearchDataProvider>
                <EmployeesDataProvider>
                  <GlobalCoursewaresProvider>
                    <PracticesProvider>
                      <OrgTicketsProvider>
                        <ReportsProvider>
                          {/* {user && blackList.includes(user.email) ? (
                            <PageUnderMaintenance />
                          ) : (
                            routing
                          )} */}
                          {routing}
                        </ReportsProvider>
                      </OrgTicketsProvider>
                    </PracticesProvider>
                  </GlobalCoursewaresProvider>
                </EmployeesDataProvider>
              </SearchDataProvider>
            </ThemeProvider>
          </MapProvider>
        </CompensationEngineProvider>
      </Suspense>
    </CacheProvider>
  );
};

export default App;
