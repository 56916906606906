import PropTypes from 'prop-types';
import { Model, surveyLocalization } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import 'survey-core/i18n/hebrew';
// import 'survey-core/modern.min.css';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc
} from '@firebase/firestore';
import {
  Alert,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Lottie from 'lottie-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import questionElements from 'src/assets/lottie/noOrg.json';
import commonConfig from 'src/common/config';
import emailTemplates from 'src/common/emailsTemplates';
import ProgressComponent from 'src/components/Practices/ProgressComponent/ProgressComponent';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import getMomentDateValue from 'src/utils/getMomentDateValue';
import getOrgRef from 'src/utils/getOrgRef';
import { Survey } from 'survey-react-ui';
import './index.scss';
import ShowCorrectAndIncorrectSurveyAnswers from './ShowCorrectAndIncorrectSurveyAnswers';
import surveyThemeJson from './surveyTheme';

surveyLocalization.defaultLocale = 'he';

const SurveyComponent = ({
  surveyJSON,
  employee_ref,
  surveyAnswers,
  onClose,
  savingPathCollection,
  showAnswers = false,
  survey_id,
  type,
  employeeSignautre
}) => {
  SurveyComponent.propTypes = {
    surveyJSON: PropTypes.object,
    surveyAnswers: PropTypes.object,
    employee_ref: PropTypes.string,
    savingPathCollection: PropTypes.string,
    onClose: PropTypes.func,
    survey_id: PropTypes.any,
    showAnswers: PropTypes.bool,
    employeeSignautre: PropTypes.object,
    type: PropTypes.string
  };

  const { currentUser } = useCurrentUserContext();
  const { snackbarState, setSnackbarState } = useSnackbar();
  const { selectedOrg } = useSelectedOrg();
  const [isCompletedScore, setIsCompleted] = useState();
  const [isLoading, setLoading] = useState();
  const [nextEvaluationRefresh, setNextEvaluationRefresh] = useState({
    seconds: moment(new Date()).unix()
  });
  const [surveyDataAnswers, setSurveyDataAnswers] = useState();
  const [
    userDataAnswersRetrievedAfterUserFinished,
    setDataAnswersRetrievedAfterUserFinished
  ] = useState(false);
  const survey = new Model({
    ...surveyJSON,
    pagePrevText: 'הקודם',
    pageNextText: 'הבא',
    // previewText: 'הצגת תשובות',
    // editText: 'עריכת תשובות',
    showCompletedText: savingPathCollection ? 'סיים ושלח' : 'סיים'
  });
  survey.locale = 'he';
  console.log('survey', survey);

  const tempTheme = { ...surveyThemeJson };

  survey.applyTheme(tempTheme);

  const saveSurveyData = (
    surveyToSave,
    evaluation_id,
    org_ref,
    isPartial = false
  ) => {
    // if (callback) {
    //   callback(surveyToSave, evaluation_id, org_ref);
    // } else {
    const { data } = surveyToSave;
    const employeeEvaluationRef = savingPathCollection
      ? collection(db, savingPathCollection)
      : doc(
          db,
          `orgs/${org_ref}/employees/${employee_ref}/evaluations/${evaluation_id}`
        );
    const dataToSave = {
      process_answers: { ...data },
      process: surveyJSON,
      done_by_id: currentUser.user_doc_id,
      done_at: serverTimestamp(),
      isPartial,
      org_ref,
      employee_ref
    };
    if (survey.timerInfo) {
      dataToSave.timerInfo = survey.timerInfo;
    }
    if (savingPathCollection && type === 'knowledgeTest') {
      const parentDocPath = `orgs/${org_ref}/knowledgeTests/${survey_id}/responses/${currentUser.user_doc_id}`;
      const parentDocRef = doc(db, parentDocPath);

      // Ensure the parent document exists
      const parentDocData = {
        user_ref: currentUser.user_doc_id,
        post_id: survey_id,
        knowledge_test_id: survey_id,
        org_ref
      };
      // console.log(`${parentDocPath}:::`, parentDocData);

      // Write the parent document and then add to the subcollection
      setDoc(parentDocRef, parentDocData, { merge: true })
        .then(() => {
          // Convert savingPathCollection into a Firestore collection reference
          const historyCollectionRef = collection(db, savingPathCollection);
          // console.log('historyCollectionRef:', historyCollectionRef);

          // Add to the subcollection
          addDoc(historyCollectionRef, dataToSave).catch((err) => {
            console.error('Error adding to history subcollection:', err);
            setSnackbarState({
              ...snackbarState,
              open: true,
              severity: 'error',
              msg: 'לא הצלחנו לשמור את נתוני ההערכה, נסה שוב!'
            });
          });
        })
        .catch((err) => {
          console.error('Error creating parent document:', err);
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'error',
            msg: 'לא הצלחנו לשמור את נתוני ההערכה, נסה שוב!'
          });
        });
    } else {
      setDoc(employeeEvaluationRef, dataToSave).catch((err) => {
        console.log(err);
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'error',
          msg: 'לא הצלחנו לשמור את נתוני ההערכה, נסה שוב!'
        });
      });
    }
    // }
  };

  function calculateMaxScore(questions) {
    // console.log(questions);
    const numOfQuestions = questions.reduce(
      (acc, curr) => {
        // console.log(curr.rateMin, curr.rateMax);
        if (curr.getType() === 'rating') {
          if (
            surveyJSON.scoringMethod &&
            surveyJSON.scoringMethod.type === 'onlyRequired'
          ) {
            if (curr.isRequired) {
              // console.log(acc.required);
              return {
                ...acc,
                all: acc.all + 1,
                required: acc.required + 1,
                maxScore: acc.maxScore + 1
              };
            }
            return { ...acc, all: acc.all + 1 };
          }
          return {
            ...acc,
            all: acc.all + 1,
            maxScore: acc.maxScore + 1
          };
        }
        if (curr.correctAnswer) {
          return {
            ...acc,
            all: acc.all + 1,
            maxScore: acc.maxScore + 1
          };
        }
        return { ...acc };
      },
      { all: 0, required: 0, maxScore: 0 }
    );
    const { all, required, maxScore } = numOfQuestions;
    return { all, required, maxScore };
  }

  /**
   *
   * @param {[]} data
   * @param {{
   * all: number,
   * required: number,
   * maxScore: number
   * }} maxScore
   * @returns Number
   */
  function calculateTotalScore(data, maxScore, scoringMethod) {
    let totalScore = 0;

    // console.log(data);
    data.forEach((item) => {
      const question = survey.getQuestionByName(item.name);
      const {
        jsonObj: { correctAnswer }
      } = question;
      // console.log('q: ', question);
      // console.log('item: ', item);
      const qValue = item.value;
      if (correctAnswer) {
        if (Array.isArray(correctAnswer)) {
          const correctCount = correctAnswer.length;
          if (Array.isArray(qValue)) {
            // iterate over the qValue and check how many of them is included in correctAnswer
            const correctAnswersScore = qValue.reduce((acc, curr) => {
              if (correctAnswer.includes(curr)) {
                const weight = 1 / correctCount;
                return acc + weight;
              }
              return acc;
            }, 0);
            totalScore += correctAnswersScore;
          } else {
            if (correctAnswer.includes(qValue)) {
              totalScore += 1;
            }
          }
        } else {
          const correctCount = 1;
          if (qValue === correctAnswer) {
            totalScore += correctCount;
          }
        }
      } else {
        if (scoringMethod === 'onlyRequired' && question.isRequired) {
          // calcQuestionScore(question)
          if (question.getType() === 'rating') {
            // console.log(qValue);
            totalScore += qValue / (question.rateMax - question.rateMin);
          }
        } else if (scoringMethod === 'allQuestions') {
          if (question.getType() === 'rating') {
            // console.log(qValue);
            totalScore += qValue / (question.rateMax - question.rateMin);
          }
        }
      }
    });

    return totalScore / maxScore.maxScore;
  }
  survey.onPartialSend.add((sender) => {
    const { evaluation_id, org_ref } = surveyJSON;
    saveSurveyData(sender, evaluation_id, org_ref, true);
    // const plainData = sender.getPlainData({
    //   // Include `score` values into the data array
    //   calculations: [{ propertyName: 'score' }]
    // });
  });

  survey.onStarted.add(() => {
    setIsCompleted();
  });

  survey.onComplete.add((sender, options) => {
    const { evaluation_id, org_ref } = surveyJSON;
    const maxScore = calculateMaxScore(sender.getAllQuestions());
    // Get survey results as a flat data array
    const plainData = sender.getPlainData({
      // Include `score` values into the data array
      calculations: [{ propertyName: 'score' }]
    });
    // sender.timerInfo is => {
    //   "spent": 9,
    //   "limit": 120,
    //   "minorSpent": 9
    // }
    const method =
      surveyJSON.scoringMethod &&
      surveyJSON.scoringMethod.type === 'onlyRequired'
        ? 'onlyRequired'
        : 'allQuestions';
    const totalScore = calculateTotalScore(plainData, maxScore, method);
    console.log('plain data', plainData);
    if (showAnswers) {
      setSurveyDataAnswers({
        ...surveyDataAnswers,
        data: plainData,
        score: { totalScore, maxScore },
        scoringMethod: method
      });
    }

    // Save the scores in survey results
    sender.setValue('maxScore', maxScore.maxScore);
    sender.setValue('totalScore', totalScore);
    saveSurveyData(
      {
        ...sender,
        data: { ...sender.data, score: totalScore, scoringMethod: method },
        employee_ref
      },
      evaluation_id,
      org_ref
    );
    // survey.showCompletedPage = true;
    survey.showCompletePage = true;
    survey.completedHtml = savingPathCollection
      ? '<h3>מצוין! סיימת את השאלון והתוצאות נשמרו בהצלחה</h3>'
      : '<h3>מצוין! סיימת את המשוב והתוצאות נשמרו בהצלחה</h3>';
    setIsCompleted(totalScore);
  });

  // useEffect(() => {
  //   // handles displaying historical results of survey
  //   if (surveyAnswers && Object.keys(survey.data).length === 0) {
  //     setSurveyDataAnswers({ data: surveyAnswers });
  //   }
  // }, [surveyAnswers]);

  useEffect(() => {
    // handles the results showing in the last page when survey is completed (onCompleted)
    if (showAnswers && survey.data && Object.keys(survey.data).length > 0) {
      setSurveyDataAnswers({ ...surveyDataAnswers, data: surveyAnswers });
      surveyJSON.mode = 'display';
      surveyJSON.questionsOnPageMode = 'singlePage';
      survey.isPreviewButtonVisible = false;
    }
  }, [showAnswers]);

  useEffect(() => {
    if (
      surveyDataAnswers &&
      surveyDataAnswers.data &&
      Object.keys(survey.data).length === 0
    ) {
      survey.data = {
        ...surveyDataAnswers.data
      };
    }
  }, [surveyDataAnswers]);

  survey.sendResultOnPageNext = true;

  const sendSignEvaluationEmailToEmployee = async () => {
    setLoading(true);
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const orgDataRef = doc(db, `users/${org_ref}`);
    const orgData = await getDoc(orgDataRef)
      .then((res) => {
        if (!res.exists()) {
          return {};
        }
        return res.data();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return {};
      });

    const { org_name } = orgData;
    const employeeDataRef = doc(db, `users/${employee_ref}`);
    const employeeData = await getDoc(employeeDataRef)
      .then((res) => {
        if (!res.exists()) {
          return {};
        }
        return res.data();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return {};
      });
    const { email } = employeeData;
    const done_by =
      currentUser.type === 'org'
        ? `הארגון ${currentUser.org_name}`
        : `${currentUser.first_name} ${currentUser.last_name}`;
    const message = {
      subject: `בקשת חתימה על משוב ב-${org_name}`,
      html: emailTemplates.sendSignEvaluationEmailToEmployee(org_name, done_by)
    };
    const mailRef = collection(db, 'mail');
    addDoc(mailRef, {
      to: email,
      from: commonConfig.supportMail,
      message
    })
      .then(() => {
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'success',
          msg: 'המייל נשלח בהצלחה'
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'error',
          msg: 'בשל תקלה טכנית, לא הצלחנו לשלוח את המייל'
        });
      });
  };

  const handleSaveNextEvaluationRefresh = (newDate) => {
    const { evaluation_id, org_ref } = surveyJSON;
    // const org_ref = getOrgRef(currentUser, selectedOrg);
    const employeeEvaluationRef = doc(
      db,
      `orgs/${org_ref}/employees/${employee_ref}/evaluations/${evaluation_id}`
    );
    updateDoc(employeeEvaluationRef, {
      nextEvaluationRefresh: newDate
    })
      .then(() => {
        setSnackbarState({
          ...snackbarState,
          msg: 'תאריך המשוב הבא נשמר בהצלחה',
          open: true,
          severity: 'success'
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackbarState({
          ...snackbarState,
          msg: 'לא הצלחנו לשמור את התאריך, נא לנסות שוב',
          open: true,
          severity: 'error'
        });
      });
  };

  const handleRetrieveUserAnswersFromDb = async () => {
    try {
      const evalsHistoryRef = collection(db, savingPathCollection);
      const q = query(evalsHistoryRef, orderBy('done_at', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const data = querySnapshot.docs[0].data();
        return {
          data: data.process_answers,
          score: {
            totalScore: data.process_answers?.totalScore || 0,
            maxScore: data.process_answers?.maxScore || 0
          },
          scoringMethod: data.process_answers?.scoringMethod || 'allQuestions',
          timestamp: data.done_at
        };
      }
      return null;
    } catch (err) {
      console.error('Error retrieving answers:', err);
      return null;
    }
  };

  // Modify the useEffect to handle answers display logic
  useEffect(() => {
    const fetchAndDisplayAnswers = async () => {
      // Only fetch if:
      // 1. showAnswers is true
      // 2. It's a knowledge test
      // 3. We haven't fetched answers yet
      // 4. Test is completed (isCompletedScore is set)
      if (
        showAnswers &&
        type === 'knowledgeTest' &&
        !userDataAnswersRetrievedAfterUserFinished &&
        isCompletedScore !== undefined
      ) {
        const answers = await handleRetrieveUserAnswersFromDb();
        if (answers) {
          setSurveyDataAnswers(answers);
          setDataAnswersRetrievedAfterUserFinished(true);

          // Set survey to display mode after getting answers
          surveyJSON.mode = 'display';
          surveyJSON.questionsOnPageMode = 'singlePage';
          survey.isPreviewButtonVisible = false;
        }
      }
    };

    // fetchAndDisplayAnswers();
  }, [
    showAnswers,
    type,
    isCompletedScore,
    userDataAnswersRetrievedAfterUserFinished
  ]);

  return (
    <>
      {employeeSignautre &&
        employeeSignautre.employee_feedback &&
        employeeSignautre.employee_feedback.length > 0 && (
          <Stack>
            <Typography color="text.secondary">
              קיימת הערה שהעובד רשם בעת החתימה:
            </Typography>
            <Alert severity="info">{employeeSignautre.employee_feedback}</Alert>
          </Stack>
        )}
      {!showAnswers && isCompletedScore >= 0 && type !== 'knowledgeTest' ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '60vh', gap: 2 }}
        >
          <ProgressComponent
            key={`progressbar-${surveyJSON.evaluation_id}`}
            innerText={`10 / ${(isCompletedScore * 10).toFixed(1)}`}
            value={isCompletedScore * 100}
            size={100}
          />
          <Typography variant="h3" textAlign="center">
            מצוין!
            <br />
            סיימת את המשוב והתוצאות נשמרו
          </Typography>
          <LocalizationProvider
            localeText={{
              cancelButtonLabel: 'ביטול',
              okButtonLabel: 'אישור'
            }}
            dateAdapter={AdapterMoment}
          >
            <MobileDatePicker
              // views={['year', 'month']}
              showToolbar={false}
              label="תאריך הדוח הבא"
              // value={
              //   nextEvaluationRefresh &&
              //   nextEvaluationRefresh.seconds &&
              //   isDate(moment(nextEvaluationRefresh.seconds * 1000).toDate())
              //     ? moment(nextEvaluationRefresh.seconds * 1000).toDate()
              //     : new Date()
              // }
              value={getMomentDateValue(nextEvaluationRefresh)}
              disablePast
              // closeOnSelect={Boolean(false)}
              name="nextEvaluationRefresh"
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => (
                <TextField fullWidth sx={{ maxWidth: 320 }} {...params} />
              )}
              onChange={(e) => {
                // console.log(moment(e).unix());
                setNextEvaluationRefresh({
                  ...nextEvaluationRefresh,
                  seconds: moment(e).unix()
                });
                handleSaveNextEvaluationRefresh(moment(e).toDate());
              }}
            />
            <Stack
              direction="row"
              sx={{
                flexWrap: 'wrap'
              }}
              justifyContent={{
                sm: 'flex-start'
              }}
              alignItems="center"
              gap={1}
            >
              <Typography variant="caption">מתי לבצע ההערכה הבאה?</Typography>
              {[
                {
                  label: 'חודש',
                  id: 'month',
                  value: 1
                },
                {
                  label: 'חצי שנה',
                  id: 'halfYear',
                  value: 6
                },
                {
                  label: 'שנה',
                  id: 'year',
                  value: 12
                }
              ].map((nextChip) => (
                <Chip
                  key={nextChip.id}
                  label={nextChip.label}
                  variant="outlined"
                  color="success"
                  clickable
                  onClick={() => {
                    // console.log(nextChip.value);
                    const addedVal = moment(
                      moment(nextEvaluationRefresh.seconds * 1000).toDate()
                    )
                      .add(nextChip.value, 'months')
                      .unix();
                    setNextEvaluationRefresh({
                      ...nextEvaluationRefresh,
                      seconds: addedVal
                    });
                    handleSaveNextEvaluationRefresh(
                      moment(addedVal * 1000).toDate()
                    );
                  }}
                />
              ))}
            </Stack>
          </LocalizationProvider>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row'
            }}
            gap={1}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                maxWidth: 'fit-content !important',
                minWidth: 120
              }}
              onClick={() => sendSignEvaluationEmailToEmployee()}
            >
              שליחת מייל לעובד
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                maxWidth: 120
              }}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              סגירה
            </Button>
          </Stack>
        </Stack>
      ) : type === 'knowledgeTest' && isCompletedScore >= 0 ? (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: theme.palette.success.main,
              mt: '12px'
            }}
            variant="h4"
          >
            סיימת את המבדק והתוצאות נשמרו בהצלחה
          </Typography>
          {showAnswers ? (
            <ShowCorrectAndIncorrectSurveyAnswers
              survey={survey}
              surveyJSON={surveyJSON}
              dataAnswers={surveyDataAnswers.data}
              key={`show-correct-answers-knowledgeTest-${survey_id}`}
            />
          ) : (
            <Lottie
              animationData={questionElements}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: 300,
                maxHeight: 300,
                filter: 'drop-shadow(2px 2px 4px #8080805e)'
              }}
            />
          )}
        </Stack>
      ) : (
        <Survey model={survey} />
      )}
      {isLoading && (
        <Backdrop in={isLoading} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default SurveyComponent;
