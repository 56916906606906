import {
  Anchor,
  CellTowerOutlined,
  FullscreenOutlined,
  LockClockOutlined
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { uniqueId } from 'lodash';
import { useEffect, useRef } from 'react';
import theme from 'src/theme';

// Our Advantages Section
const OurAdvantages = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const cardsRef = useRef([]);

  useEffect(() => {
    const cards = cardsRef.current;

    gsap.fromTo(
      titleRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 70%',
          end: 'top 40%',
          toggleActions: 'play complete none none',
          scrub: false
        }
      }
    );

    cards.forEach((card, index) => {
      gsap.fromTo(
        card,
        { x: index % 2 === 0 ? -100 : 100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 0.8,
          delay: index * 0.2,
          scrollTrigger: {
            trigger: card,
            start: 'top 85%',
            end: 'top 65%',
            toggleActions: 'play complete none none',
            scrub: false
          }
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const advantages = [
    {
      title: 'מערכת כוללנית',
      id: 'comprehensive',
      icon: <FullscreenOutlined size={32} />,
      description:
        'שמפגישה את אנשי המקצוע שנותנים את השירותים ולא רק האנשים שמקבלים את השירותים אשר כולם מדרגים ומדורגים'
    },
    {
      title: 'מערכת מהירה ויעילה',
      id: 'efficient',
      icon: <CellTowerOutlined size={32} />,
      description: 'חוסכת זמן לכל החפץ בשירותיה'
    },
    {
      title: 'ראיה רוחבית ועמוקה',
      id: 'vision',
      icon: <Anchor size={32} />,
      description: 'מכירים את כולם ואפילו את הפרטים הכי קטנים שיש בכל ארגון'
    },
    {
      title: 'ניסיון ונוכחות בשטח',
      id: 'experience',
      icon: <LockClockOutlined size={32} />,
      description: 'אין חכם כמו בעל ניסיון'
    }
  ];

  return (
    <Box
      ref={sectionRef}
      sx={{
        py: { xs: 8, md: 12 },
        position: 'relative',
        background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        color: 'white',
        overflow: 'hidden'
      }}
    >
      {/* Decorative wave elements */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0.1,
          zIndex: 0,
          background:
            'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25" viewBox="0 0 1600 800"%3E%3Cg stroke="%23ffffff" stroke-width="66.7" stroke-opacity="0.05" %3E%3Ccircle fill="%23ff9d00" cx="0" cy="0" r="1800"/%3E%3Ccircle fill="%23fb8d17" cx="0" cy="0" r="1700"/%3E%3Ccircle fill="%23f47d24" cx="0" cy="0" r="1600"/%3E%3Ccircle fill="%23ed6e2d" cx="0" cy="0" r="1500"/%3E%3Ccircle fill="%23e35f34" cx="0" cy="0" r="1400"/%3E%3Ccircle fill="%23d85239" cx="0" cy="0" r="1300"/%3E%3Ccircle fill="%23cc453e" cx="0" cy="0" r="1200"/%3E%3Ccircle fill="%23be3941" cx="0" cy="0" r="1100"/%3E%3Ccircle fill="%23b02f43" cx="0" cy="0" r="1000"/%3E%3Ccircle fill="%23a02644" cx="0" cy="0" r="900"/%3E%3Ccircle fill="%238e1e44" cx="0" cy="0" r="800"/%3E%3Ccircle fill="%237c1d44" cx="0" cy="0" r="700"/%3E%3Ccircle fill="%236a1b43" cx="0" cy="0" r="600"/%3E%3Ccircle fill="%23581a41" cx="0" cy="0" r="500"/%3E%3Ccircle fill="%23461a3e" cx="0" cy="0" r="400"/%3E%3Ccircle fill="%2335193b" cx="0" cy="0" r="300"/%3E%3Ccircle fill="%23251738" cx="0" cy="0" r="200"/%3E%3Ccircle fill="%23151534" cx="0" cy="0" r="100"/%3E%3C/g%3E%3C/svg%3E")',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          ref={titleRef}
          sx={{
            textAlign: 'center',
            mb: 6
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 800,
              color: 'white !important',
              display: 'inline-block',
              position: 'relative',
              pb: 2,
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '80px',
                height: '4px',
                background: 'white'
              }
            }}
          >
            היתרונות שלנו במגדלור
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {advantages.map((advantage, index) => (
            <Grid key={`${advantage.id}-${uniqueId()}`} item xs={12} md={6}>
              <Card
                ref={(el) => (cardsRef.current[index] = el)}
                sx={{
                  height: '100%',
                  borderRadius: '16px',
                  background: alpha(theme.palette.common.white, 0.1),
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                  boxShadow: `0 10px 30px ${alpha(
                    theme.palette.common.black,
                    0.2
                  )}`,
                  color: 'white',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: `0 20px 40px ${alpha(
                      theme.palette.common.black,
                      0.3
                    )}`
                  }
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Stack direction="row" spacing={3} alignItems="flex-start">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        borderRadius: '12px',
                        flexShrink: 0,
                        background: alpha(theme.palette.common.white, 0.2),
                        color: 'white'
                      }}
                    >
                      <Typography variant="h4" fontWeight={700}>
                        {index + 1}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 700,
                          mb: 1.5
                        }}
                      >
                        {advantage.title}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          opacity: 0.9,
                          lineHeight: 1.6
                        }}
                      >
                        {advantage.description}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurAdvantages;
