import { Box } from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useState } from 'react';
import ContactFormAndDetailsSection from 'src/components/ContactUs/ContactFormAndDetailsSection';
import ExcellenceSection from 'src/components/OurVision/FixedGradientSuccessSection';
import OurAdvantages from 'src/components/OurVision/OurAdvanteges';
import OurCommitmentSection from 'src/components/OurVision/OurCommitmentSection';
import OurVisionHeroSection from 'src/components/OurVision/OurVisionHeroSection';

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Main Vision Page Component
const OurVision = () => {
  const scrollToNextRef = useRef(null);
  const scrollToContactFormRef = useRef(null);
  const [yourMsgWasSent, setYourMsgWasSent] = useState();

  const handleScrollToNext = () => {
    scrollToNextRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToForm = () => {
    scrollToContactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    const firstNameField = document.getElementsByName('contact_first_name');
    if (firstNameField.length > 0) {
      firstNameField[0].focus();
    }
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <OurVisionHeroSection onScrollDown={handleScrollToNext} />
      <Box ref={scrollToNextRef}>
        <OurAdvantages />
      </Box>
      <OurCommitmentSection />
      <ExcellenceSection onScorllToContactForm={handleScrollToForm} />
      <Box ref={scrollToContactFormRef}>
        <ContactFormAndDetailsSection
          setYourMsgWasSent={setYourMsgWasSent}
          yourMsgWasSent={yourMsgWasSent}
        />
      </Box>
    </Box>
  );
};

export default OurVision;
