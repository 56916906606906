import { collection, getCountFromServer } from '@firebase/firestore';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';

const DeleteCommunityPostDialog = ({
  open,
  onClose,
  postToDelete,
  handleDeletePost,
  handleArchievePost
}) => {
  DeleteCommunityPostDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    postToDelete: PropTypes.object,
    handleDeletePost: PropTypes.func,
    handleArchievePost: PropTypes.func
  };

  const [responsesOnPost, setResponsesOnPost] = useState();
  // console.log(postToDelete);

  const handleCheckPostReponses = async (test_id, org_ref) => {
    const testRef = collection(
      db,
      `orgs/${org_ref}/knowledgeTests/${test_id}/responses`
    );
    const count = await getCountFromServer(testRef)
      .then((res) => res.data().count)
      .catch((err) => {
        console.log(err);
      });
    console.log(count);
    setResponsesOnPost(count);
  };

  useEffect(() => {
    if (postToDelete) {
      const { knowledge_test_id, org_ref } = postToDelete;
      if (knowledge_test_id) {
        handleCheckPostReponses(knowledge_test_id, org_ref);
      } else {
        setResponsesOnPost(false);
      }
    }
  }, [postToDelete]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {`מחיקת ${
          postToDelete && postToDelete.knowledge_test_id ? 'מבדק ידע' : 'פוסט'
        }`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {responsesOnPost > 0 ? (
          <Alert severity="error">
            אי אפשר למחוק מבדק שיש עליו מענים, לחלופין תוכל להסתיר את זה
            מהעובדים
          </Alert>
        ) : (
          <Typography variant="body1">
            את/ה בטוח/ה שהינך רוצה למחוק פוסט זה?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            console.log(postToDelete);
            if (responsesOnPost === 0) {
              handleDeletePost(postToDelete);
            } else {
              handleArchievePost(postToDelete);
            }
          }}
          variant="contained"
          disabled={isUndefined(responsesOnPost)}
          color="error"
        >
          {responsesOnPost === 0 ? 'מחיקה' : 'הסתרה'}
        </Button>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCommunityPostDialog;
