import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where
} from '@firebase/firestore';
import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import LogRocket from 'logrocket';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, storage } from 'src/_firebase/firebase';

const UserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
  CurrentUserProvider.propTypes = {
    children: PropTypes.any
  };
  const [user, loading] = useAuthState(auth);
  const [currentUser, setCurrentUser] = useState();
  const [currentUserLoading, setCurrentUserLoading] = useState(false);
  // this is our anchor for loading and user, everything should
  // render according to this in order to forbid delays
  // currentUserLoading is to be true when we retrieve the user's data
  const currentUserData = useMemo(
    () => ({ currentUser, setCurrentUser, user, loading, currentUserLoading }),
    [currentUser, setCurrentUser, user, loading, currentUserLoading]
  );

  // Separate handlers for different user types
  const handleOrgUser = async (userData) => {
    try {
      const orgStorageRef = ref(
        storage,
        `orgs/${userData.user_doc_id}/orgPictures`
      );

      // Process geolocation data
      const _geoloc = {
        lng: userData?._geoloc?._long || userData?._geoloc?.lng || 35.11,
        lat: userData?._geoloc?._lat || userData?._geoloc?.lat || 32.71
      };

      // Get org images in parallel
      const listResult = await listAll(orgStorageRef);
      const imagesPromises = listResult.items.map(async (pic) => {
        const [metadata, url] = await Promise.all([
          getMetadata(pic),
          getDownloadURL(pic)
        ]);

        return {
          name: pic.name,
          url,
          full_path: pic.fullPath,
          metadata: metadata.customMetadata
        };
      });

      const images = await Promise.all(imagesPromises);

      setCurrentUser({
        ...userData,
        _geoloc,
        images
      });
    } catch (err) {
      console.error('Error processing org user:', err);
      setCurrentUser(userData); // Fallback to basic user data
    } finally {
      setCurrentUserLoading(false);
    }
  };

  const handlePersonalUser = async (userData) => {
    try {
      const employeeWorkplacesRef = collection(
        db,
        'users',
        userData.user_doc_id,
        'employee_work_places'
      );

      const docsSnapshot = await getDocs(employeeWorkplacesRef);
      if (docsSnapshot.empty) {
        setCurrentUser({
          ...userData,
          employee_work_places: []
        });
        return;
      }

      // Process workplaces in parallel
      const workplacesPromises = docsSnapshot.docs.map(async (workplace) => {
        try {
          const workplaceData = workplace.data();
          const orgRef = doc(db, `users/${workplaceData.org_ref}`);
          const [orgDoc, orgSpecificDocument] = await Promise.all([
            getDoc(orgRef),
            getDoc(
              doc(
                db,
                `orgs/${workplaceData.org_ref}/employees/${workplaceData.employee_ref}`
              )
            )
          ]);

          if (!orgDoc.exists()) return null;

          // Process RBAC data if exists
          let org_specific_details_for_employee = {};
          if (orgSpecificDocument.exists()) {
            const orgSpecificData = orgSpecificDocument.data();
            org_specific_details_for_employee = { ...orgSpecificData };

            if (orgSpecificData.rbac?.length > 0) {
              // Get all role permissions in parallel
              const rolePromises = orgSpecificData.rbac.map(
                async (rbac, index) => {
                  if (!rbac.role_ref) return null;
                  const roleDoc = await getDoc(
                    doc(
                      db,
                      `orgs/${orgSpecificData.org_ref}/roles/${rbac.role_ref}`
                    )
                  );
                  return roleDoc.exists()
                    ? { index, role: roleDoc.data() }
                    : null;
                }
              );

              const roles = (await Promise.all(rolePromises)).filter(Boolean);
              roles.forEach(({ index, role }) => {
                org_specific_details_for_employee.rbac[index].role = role;
              });
            }
          }

          const {
            org_name,
            phone,
            type,
            geolocation,
            description,
            dateOfEstablishment,
            date_of_establishment,
            address,
            email,
            id,
            _geoloc,
            org_status,
            ...rest
          } = orgDoc.data();

          return {
            ...workplaceData,
            org_name,
            phone,
            type,
            geolocation,
            description,
            address,
            date_of_establishment: dateOfEstablishment || date_of_establishment,
            email,
            id,
            _geoloc,
            org_specific_details: org_specific_details_for_employee,
            org_status,
            ...rest
          };
        } catch (err) {
          console.error('Error processing workplace:', err);
          return null;
        }
      });

      const workplaces = (await Promise.all(workplacesPromises)).filter(
        Boolean
      );

      setCurrentUser({
        ...userData,
        employee_work_places: workplaces
      });
    } catch (err) {
      console.error('Error processing personal user:', err);
      setCurrentUser({
        ...userData,
        employee_work_places: []
      });
    } finally {
      setCurrentUserLoading(false);
    }
  };

  const handleUserDataRetrieve = async (emailWithIdPrefix) => {
    try {
      const user_id = emailWithIdPrefix.split('@')[0];
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('id', '==', user_id), limit(1));

      // Get user data
      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        setCurrentUserLoading(false);
        return;
      }

      const userData = {
        ...snapshot.docs[0].data(),
        user_doc_id: snapshot.docs[0].id
      };

      // Handle different user types
      if (userData.type === 'org') {
        await handleOrgUser(userData);
      } else {
        await handlePersonalUser(userData);
      }
    } catch (err) {
      console.error('Error retrieving user data:', err);
      setCurrentUserLoading(false);
    }
  };

  useEffect(() => {
    if (user && loading) {
      setCurrentUserLoading(true);
      handleUserDataRetrieve(user.email);
    }
    if (!user && loading) {
      setCurrentUserLoading(true);
    }
    if (user && !loading) {
      handleUserDataRetrieve(user.email);
      setCurrentUserLoading(false);
    }
    if (!user && !loading) {
      setCurrentUserLoading(false);
    }
  }, [user, loading]);

  useEffect(() => {
    if (currentUser) {
      // const usersLists = [
      //   'toku50Exj8TvMOg5WnOq',
      //   'oCRsyZprNOTmrpj8qRdQ',
      //   'HmIuVgFq3QCPuTeYIhMD'
      // ];
      const { user_doc_id } = currentUser;
      // if (usersLists.includes(user_doc_id)) {
      const { type, org_name, first_name, last_name, email, diamonds, id } =
        currentUser;
      LogRocket.identify(user_doc_id, {
        email,
        name: type === 'personal' ? `${first_name} ${last_name}` : org_name,
        id,
        diamonds,
        user_doc_id,
        type
      });
      // }
    }
  }, [currentUser]);
  return (
    <UserContext.Provider value={currentUserData}>
      {children}
    </UserContext.Provider>
  );
};
export const useCurrentUserContext = () => useContext(UserContext);
