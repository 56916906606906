import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc
} from '@firebase/firestore';
import { CloseOutlined, PhoneOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { db } from 'src/_firebase/firebase';
import lighthouse from 'src/assets/contactLighthouse.webp';
import animationData from 'src/assets/lottie/message-sent.json';
import emailTemplates from 'src/common/emailsTemplates';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import * as Yup from 'yup';
import RotatingCardFront from '../RotatingCard/RotatingCardFront';
import RelationShipFields from './RelationshipField';

const OrgSpecificContactForm = ({
  org_ref,
  org_name = 'mgdalor',
  isPrivacyDialogOpen,
  setPrivacyDialogOpen
}) => {
  OrgSpecificContactForm.propTypes = {
    org_ref: PropTypes.string,
    org_name: PropTypes.string,
    isPrivacyDialogOpen: PropTypes.bool,
    setPrivacyDialogOpen: PropTypes.func
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });
  const [yourMsgWasSent, setYourMsgWasSent] = useState('init');

  const addNewTicket = (newValues) => {
    const createdAt = serverTimestamp();
    // console.log(org_ref);
    const contactTicketsRef = collection(
      db,
      'contactTickets',
      org_ref,
      'tickets'
    );
    // console.log(newValues);
    addDoc(contactTicketsRef, {
      ...newValues,
      type: org_ref === 'mgdalor' ? 'mgdalor' : 'org',
      createdAt,
      org_ref
    })
      .then(() => {
        const mailsRef = collection(db, 'mail');
        // console.log(org_ref, 'mmail is being sent');
        let org_name_to_show;
        if (org_ref === 'mgdalor') {
          org_name_to_show = 'מגדלור';
        } else {
          if (org_name) {
            org_name_to_show = org_name;
          } else {
            org_name_to_show = 'אין מידע';
          }
        }
        addDoc(mailsRef, {
          to:
            process.env.REACT_APP_DEV === 'true'
              ? ['morad890@gmail.com']
              : ['support@mgdalor.com', 'mgdalor1@gmail.com'],
          message: {
            subject: 'קיימת פנייה חדשה דרך מערכת מגדלור',
            html: emailTemplates.newContactFromOrgPage(
              newValues.phone || 'אין מידע',
              newValues.contact_first_name || 'אין מידע',
              newValues.patient_first_name || 'אין מידע',
              newValues.patient_last_name || 'אין מידע',
              org_name_to_show
            )
          }
        })
          .then(() => console.log('sent mail'))
          .catch((err) => console.log(err));
        setYourMsgWasSent('success');
      })
      .catch((err) => {
        console.log(err);
        setYourMsgWasSent('failure');
      });
  };
  const handleSaveNewContactFormToDb = (contactValues) => {
    const orgDoc = doc(db, `contactTickets/${org_ref}`);
    getDoc(orgDoc)
      .then((val) => {
        if (val.exists()) {
          addNewTicket(contactValues);
        } else {
          setDoc(orgDoc, {
            org_ref,
            type: org_ref === 'mgdalor' ? 'mgdalor' : 'org'
          }).then(() => {
            addNewTicket(contactValues);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openPrivacyDialog = () => {
    // console.log(1);
    setPrivacyDialogOpen(true);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        phone: '',
        description: '',
        patient_first_name: '',
        patient_last_name: '',
        contact_first_name: '',
        relation: '',
        patient_age: '',
        policy: true
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validEmail')).max(255),
        patient_first_name: Yup.string()
          .max(255)
          .required(t('firstNameRequired')),
        patient_age: Yup.string().max(255).required(t('patientAgeRequired')),
        relation: Yup.string().max(255),
        contact_first_name: Yup.string()
          .max(255)
          .required(t('firstNameRequired')),
        patient_last_name: Yup.string()
          .max(255)
          .required(t('lastNameRequired')),
        phone: Yup.string()
          .max(60)
          .min(9, 'מספר טלפון לא יכול להיות פחות מ9 ספרות')
          .required(t('phoneRequired')),
        description: Yup.string().max(1000, t('maxIs1000')),
        policy: Yup.boolean().oneOf([true], t('policyChecked'))
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        initialValues,
        setTouched,
        initialErrors,
        initialTouched,
        setValues
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
            handleSaveNewContactFormToDb(values);
          }}
        >
          <Grid container spacing={2.5}>
            {yourMsgWasSent === 'success' ? (
              <Grid item xs={12}>
                <Slide direction="right" in={yourMsgWasSent === 'success'}>
                  <Stack
                    sx={{
                      width: '100%',
                      minHeight: '40vh',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Lottie
                      style={{
                        width: '70% !important',
                        height: '120px',
                        minWidth: '120px'
                        // marginBottom: 0,
                        // marginTop: '24px'
                      }}
                      autoplay
                      animationData={animationData}
                    />
                    <Alert sx={{ margin: 'auto', mt: 1 }} severity="success">
                      {t('yourMsgWasSent')}
                    </Alert>
                    <Typography>{t('haveAnotherTicketToSubmit')}</Typography>
                    <Button
                      sx={{
                        minWidth: 'fit-content',
                        width: 200,
                        margin: 'auto'
                      }}
                      variant="outlined"
                      onClick={() => {
                        setValues(initialValues);
                        setTouched(initialTouched);
                        setYourMsgWasSent('init');
                      }}
                    >
                      {t('sendAgain')}
                    </Button>
                  </Stack>
                </Slide>
              </Grid>
            ) : yourMsgWasSent === 'failure' ? (
              <Slide direction="right" in={yourMsgWasSent === 'failure'}>
                <Alert sx={{ margin: 'auto', mt: 1 }} severity="error">
                  {t('weCouldntSendYourMsg')}
                </Alert>
              </Slide>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error={Boolean(
                      touched.patient_first_name && errors.patient_first_name
                    )}
                    fullWidth
                    helperText={
                      touched.patient_first_name && errors.patient_first_name
                    }
                    label={t('patientFirstName')}
                    name="patient_first_name"
                    required
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.patient_first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error={Boolean(
                      touched.patient_last_name && errors.patient_last_name
                    )}
                    fullWidth
                    helperText={
                      touched.patient_last_name && errors.patient_last_name
                    }
                    required
                    label={t('patientLastName')}
                    name="patient_last_name"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.patient_last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error={Boolean(touched.patient_age && errors.patient_age)}
                    fullWidth
                    helperText={touched.patient_age && errors.patient_age}
                    required
                    label={t('patientAge')}
                    name="patient_age"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.patient_age}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error={Boolean(
                      touched.contact_first_name && errors.contact_first_name
                    )}
                    fullWidth
                    helperText={
                      touched.contact_first_name && errors.contact_first_name
                    }
                    required
                    label={t('contactFirstName')}
                    name="contact_first_name"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.contact_first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <RelationShipFields
                    key="relationship-in-org-specific"
                    errors={errors}
                    newValues={values}
                    setValues={setValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    required
                    label={t('contactPhone')}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const replaced = e.target.value.replace(/[^\d]+/g, '');
                      // console.log(replaced);
                      if (replaced) {
                        handleChange(e);
                      }
                    }}
                    type="phone"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t('email')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline
                    // rows={3}
                    maxRows={5}
                    helperText={touched.description && errors.description}
                    label={t('description')}
                    name="description"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="email"
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox
                      checked={values.policy}
                      name="policy"
                      // onChange={handleChange}
                      onChange={(e, checked) => {
                        setValues({ ...values, policy: checked });
                      }}
                    />
                    <Typography color="textSecondary" variant="body1">
                      {t('iHaveReadThe')}
                      &nbsp;
                      <Link onClick={() => openPrivacyDialog()}>
                        {t('termsAndConditions')}
                      </Link>
                      {/* <Button
                        color="primary"
                        LinkComponent="a"
                        onClick={() => openPrivacyDialog()}
                        variant="text"
                      >
                        {t('termsAndConditions')}
                      </Button> */}
                    </Typography>
                  </Stack>
                </Grid>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Grid
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  item
                  xs={12}
                  md={12}
                >
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={!values.policy}
                  >
                    {t('send')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const OrgPageContactForm = ({ orgToContact, customSx }) => {
  OrgPageContactForm.propTypes = {
    orgToContact: PropTypes.object,
    customSx: PropTypes.object
  };
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });
  const [imageToDisplay, setImageToDisplay] = useState();

  const getMainPicOfOrg = (orgValues) => {
    if (
      orgValues &&
      orgValues.org_pictures &&
      orgValues.org_pictures.length > 0
    ) {
      const indexOfMain = orgValues.org_pictures.findIndex(
        (el) => el.metadata && el.metadata.mainPic === 'true'
      );
      if (indexOfMain > -1) {
        return orgValues.org_pictures[indexOfMain].url;
      }
      return orgValues.org_pictures[0].url;
    }
    return lighthouse;
  };

  useEffect(() => {
    // console.log(orgToContact);
    if (orgToContact && orgToContact.org_pictures) {
      const pic = getMainPicOfOrg(orgToContact);
      console.log(pic);
      setImageToDisplay(pic);
    }
  }, [orgToContact]);
  const [isPrivacyDialogOpen, setPrivacyDialogOpen] = useState(false);

  return (
    <>
      <Card
        elevation={6}
        sx={{
          display: 'flex',
          flex: 1,
          width: '95%',
          mb: 12,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#dcd6d64f',
          mx: 'auto',
          ...customSx
        }}
      >
        <Typography variant="h1" sx={{ textAlign: 'center', my: 2 }}>
          {`${t('contact')} ${orgToContact.org_name}`}
        </Typography>

        <Button variant="outlined">
          <Typography
            variant="button"
            sx={{ textAlign: 'end', mx: 'auto' }}
            width="90%"
            component="a"
            href={`tel:${orgToContact.internal_phone}`}
          >
            {orgToContact.internal_phone}
          </Typography>
          <Icon className="phonePulse" sx={{ width: 42, height: 42 }}>
            <PhoneOutlined />
          </Icon>
        </Button>
        <CardContent sx={{ mt: 0, width: '100%' }}>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            {matchDownMd ? (
              <>
                <Grid item xs={12} md={6}>
                  <RotatingCardFront
                    image={imageToDisplay}
                    title={
                      <>
                        {t('ourProfessionalStaff')}
                        <br />
                        {t('WillBeReadyToAssist')}
                      </>
                    }
                    description={t('cardDescription')}
                    customSx={{
                      height: '100%'
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OrgSpecificContactForm
                    org_ref={orgToContact.user_doc_id}
                    isPrivacyDialogOpen={isPrivacyDialogOpen}
                    setPrivacyDialogOpen={setPrivacyDialogOpen}
                    org_name={orgToContact.org_name}
                    key="org-specific-contact-form--mobile"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <OrgSpecificContactForm
                    org_ref={orgToContact.user_doc_id}
                    org_name={orgToContact.org_name}
                    isPrivacyDialogOpen={isPrivacyDialogOpen}
                    setPrivacyDialogOpen={setPrivacyDialogOpen}
                    key="org-specific-contact-form--desktop"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RotatingCardFront
                    // image={lighthouse}
                    image={imageToDisplay}
                    title={
                      <>
                        {t('ourProfessionalStaff')}
                        <br />
                        {t('WillBeReadyToAssist')}
                      </>
                    }
                    description={t('cardDescription')}
                    customSx={{
                      height: '100%'
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
      {isPrivacyDialogOpen && (
        <Dialog
          onClose={() => setPrivacyDialogOpen(false)}
          open={isPrivacyDialogOpen}
          fullScreen
        >
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <IconButton
              onClick={() => setPrivacyDialogOpen(false)}
              sx={{ width: 'fit-content' }}
            >
              <CloseOutlined />
            </IconButton>
          </Stack>
          <PrivacyPolicy
            pageCustomSx={{
              overflow: 'auto'
            }}
            showSpacingBox={Boolean(false)}
          />
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setPrivacyDialogOpen(false)}
            >
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default OrgPageContactForm;
