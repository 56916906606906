import { doc, increment, updateDoc } from '@firebase/firestore';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { auth, db, functions } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';
import diamonds from 'src/common/diamonds';
import handleCreateNewOrg from 'src/utils/handleCreateNewOrg';
import handleCreateNewPersonalAccount from 'src/utils/handleCreateNewPersonalAccount';

const VerifyUser = ({ isOpen, closeDialog, newUserValues, userType }) => {
  VerifyUser.propTypes = {
    isOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    newUserValues: PropTypes.object,
    userType: PropTypes.string
  };
  const [createUserWithEmailAndPassword] =
    useCreateUserWithEmailAndPassword(auth);
  const { phone } = newUserValues;
  const { t } = useTranslation('translation', {
    keyPrefix: 'register.verifyDialog'
  });
  const [verifyCodeInput, setVerifyCodeInput] = useState();
  const [verifyCodeError, setVerifyCodeError] = useState();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCheckCode = async (e) => {
    if (isProcessing) return; // Prevent multiple clicks

    setIsProcessing(true);
    setLoading(true);

    try {
      const checkVerifyCode = httpsCallable(
        functions,
        'secondGencheckVerifyCode'
      );
      const res = await checkVerifyCode({
        verify_code_input: verifyCodeInput,
        phone: `+972${phone}`
      });

      const { data } = res;
      if (data.codeIsCorrect) {
        if (userType === 'personal') {
          const referralData = window.sessionStorage.getItem('referralData');
          if (referralData) {
            const parsed = JSON.parse(referralData);
            const { referralDoc } = parsed;
            newUserValues.referred_by = referralDoc;
            const referralRef = doc(db, `users/${referralDoc}`);
            console.log(parsed);
            await updateDoc(referralRef, {
              diamonds: increment(diamonds.friendReferral)
            }).catch((err) => console.error('Error updating referral:', err));
          }

          console.log(newUserValues);

          await handleCreateNewPersonalAccount(
            newUserValues,
            createUserWithEmailAndPassword,
            userType
          );
        } else {
          const { password, ...rest } = newUserValues;
          await handleCreateNewOrg(rest, e);
          await createUserWithEmailAndPassword(
            `${newUserValues.id}@${commonConfig.emailAuthSuffix}`,
            password
          );
        }
      } else {
        setVerifyCodeError('הקוד שגוי, נא לנסות שוב');
      }
    } catch (error) {
      console.error('Verification error:', error);
      setVerifyCodeError('נא לנסות שוב');
    } finally {
      setLoading(false);
      setIsProcessing(false); // Reset processing state
    }
  };
  const handleVerifyCodeChange = (e) => {
    setVerifyCodeInput(e.target.value);
  };
  return (
    <>
      <Dialog maxWidth="xs" fullWidth open={Boolean(true)}>
        <DialogTitle>{t('chooseYourPreferance')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('aCodeWasSentToYourPhone')}</DialogContentText>
          <DialogContentText>{`${t('sentTo')}: ${phone}`}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="verifyCode"
            label={t('verifyCode')}
            type="text"
            onChange={handleVerifyCodeChange}
            fullWidth
          />
          {verifyCodeError && (
            <Typography
              sx={{
                color: (theme) => theme.palette.error.dark
                // textAlign: 'center'
              }}
            >
              {verifyCodeError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('cancel')}</Button>
          <Button disabled={loading} onClick={handleCheckCode}>
            {t('verify')}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => 1800 }} open={loading}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default VerifyUser;
