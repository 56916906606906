import { DifferenceOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  List,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ComparisonTableDialog from 'src/components/Home/ComparisonTableDialog';
import 'src/components/Results/searchResults.css';
import MainMap from 'src/components/map/Map';
import OrgDirectionsDialog from 'src/components/shared/OrgDirectionsDialog/OrgDirectionsDialog';
import ResultListItem from 'src/components/shared/ResultListItem/ResultListItem';
import SearchButtonGroup from 'src/components/shared/SearchButtonGroup/SearchButtonGroup';
import SearchLoader from 'src/components/shared/SearchLoader/SearchLoader';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import handleAdoptionForCompareTable from 'src/utils/handleAdoptionForCompareTable';
import handleGTag from 'src/utils/handleGTag';

const SearchResults = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'searchResults' });
  const mapContainer = useRef(null);
  const [checkedToCompare, setCheckedToCompare] = useState([]);
  const [selectedToExamine, setSelectedToExamine] = useState();
  const [isCompareDialogOpen, setCompareDialogOpen] = useState(false);
  const [dataToCompare, setDataToCompare] = useState();
  const {
    searchResults,
    setSliderDistanceValue,
    searchQuery,
    setSearchQuery,
    areResultsLoading
  } = useSearchData();

  const [farResults, setFarResults] = useState();
  const [withinResults, setWithinResults] = useState();
  const [orgsPictures, setOrgsPictures] = useState();
  const [isDirectionsDialogOpen, setOpenDirectionsDialog] = useState();
  const [isResultCollapseOpen, setResultCollapseOpen] = useState({
    state: false
  });

  const handleSelectHome = (home) => {
    const selectedIndex = checkedToCompare.findIndex(
      (el) => el.user_doc_id === home.user_doc_id
    );
    const newSelected = [...checkedToCompare];
    if (selectedIndex === -1) {
      newSelected.push(home);
    } else if (selectedIndex === 0) {
      newSelected.shift();
    } else if (selectedIndex === checkedToCompare.length - 1) {
      newSelected.pop();
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setCheckedToCompare(newSelected);
  };

  const handleOpenCompareDialog = () => {
    setCompareDialogOpen(true);
  };
  const handleCloseCompareDialog = () => {
    setOrgsPictures();
    setCompareDialogOpen(false);
  };

  const handleSliderValueChangeInResults = (e, newValue) => {
    setSliderDistanceValue(newValue);
  };

  const handleAddressChangeInResults = (e) => {
    setSearchQuery({ ...searchQuery, text: e.target.value });
  };

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      const tempFarResults = [];
      const tempWithinResults = [];
      for (let index = 0; index < searchResults.length; index++) {
        const home = searchResults[index];
        if (
          home._rankingInfo.matchedGeoLocation.distance >
          JSON.parse(localStorage.getItem('search'))[0].distanceRadius * 1000 // in meters
        ) {
          tempFarResults.push(home);
        } else {
          tempWithinResults.push(home);
          // console.log(index);
        }
      }
      setFarResults(tempFarResults);
      setWithinResults(tempWithinResults);
    } else {
      setFarResults([]);
      setWithinResults([]);
    }
  }, [searchResults]);

  const normalizeDataForList = async () => {
    const listData = await handleAdoptionForCompareTable(checkedToCompare);
    setDataToCompare(listData);
  };

  useEffect(() => {
    if (checkedToCompare && checkedToCompare.length > 0) {
      normalizeDataForList();
    }
  }, [checkedToCompare]);

  const handleOpenDirectionsDialog = (selectedHome, localizedAddress) => {
    setOpenDirectionsDialog(localizedAddress);
  };

  const handleCloseDirectionsDialog = () => {
    setOpenDirectionsDialog();
  };

  return (
    <>
      <Helmet>
        <title>{t('searchResults')}</title>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="1 days" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
        <link rel="canonical" href="https://www.mgdalor.com/search" />
      </Helmet>
      <Stack
        sx={{ background: 'radial-gradient(#a4befc26, #ece9e600)' }}
        my={12}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container>
          <Grid item sx={{ justifyContent: 'center', display: 'flex' }} xs={12}>
            <SearchButtonGroup
              handleAddressChange={handleAddressChangeInResults}
              handleSliderValueChange={handleSliderValueChangeInResults}
              searchAddress={searchQuery.text}
              key="search-buttongroup-in-results"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12} container spacing={1}>
              <Grid
                item
                sx={{
                  paddingTop: 0,
                  mt: 2,
                  backdropFilter: 'blur(600px) saturate(3)',
                  borderRadius: 2,
                  boxShadow: theme.shadows[8]
                  // mx: 1
                }}
                xs={12}
              >
                <Stack
                  pr={2}
                  direction="row"
                  justifyContent="space-between"
                  gap={1}
                  my={1}
                >
                  <Stack direction="row" alignItems="center">
                    <Typography
                      width={50}
                      sx={{
                        textAlign: 'justify',
                        mr: 2,
                        ml: 3,
                        width: 'fit-content',
                        fontSize: '1rem'
                        // lineHeight: '1.5rem'
                      }}
                      variant="caption"
                    >
                      סמן כדי להשוות
                    </Typography>
                  </Stack>
                  {checkedToCompare && checkedToCompare.length > 0 && (
                    <Tooltip arrow title={t('compareButtonLabel')}>
                      <Button
                        onClick={() => handleOpenCompareDialog()}
                        variant="outlined"
                      >
                        <DifferenceOutlined sx={{ mr: 1 }} size="small" />
                        {`${checkedToCompare.length}`}
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
                <Stack
                  sx={{ pl: 9, gap: 1 }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography color="GrayText" fontWeight="bold" variant="h6">
                    {`${withinResults ? withinResults.length : 0} `}
                  </Typography>
                  <Typography variant="body2" color="GrayText">
                    {t('resultsInDesiredDistance')}
                  </Typography>
                </Stack>
                <List
                  disablePadding
                  sx={{
                    width: '100%',
                    overflowY: 'auto',
                    height: '70vh'
                  }}
                >
                  {!withinResults ? (
                    <LinearProgress />
                  ) : withinResults && withinResults.length === 0 ? (
                    <Typography variant="caption" sx={{ mx: 2 }}>
                      {t('noHomesWereFoundForYourSearch')}
                    </Typography>
                  ) : (
                    withinResults.map((home) => (
                      <ResultListItem
                        home={home}
                        checkedToCompare={checkedToCompare}
                        handleSelectHome={handleSelectHome}
                        selectedToExamine={selectedToExamine}
                        setSelectedToExamine={setSelectedToExamine}
                        key={`within-result-${home.user_doc_id}`}
                        setResultCollapseOpen={setResultCollapseOpen}
                        isResultCollapseOpen={isResultCollapseOpen}
                        handleOpenDirectionsDialog={handleOpenDirectionsDialog}
                      />
                    ))
                  )}
                  {farResults && farResults.length > 0 && (
                    <>
                      {localStorage.getItem('search') &&
                        JSON.parse(localStorage.getItem('search')).length >
                          0 && (
                          <Stack sx={{ py: 4 }}>
                            <Typography
                              textAlign="center"
                              sx={{
                                mb: 2,
                                color: 'GrayText',
                                fontSize: '15px'
                              }}
                              variant="caption"
                            >
                              {t('theseAreResultsUpToFar')}
                            </Typography>
                          </Stack>
                        )}
                    </>
                  )}
                  <Divider sx={{ my: 1 }} />
                  {!farResults ? (
                    <LinearProgress />
                  ) : farResults && farResults.length > 0 ? (
                    farResults.map((home, index) => (
                      <ResultListItem
                        home={home}
                        checkedToCompare={checkedToCompare}
                        handleSelectHome={handleSelectHome}
                        selectedToExamine={selectedToExamine}
                        setSelectedToExamine={setSelectedToExamine}
                        key={`far-result-${home.user_doc_id}`}
                        setResultCollapseOpen={setResultCollapseOpen}
                        isResultCollapseOpen={isResultCollapseOpen}
                        handleOpenDirectionsDialog={handleOpenDirectionsDialog}
                        index={index}
                      />
                    ))
                  ) : null}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: 2 }}>
            <div
              style={{
                height: '100%',
                width: '100%',
                boxShadow: theme.shadows[8],
                borderRadius: '8px',
                margin: '8px 0'
              }}
              className="main-map-container"
            >
              <MainMap
                markers={searchResults}
                mapContainer={mapContainer}
                selectedToExamine={selectedToExamine}
                setResultCollapseOpen={setResultCollapseOpen}
                isResultCollapseOpen={isResultCollapseOpen}
              />
            </div>
          </Grid>
        </Grid>
      </Stack>
      {checkedToCompare && checkedToCompare.length > 0 ? (
        <ComparisonTableDialog
          handleClose={handleCloseCompareDialog}
          open={isCompareDialogOpen}
          comparisonList={checkedToCompare}
          list={dataToCompare}
          setDataToCompare={setDataToCompare}
          orgsPictures={orgsPictures}
          setOrgsPictures={setOrgsPictures}
        />
      ) : null}
      {areResultsLoading && (
        <SearchLoader
          key="loader-in-home-for-results"
          open={areResultsLoading}
        />
      )}
      {isDirectionsDialogOpen && (
        <OrgDirectionsDialog
          open={Boolean(isDirectionsDialogOpen)}
          onClose={handleCloseDirectionsDialog}
          selectedHome={isDirectionsDialogOpen}
        />
      )}
    </>
  );
};

export default SearchResults;
