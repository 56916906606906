import {
  collection,
  collectionGroup,
  getDocs,
  orderBy,
  query,
  where
} from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const getPurchasedKnowledgeTests = async (userDocId) => {
  const querySnapshot = collectionGroup(db, 'purchases');
  const q = query(
    querySnapshot,
    where('buyer_id', '==', userDocId),
    where('type', '==', 'knowledgeTest')
  );
  const purchasedTestsQuery = await getDocs(q).then((testsDocs) => {
    if (testsDocs.empty) {
      return [];
    }
    return testsDocs.docs.map((test) => ({
      ...test.data(),
      id: test.id,
      knowledge_test_id: test.id,
      source: 'purchased',
      purchased_item_full_path: test.ref.path
    }));
  });

  const knowledgeTests = {};
  purchasedTestsQuery.forEach((test) => {
    knowledgeTests[test.knowledge_test_id] = test;
  });

  await Promise.all(
    Object.values(knowledgeTests).map(async (test) => {
      console.log(test);
      const {
        original_details: {
          test_details: { knowledge_test_id, knowledgeTest }
        },
        purchased_item_full_path
      } = test;
      const pagesCollection = collection(
        db,
        `${purchased_item_full_path}/knowledgeTest/${knowledge_test_id}/pages`
      );
      const tempQ = query(pagesCollection, orderBy('order', 'asc'));
      const pagesSnapshot = await getDocs(tempQ);
      test.knowledgeTest = {
        ...knowledgeTest,
        pages: pagesSnapshot.docs.map((pageDoc) => ({
          id: pageDoc.id,
          ...pageDoc.data()
        }))
      };
    })
  );

  const sortedKnowledgeTests = Object.values(knowledgeTests).sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  );

  return sortedKnowledgeTests;
};

export default getPurchasedKnowledgeTests;
