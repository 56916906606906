import { collection, getDocs, orderBy, query } from '@firebase/firestore';
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  EditOutlined,
  InfoOutlined,
  ShoppingCartOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import cardPic from 'src/assets/moreToRead/process.webp';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import getOrgRef from 'src/utils/getOrgRef';
import marketplaceCategories from './marketplaceCategories';

const ProductCard = ({
  product,
  handleOpenVerifyPurchaseRequest,
  handleOpenAddProduct,
  handleBookmarkProduct,
  marketplaceView
}) => {
  ProductCard.propTypes = {
    product: PropTypes.object,
    handleOpenVerifyPurchaseRequest: PropTypes.func,
    handleOpenAddProduct: PropTypes.func,
    handleBookmarkProduct: PropTypes.func,
    marketplaceView: PropTypes.string
  };
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();

  const [isLabelExpanded, setIsLabelExpanded] = useState(false);
  const [knowledgeTestPages, setKnowledgeTestPages] = useState();
  const [showDetails, setShowDetails] = useState(false);

  const getLabelOfMarketplaceProduct = () => {
    const { category } = product;

    // Early return if no categories
    if (!category || category.length === 0) {
      return '';
    }

    const [first] = category;
    let productLabel;

    // Handle different category formats
    if (first.inputValue) {
      // User input value
      productLabel = first.inputValue;
    } else if (first.label) {
      // Direct label
      productLabel = first.label;
    } else {
      // Look up in predefined categories
      const index = marketplaceCategories.findIndex(
        (el) => el.value === first.value
      );
      productLabel =
        index > -1
          ? marketplaceCategories[index].label
          : first.value || 'קטגוריה אחרת';
    }

    // Return label with count if multiple categories
    return category.length > 1
      ? `${productLabel} + ${category.length - 1}`
      : productLabel;
  };

  const getAllLabelsOfProduct = () => {
    const { category } = product;
    const mapped = category.map((cat) => {
      const index = marketplaceCategories.findIndex(
        (el) => el.value === cat.value
      );

      if (index > -1) {
        return marketplaceCategories[index].label;
      }
      if (cat.inputValue) {
        return cat.inputValue;
      }
      return cat.value;
    });
    const joined = mapped.join(',');
    return joined;
  };
  const isMyProduct = currentUser.user_doc_id === product.seller_id;
  const purchaseDisabled = () =>
    !currentUser ||
    (currentUser &&
      Number(product.product_price) > Number(currentUser.diamonds));

  const getBorderStatus = () => {
    if (isMyProduct && marketplaceView === 'myProducts') {
      const { published } = product;
      if (published) {
        return `1px solid ${theme.palette.success.light}`;
      }
      return `1px solid ${theme.palette.warning.light}`;
    }
    return 'none';
  };

  const handleRetrieveKnowledgeTestDetails = async (knowledgeTestId) => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const pagesCollection = collection(
      db,
      `orgs/${org_ref}/knowledgeTests/${knowledgeTestId}/pages`
    );
    const q = query(pagesCollection, orderBy('order', 'asc'));
    const pagesSnapshot = await getDocs(q);
    const pages = pagesSnapshot.docs.map((pageDoc) => ({
      id: pageDoc.id,
      ...pageDoc.data()
    }));
    setKnowledgeTestPages({ ...knowledgeTestPages, pages });
  };

  useEffect(() => {
    if (
      currentUser &&
      selectedOrg &&
      product &&
      product.category &&
      product.category.length > 0
    ) {
      const index = product.category.findIndex(
        (el) => el.value === 'knowledgeTests'
      );
      if (index > -1 && !knowledgeTestPages) {
        handleRetrieveKnowledgeTestDetails(
          product.knowledge_test_for_sale.knowledge_test_id
        );
      }
    }
  }, [product, selectedOrg, currentUser, knowledgeTestPages]);

  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Card
        elevation={3}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: 6
          },
          borderRadius: 2,
          overflow: 'visible'
        }}
      >
        {/* Category Chips */}
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 2,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 0.5,
            maxWidth: 'calc(100% - 80px)'
          }}
        >
          {product.category?.map((cat, index) => (
            <Chip
              // sx={{
              //   position: 'absolute',
              //   right: 10,
              //   top: 140,
              //   minWidth: 100,
              //   '& .MuiChip-label': {
              //     textOverflow: !isLabelExpanded && 'ellipsis',
              //     width: '100%',
              //     overflow: 'hidden',
              //     whiteSpace: !isLabelExpanded ? 'nowrap' : 'normal',
              //     wordWrap: 'break-word',
              //     textAlign: 'center'
              //   }
              // }}
              // sx={{
              //   position: 'absolute',
              //   right: 10,
              //   top: 140,
              //   minWidth: 100,
              //   '& .MuiChip-label': {
              //     textOverflow: !isLabelExpanded && 'ellipsis',
              //     width: '100%',
              //     overflow: 'hidden',
              //     whiteSpace: !isLabelExpanded ? 'nowrap' : 'normal',
              //     wordWrap: 'break-word',
              //     textAlign: 'center'
              //   }
              // }}
              // sx={{
              //   position: 'absolute',
              //   right: 10,
              //   top: 140,
              //   minWidth: 100,
              //   '& .MuiChip-label': {
              //     textOverflow: !isLabelExpanded && 'ellipsis',
              //     width: '100%',
              //     overflow: 'hidden',
              //     whiteSpace: !isLabelExpanded ? 'nowrap' : 'normal',
              //     wordWrap: 'break-word',
              //     textAlign: 'center'
              //   }
              // }}
              size="small"
              color="warning"
              sx={{
                backgroundColor: 'rgba(255, 167, 38, 0.9)',
                backdropFilter: 'blur(4px)',
                '&:hover': { backgroundColor: 'warning.main' }
              }}
              clickable
              onClick={() => setIsLabelExpanded(!isLabelExpanded)}
              variant="filled"
              // color="warning"
              label={
                isLabelExpanded
                  ? getAllLabelsOfProduct()
                  : getLabelOfMarketplaceProduct()
              }
            />
          ))}
        </Box>

        {/* Bookmark Button */}
        <IconButton
          onClick={() => handleBookmarkProduct(product.product_id)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(4px)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' }
          }}
        >
          {product.bookmarked ? (
            <BookmarkOutlined color="error" />
          ) : (
            <BookmarkBorderOutlined color="action" />
          )}
        </IconButton>

        {/* Product Image */}
        <CardMedia
          component="div"
          sx={{
            height: 220,
            backgroundSize: 'contain',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.02)'
            }
          }}
          image={product.preview || cardPic}
        />

        <CardContent sx={{ flexGrow: 1, pt: 3 }}>
          {/* Title */}
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: 600,
              mb: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: 1.2
            }}
          >
            {product.product_name}
          </Typography>

          {/* Description */}
          <Typography
            color="text.secondary"
            sx={{
              mb: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical'
            }}
          >
            {product.description}
          </Typography>

          {/* Stats */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack alignItems="center">
              <Typography
                variant="h5"
                color="primary.main"
                sx={{ fontWeight: 700 }}
              >
                {product.product_price}
              </Typography>
              <Typography variant="caption" color="primary.main">
                יהלומים
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography
                variant="h5"
                color="text.primary"
                sx={{ fontWeight: 700 }}
              >
                {product.num_of_purchases || 0}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                רכישות
              </Typography>
            </Stack>
          </Stack>

          {/* Knowledge Test Info */}
          {knowledgeTestPages && (
            <>
              <Button
                startIcon={<InfoOutlined />}
                onClick={() => setShowDetails(!showDetails)}
                size="small"
                sx={{ mb: 1 }}
              >
                פרטי מבחן
              </Button>
              <Collapse in={showDetails}>
                <Box
                  sx={{
                    backgroundColor: 'primary.lighter',
                    borderRadius: 1,
                    p: 1,
                    mb: 2
                  }}
                >
                  {`${
                    knowledgeTestPages.pages?.length || 0
                  } עמודים ו ${knowledgeTestPages.pages?.reduce(
                    (acc, curr) => acc + curr.elements.length,
                    0
                  )} שאלות`}
                  <Divider sx={{ my: 0.5 }} />
                  {knowledgeTestPages.pages?.slice(0, 3).map((page) =>
                    page.elements.slice(0, 3).map((element) => (
                      <Typography key={element.name} variant="body2">
                        {`* ${element.title}`}
                      </Typography>
                    ))
                  )}
                </Box>
              </Collapse>
            </>
          )}
        </CardContent>

        {/* Action Button */}
        <CardActions sx={{ p: 2, pt: 0 }}>
          {isMyProduct ? (
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => handleOpenAddProduct(product)}
              startIcon={<EditOutlined />}
              sx={{
                borderRadius: 2,
                py: 1
              }}
            >
              עריכה
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={Boolean(purchaseDisabled())}
              onClick={() =>
                handleOpenVerifyPurchaseRequest(product, knowledgeTestPages)
              }
              startIcon={<ShoppingCartOutlined />}
              sx={{
                borderRadius: 2,
                py: 1,
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4
                }
              }}
            >
              רכישה
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ProductCard;
