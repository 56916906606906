import {
  ExpandLessOutlined,
  ExpandMoreOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined
} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Grid,
  Grow,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isArray, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import available_funds from 'src/common/availableFunds';
import kosherOptions from 'src/common/kosherOptions';
import orgsTags from 'src/common/orgsTags';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';

const AdvancedSearch = ({
  handleToggleTag,
  handleClearAllQueryParams,
  handleDeleteQueryPart,
  handleToggleCollapsed,
  collapsed,
  performSearch
}) => {
  AdvancedSearch.propTypes = {
    handleToggleTag: PropTypes.func,
    handleClearAllQueryParams: PropTypes.func,
    handleDeleteQueryPart: PropTypes.func,
    handleToggleCollapsed: PropTypes.func,
    performSearch: PropTypes.func,
    collapsed: PropTypes.object
  };
  const { searchQuery } = useSearchData();
  const { t } = useTranslation('translation', { keyPrefix: 'mainPage' });
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const createChipLabelByKey = (key) => {
    // console.log(key);
    if (isArray(searchQuery.query[key])) {
      return `${t(key)}: ${t('choosen')} ${searchQuery.query[key].length}`;
    }
    return `${t(key)}: ${t(searchQuery.query[key])}`;
  };

  return (
    <Grow direction="left" timeout="auto" in>
      <Card
        sx={{
          px: 2
        }}
      >
        <CardHeader
          sx={{ pt: 1, mb: 1 }}
          title={<Typography variant="inherit">{t('advanced')}</Typography>}
        />
        {searchQuery.query && Object.keys(searchQuery.query).length > 0 ? (
          <Grow in>
            <Stack>
              <Stack sx={{ flexWrap: 'wrap', mb: 1 }} direction="row" gap={1}>
                {Object.keys(searchQuery.query).map((key) => (
                  <Chip
                    onDelete={() => handleDeleteQueryPart(key)}
                    key={`${key}-${uniqueId('filter-chips')}`}
                    variant="contained"
                    label={createChipLabelByKey(key)}
                    sx={{ width: 'fit-content', fontSize: '0.9rem' }}
                  />
                ))}
              </Stack>
              <Stack
                direciton="row"
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  pb: 1
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ width: 'fit-content' }}
                  onClick={() => handleClearAllQueryParams()}
                >
                  {t('clearAll')}
                </Button>
              </Stack>
              <Divider />
            </Stack>
          </Grow>
        ) : null}
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card
                elevation={4}
                sx={{
                  '&:hover': {
                    boxShadow: theme.shadows[6]
                  },
                  cursor: 'pointer'
                }}
              >
                <CardHeader
                  onClick={() => handleToggleCollapsed('departments')}
                  title={<Typography variant="inherit">{t('tags')}</Typography>}
                  action={
                    !collapsed.departments ? (
                      <ExpandMoreOutlined />
                    ) : (
                      <ExpandLessOutlined />
                    )
                  }
                />
                <Collapse in={collapsed.departments}>
                  {orgsTags.map((tag, i) => (
                    <Fragment key={`${tag.name}-${uniqueId('tags-fragment')}`}>
                      {/* <ListItem disableGutters disablePadding> */}
                      <ListItemButton
                        role="button"
                        onClick={() => handleToggleTag(tag.name, 'tags')}
                        dense
                        key={`${tag.name}-${uniqueId('tags')}`}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={
                              searchQuery.query &&
                              searchQuery.query.tags &&
                              searchQuery.query.tags.indexOf(tag.name) !== -1
                            }
                            tabIndex={i}
                            disableRipple
                            value={tag.name}
                            // inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={tag.label} />
                      </ListItemButton>
                      {/* </ListItem> */}
                      {i !== orgsTags.length - 1 && <Divider />}
                    </Fragment>
                  ))}
                </Collapse>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                elevation={4}
                sx={{
                  '&:hover': {
                    boxShadow: theme.shadows[6]
                  },
                  cursor: 'pointer'
                }}
              >
                <CardHeader
                  onClick={() => handleToggleCollapsed('available_funds')}
                  title={
                    <Typography variant="inherit">
                      {t('available_funds')}
                    </Typography>
                  }
                  action={
                    !collapsed.available_funds ? (
                      <ExpandMoreOutlined />
                    ) : (
                      <ExpandLessOutlined />
                    )
                  }
                />
                <Collapse in={collapsed.available_funds}>
                  {available_funds.map((fund, i) => (
                    <Fragment
                      key={`${fund.name}-${uniqueId(
                        'available_funds-fragment'
                      )}`}
                    >
                      <ListItemButton
                        key={`${fund.name}-${uniqueId('available_funds')}`}
                        role="button"
                        onClick={() => {
                          handleToggleTag(fund.name, 'available_funds');
                          // console.log(searchQuery);
                        }}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={
                              searchQuery.query &&
                              searchQuery.query.available_funds &&
                              searchQuery.query.available_funds.indexOf(
                                fund.name
                              ) !== -1
                            }
                            tabIndex={i}
                            disableRipple
                            value={fund.name}
                            // inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={fund.label} />
                      </ListItemButton>
                      {i !== available_funds.length - 1 && <Divider />}
                    </Fragment>
                  ))}
                </Collapse>
              </Card>
            </Grid>
            <Grid item xs={12} sm={10}>
              {/* <Card> */}
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="caption">{t('kitchen')}</Typography>
                  }
                  slotProps={{
                    primary: {
                      justifyContent: 'flex-end'
                    }
                  }}
                />
                <ButtonGroup
                  fullWidth
                  sx={{ flex: 1, justifyContent: 'center' }}
                >
                  {[
                    { value: 'cooking' },
                    { value: 'catering' },
                    { value: 'notImportant' }
                  ].map((type) => (
                    <Button
                      onClick={() =>
                        handleToggleTag(type.value, 'kitchen', false)
                      }
                      fullWidth
                      variant={
                        searchQuery.query &&
                        searchQuery.query.kitchen &&
                        searchQuery.query.kitchen === type.value
                          ? 'contained'
                          : 'outlined'
                      }
                      key={`${type.value}-${uniqueId('kitchen')}`}
                    >
                      {t(type.value)}
                    </Button>
                  ))}
                </ButtonGroup>
              </ListItem>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} sm={10}>
              {/* <Card> */}
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="caption">{t('cctv')}</Typography>
                  }
                  slotProps={{
                    primary: {
                      justifyContent: 'flex-end'
                    }
                  }}
                />
                <ButtonGroup
                  fullWidth
                  sx={{ flex: 1, justifyContent: 'center' }}
                >
                  {[
                    { value: 'weHave' },
                    { value: 'weDont' },
                    { value: 'notImportant' }
                  ].map((type) => (
                    <Button
                      onClick={() => handleToggleTag(type.value, 'cctv', false)}
                      fullWidth
                      variant={
                        searchQuery.query &&
                        searchQuery.query.cctv &&
                        searchQuery.query.cctv === type.value
                          ? 'contained'
                          : 'outlined'
                      }
                      key={`${type.value}-${uniqueId('cctv')}`}
                    >
                      {t(type.value)}
                    </Button>
                  ))}
                </ButtonGroup>
              </ListItem>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} sm={10}>
              {/* <Card> */}
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="caption">
                      {t('fallPrevention')}
                    </Typography>
                  }
                  slotProps={{
                    primary: {
                      justifyContent: 'flex-end'
                    }
                  }}
                />
                <ButtonGroup
                  fullWidth
                  sx={{ flex: 1, justifyContent: 'center' }}
                >
                  {[
                    { value: 'weHave' },
                    { value: 'weDont' },
                    { value: 'notImportant' }
                  ].map((type) => (
                    <Button
                      onClick={() =>
                        handleToggleTag(type.value, 'fall_prevention', false)
                      }
                      fullWidth
                      variant={
                        searchQuery.query &&
                        searchQuery.query.fall_prevention &&
                        searchQuery.query.fall_prevention === type.value
                          ? 'contained'
                          : 'outlined'
                      }
                      key={`${type.value}-${uniqueId('fall_prevention')}`}
                    >
                      {t(type.value)}
                    </Button>
                  ))}
                </ButtonGroup>
              </ListItem>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} sm={10}>
              {/* <Card> */}
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <ListItemText
                  primary={<Typography variant="caption">{t('iv')}</Typography>}
                  slotProps={{
                    primary: {
                      justifyContent: 'flex-end'
                    }
                  }}
                />
                <ButtonGroup
                  fullWidth
                  sx={{ flex: 1, justifyContent: 'center' }}
                >
                  {[
                    { value: 'weHave' },
                    { value: 'weDont' },
                    { value: 'notImportant' }
                  ].map((type) => (
                    <Button
                      onClick={() => handleToggleTag(type.value, 'iv', false)}
                      fullWidth
                      variant={
                        searchQuery.query &&
                        searchQuery.query.iv &&
                        searchQuery.query.iv === type.value
                          ? 'contained'
                          : 'outlined'
                      }
                      key={`${type.value}-${uniqueId('iv')}`}
                    >
                      {t(type.value)}
                    </Button>
                  ))}
                </ButtonGroup>
              </ListItem>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                elevation={4}
                sx={{
                  '&:hover': {
                    boxShadow: theme.shadows[6]
                  },
                  cursor: 'pointer'
                }}
              >
                <CardHeader
                  onClick={() => handleToggleCollapsed('kosher')}
                  title={
                    <Typography variant="inherit">{t('kosher')}</Typography>
                  }
                  action={
                    !collapsed.kosher ? (
                      <ExpandMoreOutlined />
                    ) : (
                      <ExpandLessOutlined />
                    )
                  }
                />
                <Collapse in={collapsed.kosher}>
                  {kosherOptions.map((kosher, i) => (
                    <Fragment
                      key={`${kosher.name}-${uniqueId('kosher-fragment')}`}
                    >
                      <ListItemButton
                        key={`${kosher.name}-${uniqueId('kosher')}`}
                        role="button"
                        onClick={() =>
                          handleToggleTag(kosher.name, 'kosher', false)
                        }
                        dense
                      >
                        <ListItemIcon>
                          {searchQuery.query &&
                          searchQuery.query.kosher === kosher.name ? (
                            <RadioButtonCheckedOutlined
                              color="primary"
                              tabIndex={i}
                              disableRipple
                              value={kosher.name}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          ) : (
                            <RadioButtonUncheckedOutlined color="action" />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={kosher.label} />
                      </ListItemButton>
                      {i !== kosherOptions.length - 1 && <Divider />}
                    </Fragment>
                  ))}
                </Collapse>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        {!matchDownSm && (
          <>
            <Divider />
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button onClick={() => performSearch()} variant="outlined">
                {t('search')}
              </Button>
              <Button onClick={handleClearAllQueryParams} variant="outlined">
                {t('clearAll')}
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </Grow>
  );
};

export default AdvancedSearch;
