import { doc, getDoc } from '@firebase/firestore';
import {
  ArrowBackOutlined,
  PlayCircleOutline,
  RedeemOutlined,
  SearchOutlined,
  SupportOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { db } from 'src/_firebase/firebase';
import circlesPattern from 'src/assets/knowledgeCommunity/circlesPattern.svg';
import diamond from 'src/assets/lottie/diamondLoadingLottie.json';
import help from 'src/assets/lottie/help.json';
import jobProposal from 'src/assets/lottie/job-proposal.json';
import mohLogo from 'src/assets/lottie/mohLogo.png';
import selectOrg from 'src/assets/lottie/select-org.json';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import AuthorizedPersonalContactWithMgdalorDialog from '../shared/AuthorizedPersonalContactWithMgdalorDialog/AuthorizedPersonalContactWithMgdalorDialog';
import './knowledgeCommunity.css';

const cards = [
  {
    id: 'userGuide',
    lottie: help,
    bgColor: lighten('#fac719', 0.3),
    ctaIcon: <PlayCircleOutline />,
    btnText: 'צפיה במדריך',
    href: '/userGuide',
    hrefProps: {
      target: '_blank',
      rel: 'noreferrer noopener'
    },
    title: 'המדריך למשתמש',
    description:
      'יש לך שאלות ותהיות לגבי השימוש במערכת? הכנו לך סדרת סרטונים אשר תסביר ותעזור',
    permission: ['org', 'personal']
  },
  {
    id: 'jobs',
    lottie: jobProposal,
    bgColor: '#53be95',
    ctaIcon: <SearchOutlined />,
    btnText: 'חיפוש',
    title: 'משרות',
    href: `/${routesConst.app}/${routesConst.user}/${routesConst.jobs}`,
    hrefProps: {},
    description:
      'כל המשרות של כל הארגונים במקום אחד, ובלחיצת כפתור אחד, יקבלו את הקורות חיים שלך',
    permission: ['personal']
  },
  {
    id: 'redeemDiamonds',
    lottie: diamond,
    bgColor: lighten('#f69db3', 0.4),
    ctaIcon: <RedeemOutlined />,
    btnText: 'בחר שובר מתנה',
    title: 'המרת יהלומים',
    href: `/${routesConst.app}/${routesConst.redeemDiamonds}`,
    hrefProps: {},
    description:
      'היהלומים שצברת דרך המערכת שלנו, הם לא רק מספר אלא כסף אמיתי שאיתו תוכל לרכוש שוברי קנייה',
    className: 'redeemDiamonds',
    comingSoon: true,
    permission: ['personal']
  },
  {
    id: 'community',
    lottie: selectOrg,
    bgColor: lighten('#0a4b74', 0.3),
    href: `/${routesConst.app}/${routesConst.community}`,
    // comingSoon: process.env.REACT_APP_ENV !== 'dev',
    title: 'קהילה',
    description:
      'המקום של קהילת מגדלור, שבו אפשר למצוא מקורות חדשים של ידע ועוד המון..',
    permission: ['org', 'personal']
  },
  {
    id: 'moh-assessment',
    img: mohLogo,
    mediaType: 'gif',
    bgColor: lighten(theme.palette.grey[200], 0.3),
    href: `/${routesConst.app}/${routesConst.mohAssessment}`,
    // comingSoon: process.env.REACT_APP_ENV !== 'dev',
    title: 'מכרז משרד הבריאות',
    description: 'המקום לנהל את כל מה שקשור למכרז משרד הבריאות',
    permission: ['org', 'personal']
  }
];
const KnowledgeCommunity = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'knowledgeCommunity'
  });
  const { currentUser } = useCurrentUserContext();
  const [knowledgeCards, setKnowledgeCards] = useState(cards);
  const [
    isAuthorizedPersonalContactWithMgdalor,
    setAuthorizedPersonalContactWithMgdalor
  ] = useState();
  const handleOpenAuthorizedPersonalContactWithMgdalorDialog = () => {
    setAuthorizedPersonalContactWithMgdalor(true);
  };
  const handleCloseAuthorizedPersonalContactWithMgdalorDialog = () => {
    setAuthorizedPersonalContactWithMgdalor(false);
  };

  const getRedeemDiamondsCardInfo = () => {
    const index = knowledgeCards.findIndex((el) => el.id === 'redeemDiamonds');
    return index;
  };

  const getRedeemDiamondsStatus = async () => {
    const pagesVisibilityRef = doc(db, 'visibilePages/app$$redeem');
    getDoc(pagesVisibilityRef).then((val) => {
      const temp = [...knowledgeCards];
      if (val.exists()) {
        const index = getRedeemDiamondsCardInfo();
        const { published } = val.data();
        temp[index].comingSoon = !published;
      } else {
        const index = getRedeemDiamondsCardInfo();
        temp[index].comingSoon = true;
      }
      setKnowledgeCards(temp);
    });
  };

  useEffect(() => {
    getRedeemDiamondsStatus();
  }, []);
  return (
    <>
      <Helmet>
        <title>{t('knowledgeCommunity')}</title>
      </Helmet>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          key="upper-section"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            m: 3,
            position: 'relative',
            backgroundColor: '#5557d4'
          }}
          component={Card}
        >
          <Box
            sx={{ position: 'absolute', right: 0, top: 0, zIndex: 0 }}
            component="img"
            src={circlesPattern}
          />
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            {currentUser ? (
              <Typography
                variant="h1"
                sx={{
                  fontSize: '2rem',
                  //   textAlign: 'center',
                  color: theme.palette.primary.contrastText
                }}
                component="span"
              >
                שלום
                <Typography
                  component="span"
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: '2.2rem',
                    fontWeight: 800
                  }}
                >
                  {` ${currentUser.first_name || currentUser.org_name}`}
                </Typography>
                , איך אנחנו יכולים לעזור לך?
              </Typography>
            ) : (
              <Skeleton variant="text" width={50} />
            )}
            <Typography
              variant="h3"
              sx={{ fontSize: '1.25rem', color: '#efefef' }}
            >
              לא בטוח שמצאת את מה שרצית בכרטיסיות למטה?
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                endIcon={<SupportOutlined />}
                color="warning"
                variant="contained"
                sx={{ maxWidth: 140 }}
                fullWidth
                onClick={() =>
                  handleOpenAuthorizedPersonalContactWithMgdalorDialog()
                }
              >
                שלח לנו
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ p: 3, pt: 1.5 }} item container xs={12} spacing={3}>
          {currentUser &&
            knowledgeCards
              .filter((opt) => opt.permission.includes(currentUser.type))
              .map((option) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={`${option.id}-${uniqueId('option')}`}
                >
                  <Card
                    elevation={4}
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      '&:hover': {
                        boxShadow: theme.shadows[8]
                      }
                    }}
                  >
                    {option.comingSoon && (
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          userSelect: 'none',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          backgroundColor: theme.palette.action.disabled,
                          zIndex: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'not-allowed'
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.primary.contrastText,
                            fontSize: '2.5rem',
                            transform: 'rotate(-25deg)',
                            fontWeight: 600,
                            letterSpacing: 4
                          }}
                        >
                          בקרוב..
                        </Typography>
                      </Box>
                    )}
                    {option.lottie ? (
                      <Lottie
                        animationData={option.lottie}
                        style={{
                          width: '100%',
                          height: 160,
                          objectFit: 'fill',
                          transform: 'scale(1.2)',
                          backgroundColor: option.bgColor
                        }}
                        className={`${option.className}-lottie`}
                      />
                    ) : (
                      <CardMedia
                        component="img"
                        sx={{
                          width: '100%',
                          height: 160,
                          objectFit: 'fill',
                          opacity: '0.7'
                        }}
                        src={option.img}
                        alt={option.title}
                      />
                    )}
                    <CardHeader
                      title={option.title}
                      subheader={option.description}
                    />
                    <CardActions
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        {...option.hrefProps}
                        href={option.href}
                        endIcon={
                          option.ctaIcon ? (
                            option.ctaIcon
                          ) : (
                            <ArrowBackOutlined />
                          )
                        }
                        variant="text"
                      >
                        {option.btnText || 'למד עוד'}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Grid>
      <AuthorizedPersonalContactWithMgdalorDialog
        key="authorizedPersonalContactWithMgdalorDialog-knowledge-community"
        onClose={handleCloseAuthorizedPersonalContactWithMgdalorDialog}
        open={isAuthorizedPersonalContactWithMgdalor}
      />
    </>
  );
};
export default KnowledgeCommunity;
