import { Box, Card, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { memo } from 'react';

const OrgCommunityPostsTabs = memo(({ tabValue, handleChangeTabValue }) => (
  <Box position="sticky" top={0} zIndex={1}>
    <Tabs
      value={tabValue}
      onChange={(e, view) => handleChangeTabValue(view)}
      aria-label="basic tabs OrgCommunityPostsTabs"
      textColor="primary"
      indicatorColor="primary"
      component={Card}
      elevation={3}
    >
      <Tab value="regular" label="פרסומים כלליים" />
      <Tab value="signature_required" label="פרסומים לחתימה" />
      <Tab value="knowledge_tests" label="מבדקי ידע" />
    </Tabs>
  </Box>
));

OrgCommunityPostsTabs.propTypes = {
  tabValue: PropTypes.string.isRequired,
  handleChangeTabValue: PropTypes.func.isRequired
};

export default OrgCommunityPostsTabs;
