import {
  CloseOutlined,
  LanguageOutlined,
  MenuOutlined,
  WhatsApp
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Grow,
  Hidden,
  Icon,
  IconButton,
  Link as MuiLink,
  Popper,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MgdalorLogo from 'src/assets/mgdalor.png';
import theme from 'src/theme';
import DefaultNavbarDropdown from './navbar/DefaultNavbarDropDown';
import DefaultNavbarMobile from './navbar/DefaultNavbarMobile';
import LanguagePopperMenu from './shared/LanguagePopper/LanguagePopperMenu';

function DefaultNavbar({
  brand = 'Mgdalor',
  navbarRoutes,
  transparent = false,
  light = false,
  action = false,
  sticky = false,
  relative = false,
  center = false
}) {
  const [dropdown, setDropdown] = useState('');
  const [dropdownEl, setDropdownEl] = useState('');
  const [dropdownName, setDropdownName] = useState('');
  const [nestedDropdown, setNestedDropdown] = useState('');
  const [nestedDropdownEl, setNestedDropdownEl] = useState('');
  const [nestedDropdownName, setNestedDropdownName] = useState('');
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < theme.breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }
    window.addEventListener('resize', displayMobileNavbar);
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  const renderNavbarItems = navbarRoutes.map(
    ({ name, icon, href, route, collapse }) => (
      <DefaultNavbarDropdown
        key={name}
        name={name}
        icon={icon}
        href={href}
        route={route}
        collapse={Boolean(collapse)}
        onMouseEnter={({ currentTarget }) => {
          if (collapse) {
            setDropdown(currentTarget);
            setDropdownEl(currentTarget);
            setDropdownName(name);
          }
        }}
        onMouseLeave={() => collapse && setDropdown(null)}
        light={light}
      />
    )
  );

  // Render the routes on the dropdown menu
  const renderRoutes = navbarRoutes.map(
    ({ name, collapse, columns, rowsPerColumn }) => {
      let template;

      // Render the dropdown menu that should be display as columns
      if (collapse && columns && name === dropdownName) {
        const calculateColumns = collapse.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / rowsPerColumn);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }

          resultArray[chunkIndex].push(item);

          return resultArray;
        }, []);

        template = (
          <Grid key={name} container spacing={3} py={1} px={1.5}>
            {calculateColumns.map((cols, key) => {
              const gridKey = `grid-${key}`;
              const dividerKey = `divider-${key}`;

              return (
                <Grid
                  key={gridKey}
                  item
                  xs={12 / columns}
                  sx={{ position: 'relative' }}
                >
                  {cols.map((col, index) => (
                    <Fragment key={col.name}>
                      <Typography
                        display="block"
                        variant="button"
                        fontWeight="600"
                        textTransform="capitalize"
                        sx={{ fontSize: '18px' }}
                        py={1}
                        px={0.5}
                        mt={index !== 0 ? 2 : 0}
                      >
                        {col.name}
                      </Typography>
                      {col.collapse.map((item) => (
                        <Typography
                          key={item.name}
                          component={item.route ? Link : MuiLink}
                          to={item.route ? item.route : ''}
                          href={
                            item.href ? item.href : (e) => e.preventDefault()
                          }
                          target={item.href ? '_blank' : ''}
                          rel={item.href ? 'noreferrer' : 'noreferrer'}
                          minWidth="11.25rem"
                          display="block"
                          variant="button"
                          color={theme.palette.grey[900]}
                          textTransform="capitalize"
                          fontWeight="regular"
                          fontSize="18px"
                          py={0.625}
                          pl={1}
                          pr={2}
                          sx={{
                            borderRadius: 8,
                            cursor: 'pointer',
                            transition: 'all 300ms linear',
                            textIndent: '12px',
                            '&:hover': {
                              backgroundColor: theme.palette.grey[200],
                              color: theme.palette.primary.main
                            }
                          }}
                          onClick={() => setDropdown(null)}
                        >
                          {item.name}
                        </Typography>
                      ))}
                    </Fragment>
                  ))}
                  {key !== 0 && (
                    <Divider
                      key={dividerKey}
                      orientation="vertical"
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '-2px',
                        transform: 'translateY(-45%)',
                        height: '90%',
                        mx: 1
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        );

        // Render the dropdown menu that should be display as list items
      } else if (collapse && name === dropdownName) {
        template = collapse.map((item) => {
          const linkComponent = {
            component: MuiLink,
            href: item.href,
            target: '_blank',
            rel: 'noreferrer'
          };

          const routeComponent = {
            component: Link,
            to: item.route
          };

          return (
            <Typography
              key={item.name}
              {...(item.route ? routeComponent : linkComponent)}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              variant="button"
              textTransform="capitalize"
              minWidth={item.description ? '14rem' : '12rem'}
              // color={theme.palette.primary.light}
              color={theme.palette.grey[900]}
              fontWeight={item.description ? 'bold' : 'regular'}
              py={item.description ? 1 : 0.625}
              px={2}
              sx={{
                borderRadius: 8,
                cursor: 'pointer',
                transition: 'all 300ms linear',

                '&:hover': {
                  backgroundColor: theme.palette.grey[200],
                  color: theme.palette.primary.main,

                  '& *': {
                    color: theme.palette.primary.main
                  }
                }
              }}
              onMouseEnter={({ currentTarget }) => {
                if (item.dropdown) {
                  setNestedDropdown(currentTarget);
                  setNestedDropdownEl(currentTarget);
                  setNestedDropdownName(item.name);
                }
              }}
              onMouseLeave={() => {
                if (item.dropdown) {
                  setNestedDropdown(null);
                }
              }}
              onClick={() => setDropdown(null)}
            >
              {item.description ? (
                <Box>
                  {item.name}
                  <Typography
                    display="block"
                    variant="button"
                    color={theme.palette.primary.light}
                    fontWeight="regular"
                    sx={{ transition: 'all 300ms linear' }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              ) : (
                item.name
              )}
            </Typography>
          );
        });
      }

      return template;
    }
  );

  // Routes dropdown menu
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="auto"
      transition
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef
          }
        }
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null);
          setDropdownName('');
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: 'left top',
            background: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: theme.shadows[6]
          }}
        >
          {/* <Box borderRadius="lg"> */}
          <Box shadow="lg" sx={{ borderRadius: 2 }} p={2} mt={2}>
            {renderRoutes}
            {/* </Box> */}
          </Box>
        </Grow>
      )}
    </Popper>
  );

  // Render routes that are nested inside the dropdown menu routes
  const renderNestedRoutes = navbarRoutes.map(({ collapse, columns }) => {
    if (collapse && !columns) {
      collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
        let template;

        if (parentName === nestedDropdownName) {
          template =
            nestedCollapse &&
            nestedCollapse.map((item) => {
              const linkComponent = {
                component: MuiLink,
                href: item.href,
                target: '_blank',
                rel: 'noreferrer'
              };

              const routeComponent = {
                component: Link,
                to: item.route
              };

              return (
                <Typography
                  key={item.name}
                  {...(item.route ? routeComponent : linkComponent)}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  variant="button"
                  textTransform="capitalize"
                  minWidth={item.description ? '14rem' : '12rem'}
                  color={item.description ? 'dark' : 'text'}
                  fontWeight={item.description ? 'bold' : 'regular'}
                  py={item.description ? 1 : 0.625}
                  px={2}
                  sx={{
                    borderRadius: 8,
                    cursor: 'pointer',
                    transition: 'all 300ms linear',
                    '&:hover': {
                      backgroundColor: theme.palette.grey[200],
                      color: theme.palette.grey[900],
                      '& *': {
                        color: theme.palette.grey[900]
                      }
                    }
                  }}
                >
                  {item.description ? (
                    <Box>
                      {item.name}
                      <Typography
                        display="block"
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        sx={{ transition: 'all 300ms linear' }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  ) : (
                    item.name
                  )}
                  {item.collapse && (
                    <Icon
                      fontSize="small"
                      sx={{
                        fontWeight: 'normal',
                        verticalAlign: 'middle',
                        mr: -0.5
                      }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              );
            });
        }

        return template;
      });
    }
    return null;
  });

  // Dropdown menu for the nested dropdowns
  const nestedDropdownMenu = (
    <Popper
      anchorEl={nestedDropdown}
      popperRef={null}
      open={Boolean(nestedDropdown)}
      placement="auto"
      transition
      style={{ zIndex: 10 }}
      onMouseEnter={() => {
        setNestedDropdown(nestedDropdownEl);
      }}
      onMouseLeave={() => {
        setNestedDropdown(null);
        setNestedDropdownName('');
        setDropdown(null);
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: 'left top',
            background: 'white'
          }}
        >
          <Box ml={2.5} mt={-2.5} borderRadius="lg">
            <Box shadow="lg" sx={{ borderRadius: 2 }} py={1.5} px={1} mt={2}>
              {renderNestedRoutes}
            </Box>
          </Box>
        </Grow>
      )}
    </Popper>
  );

  const handleLanguageMenuOpen = (e) => {
    setLanguageMenuAnchor(languageMenuAnchor ? null : e.currentTarget);
  };
  return (
    <Container
      sx={
        sticky
          ? { position: 'sticky', top: 0, zIndex: 10, borderRadius: 8 }
          : null
      }
    >
      <Box
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? '100%' : 'calc(100% - 48px)'}
        color={light ? 'white' : 'dark'}
        position={relative ? 'relative' : 'absolute'}
        left={0}
        zIndex={12}
        sx={({ palette: { transparent: transparentColor } }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : alpha(theme.palette.background.paper, 0.8),
          backdropFilter: transparent ? 'none' : 'saturate(200%) blur(30px)',
          borderRadius: 2,
          boxShadow: theme.shadows[6]
        })}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            component={Link}
            to="/"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
            onClick={() => {
              if (mobileNavbar) setMobileNavbar(false);
            }}
          >
            <Avatar src={MgdalorLogo}>מגדלור</Avatar>
          </Box>
          <Box
            color="inherit"
            display={{ xs: 'none', lg: 'flex' }}
            ml="auto"
            mr={center ? 'auto' : 0}
          >
            {renderNavbarItems}
          </Box>
          <Box
            ml={{ xs: 'auto', lg: 0 }}
            sx={{ display: 'flex', flexFlow: 'row no-wrap' }}
          >
            {action &&
              (action.type === 'internal' ? (
                <Button
                  component={Link}
                  to={action.route}
                  variant="outlined"
                  color="success"
                  size="small"
                >
                  {action.label}
                </Button>
              ) : (
                <Button
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  {action.label}
                </Button>
              ))}
            <IconButton
              LinkComponent="a"
              href={`https://api.whatsapp.com/send/?phone=972557111106&text=${encodeURIComponent(
                'שלום, אשמח לקבל עזרה והמלצות אודות חיפוש בית אבות ודיור מוגן מתאים ומקצועי'
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <WhatsApp style={{ color: 'rgb(36, 211, 101)' }} />
            </IconButton>
            <Hidden smDown>
              <IconButton
                sx={{
                  transition: 'all 100ms linear',
                  '&:hover': {
                    transform: 'scale(1.2)'
                  }
                }}
                onClick={(e) => handleLanguageMenuOpen(e)}
              >
                <LanguageOutlined size="small" color="primary" />
              </IconButton>
            </Hidden>
          </Box>
          <Box
            display={{ xs: 'inline-block', lg: 'none' }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent ? 'white' : 'inherit'}
            sx={{ cursor: 'pointer' }}
            onClick={openMobileNavbar}
          >
            <IconButton sx={{ p: 0 }}>
              {mobileNavbar ? <CloseOutlined /> : <MenuOutlined />}
            </IconButton>
          </Box>
        </Box>
        <Box
          bgcolor={transparent ? 'white' : 'transparent'}
          shadow={transparent ? 'lg' : 'none'}
          sx={{
            borderRadius: 2
          }}
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <DefaultNavbarMobile
              navbarRoutes={navbarRoutes}
              open={mobileNavbar}
              setOpen={setMobileNavbar}
            />
          )}
        </Box>
      </Box>
      {dropdownMenu}
      {nestedDropdownMenu}
      <LanguagePopperMenu
        languageMenuAnchor={languageMenuAnchor}
        setLanguageMenuAnchor={setLanguageMenuAnchor}
      />
    </Container>
  );
}

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  navbarRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white'
      ]),
      label: PropTypes.string.isRequired
    })
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool
};

export default DefaultNavbar;
