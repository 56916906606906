import { CloseOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { any, array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

const EditPostDurationDialog = ({
  open,
  onClose,
  handleSaveNewDurationChange,
  postToEditDurationFor,
  setEdittingDuration
}) => {
  EditPostDurationDialog.propTypes = {
    open: any,
    onClose: func,
    handleSaveNewDurationChange: func,
    setEdittingDuration: func,
    postToEditDurationFor: array
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'knowledgeCommunity'
  });

  const handleChangePostDuration = (newDuration) => {
    // postToEditDurationFor.duration = newDuration;
    setEdittingDuration({
      ...postToEditDurationFor,
      duration: Math.abs(newDuration)
    });
  };
  return (
    <Dialog maxWidth="sm" fullWidth onClose={onClose} open={Boolean(open)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Stack gap={0.5} fontSize="inherit">
          <Typography fontSize="inherit" variant="inherit">
            עריכת שעות חת&quot;ש
          </Typography>
          {/* <Typography variant="body1" color={theme.palette.error.dark}>
            בזמן בניית שאלון ומצרפים אותו לפרסום, אוטומטית הפרסום יסווג כמבדק
            ידע ויתווסף לכרטיסיה של מבדקי ידע
          </Typography> */}
        </Stack>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          value={postToEditDurationFor.duration || 0}
          placeholder="מה משך עדכון זה? (בשעות)"
          helperText="כמה שעות ייספרו לעובד שעבר על עדכון זה - במסגרת סעיף עדכון ידע מקצועי"
          //   sx={{ mt: 2 }}
          fullWidth
          name="duration"
          type="number"
          onChange={(e) => {
            handleChangePostDuration(parseFloat(e.target.value));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() =>
            handleSaveNewDurationChange(postToEditDurationFor, onClose)
          }
        >
          שמירת שינוי
        </Button>
        <Button variant="outlined" onClick={onClose}>
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPostDurationDialog;
