import {
  collection,
  getDocs,
  orderBy,
  query,
  where
} from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const getKnowledgeTests = async (
  org_ref,
  current_user_doc_id,
  user_labels = []
) => {
  try {
    const knowledgeTestsCollection = collection(
      db,
      `orgs/${org_ref}/knowledgeTests`
    );

    const querySnapshots = [];
    // console.log(user_labels);

    if (org_ref === current_user_doc_id) {
      // Fetch all tests for the current user
      const testsQuery = query(
        knowledgeTestsCollection,
        orderBy('created_at', 'desc')
      );
      querySnapshots.push(await getDocs(testsQuery));
    } else {
      // Fetch tests for allowed employees
      const allowedTestsQuery = query(
        knowledgeTestsCollection,
        where('allowed_employees', 'array-contains', current_user_doc_id)
      );
      querySnapshots.push(await getDocs(allowedTestsQuery));

      // Fetch tests that user published
      const myTestsQuery = query(
        knowledgeTestsCollection,
        where('author_ref', '==', current_user_doc_id)
      );
      querySnapshots.push(await getDocs(myTestsQuery));

      // Fetch tests matching user labels
      if (user_labels.length > 0) {
        const labeledTestsQuery = query(
          knowledgeTestsCollection,
          where('labels', 'array-contains-any', user_labels)
        );
        querySnapshots.push(await getDocs(labeledTestsQuery));
      }
      // retrieve knowledgeTests that contains the current user as a responsible employee
      const responsibleTestsQuery = query(
        knowledgeTestsCollection,
        where('responsible_employees', 'array-contains', current_user_doc_id)
      );
      querySnapshots.push(await getDocs(responsibleTestsQuery));
    }

    // Merge results and remove duplicates
    const knowledgeTests = {};
    querySnapshots.forEach((snapshot) => {
      snapshot.docs.forEach((doc) => {
        if (!knowledgeTests[doc.id]) {
          knowledgeTests[doc.id] = {
            knowledge_test_id: doc.id,
            post_id: doc.id,
            ...doc.data(),
            knowledgeTest: {
              ...doc.data().knowledgeTest,
              pages: [] // Pages will be fetched later
            }
          };
        }
      });
    });

    // Fetch and populate pages for each unique test
    await Promise.all(
      Object.values(knowledgeTests).map(async (test) => {
        const pagesCollection = collection(
          db,
          `orgs/${org_ref}/knowledgeTests/${test.knowledge_test_id}/pages`
        );
        const q = query(pagesCollection, orderBy('order', 'asc'));
        const pagesSnapshot = await getDocs(q);
        test.knowledgeTest.pages = pagesSnapshot.docs.map((pageDoc) => ({
          id: pageDoc.id,
          ...pageDoc.data()
        }));
      })
    );
    const sortedKnowledgeTests = Object.values(knowledgeTests).sort(
      (a, b) => b.created_at.seconds - a.created_at.seconds
    );
    return sortedKnowledgeTests;
  } catch (error) {
    console.error('Error retrieving knowledge tests:', error);
    return [];
  }
};

export default getKnowledgeTests;
