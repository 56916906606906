// IndexedDB utility functions
const initIndexedDB = () =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open('dashboardCache', 1);

    request.onupgradeneeded = (event) => {
      const tempIndexedDb = event.target.result;

      // Create object stores if they don't exist
      if (!tempIndexedDb.objectStoreNames.contains('employees')) {
        tempIndexedDb.createObjectStore('employees', { keyPath: 'cacheKey' });
      }

      if (!tempIndexedDb.objectStoreNames.contains('folders')) {
        tempIndexedDb.createObjectStore('folders', { keyPath: 'cacheKey' });
      }

      if (!tempIndexedDb.objectStoreNames.contains('practices')) {
        tempIndexedDb.createObjectStore('practices', { keyPath: 'cacheKey' });
      }

      // Store for data timestamps
      if (!tempIndexedDb.objectStoreNames.contains('timestamps')) {
        tempIndexedDb.createObjectStore('timestamps', { keyPath: 'key' });
      }

      // Store for access control metadata
      if (!tempIndexedDb.objectStoreNames.contains('accessControl')) {
        tempIndexedDb.createObjectStore('accessControl', { keyPath: 'key' });
      }
    };

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });

// Generate a secure cache key that includes user and org information
const generateCacheKey = (storeName, orgId, userId, additionalParams = {}) => {
  const baseKey = `${storeName}_${orgId}_${userId}`;

  if (additionalParams.labels && Array.isArray(additionalParams.labels)) {
    // Sort and filter labels to ensure consistent order and valid values
    const sanitizedLabels = additionalParams.labels
      .filter((label) => label && typeof label === 'string')
      .sort()
      .join('-');
    return sanitizedLabels ? `${baseKey}_labels_${sanitizedLabels}` : baseKey;
  }

  return baseKey;
};

// Save data to IndexedDB with security metadata
const saveToIndexedDB = async (storeName, cacheKey, data, securityMeta) => {
  try {
    const tempIndexedDb = await initIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = tempIndexedDb.transaction(
        [storeName, 'timestamps', 'accessControl'],
        'readwrite'
      );
      const store = transaction.objectStore(storeName);
      const timestampStore = transaction.objectStore('timestamps');
      const accessControlStore = transaction.objectStore('accessControl');

      // Save the actual data
      const dataRequest = store.put({ cacheKey, data });

      // Update the timestamp
      const now = Date.now();
      timestampStore.put({ key: cacheKey, timestamp: now });

      // Save access control metadata for security checks
      accessControlStore.put({
        key: cacheKey,
        orgId: securityMeta.orgId,
        userId: securityMeta.userId,
        userType: securityMeta.userType,
        accessGranted: now,
        userLabels: securityMeta.userLabels || []
      });

      transaction.oncomplete = () => resolve(true);
      transaction.onerror = () => reject(transaction.error);
    });
  } catch (error) {
    console.error(`Error saving to IndexedDB (${storeName}):`, error);
    return false;
  }
};

// Validate if current user has access to this cached data
const validateAccess = async (cacheKey, currentSecurityMeta) => {
  try {
    const tempIndexedDb = await initIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = tempIndexedDb.transaction('accessControl');
      const store = transaction.objectStore('accessControl');

      const request = store.get(cacheKey);

      request.onsuccess = () => {
        // If no access control record exists, deny access
        if (!request.result) {
          resolve(false);
          return;
        }

        const accessMeta = request.result;

        // Basic security checks:
        // 1. Verify same user ID
        const sameUser = accessMeta.userId === currentSecurityMeta.userId;

        // 2. Verify same organization ID
        const sameOrg = accessMeta.orgId === currentSecurityMeta.orgId;

        // 3. Verify user type hasn't changed (org vs personal)
        const sameUserType =
          accessMeta.userType === currentSecurityMeta.userType;

        // 4. For personal users, verify labels haven't changed
        // (org users have all access regardless of labels)
        let labelsValid = true;
        if (currentSecurityMeta.userType === 'personal') {
          const storedLabels = accessMeta.userLabels || [];
          const currentLabels = currentSecurityMeta.userLabels || [];

          // Compare labels - they must be the same for access to be valid
          // This prevents users from seeing data they shouldn't after role changes
          labelsValid =
            storedLabels.length === currentLabels.length &&
            storedLabels.every((label) => currentLabels.includes(label));
        }

        resolve(sameUser && sameOrg && sameUserType && labelsValid);
      };
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error validating access:', error);
    return false; // Fail closed - deny access on error
  }
};

// Get data from IndexedDB with security validation
const getFromIndexedDB = async (storeName, cacheKey, securityMeta) => {
  try {
    const tempIndexedDb = await initIndexedDB();

    // First, validate access control
    const isAuthorized = await validateAccess(cacheKey, securityMeta);
    if (!isAuthorized) {
      console.warn(`Access validation failed for ${cacheKey}`);
      return null;
    }

    return new Promise((resolve, reject) => {
      const transaction = tempIndexedDb.transaction(storeName);
      const store = transaction.objectStore(storeName);

      const request = store.get(cacheKey);

      request.onsuccess = () => {
        resolve(request.result ? request.result.data : null);
      };
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error(`Error getting from IndexedDB (${storeName}):`, error);
    return null;
  }
};

// Clear all caches for a user when they log out
const clearUserCaches = async (userId) => {
  try {
    const tempIndexedDb = await initIndexedDB();

    // Get all access control records
    const transaction = tempIndexedDb.transaction('accessControl');
    const store = transaction.objectStore('accessControl');
    const allRecords = await new Promise((resolve) => {
      const request = store.getAll();
      request.onsuccess = () => resolve(request.result || []);
      request.onerror = () => resolve([]);
    });

    // Find all cacheKeys belonging to this user
    const userCacheKeys = allRecords
      .filter((record) => record.userId === userId)
      .map((record) => record.key);

    // Delete all these records from all stores
    const stores = [
      'employees',
      'folders',
      'practices',
      'timestamps',
      'accessControl'
    ];
    await Promise.all(
      stores.map(async (storeName) => {
        const storeTransaction = tempIndexedDb.transaction(
          storeName,
          'readwrite'
        );
        const currentStore = storeTransaction.objectStore(storeName);

        return Promise.all(
          userCacheKeys.map(
            (key) =>
              new Promise((resolve) => {
                const request = currentStore.delete(key);
                request.onsuccess = () => resolve();
                request.onerror = () => resolve();
              })
          )
        );
      })
    );

    return true;
  } catch (error) {
    console.error('Error clearing user caches:', error);
    return false;
  }
};

// Check if data in IndexedDB is stale (older than 15 minutes)
const isDataStale = async (cacheKey) => {
  try {
    const tempIndexedDb = await initIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = tempIndexedDb.transaction('timestamps');
      const store = transaction.objectStore('timestamps');

      const request = store.get(cacheKey);

      request.onsuccess = () => {
        if (!request.result) {
          resolve(true); // No timestamp means data is stale
          return;
        }

        const cacheAge = Date.now() - request.result.timestamp;
        const fifteenMinutes = 15 * 60 * 1000;
        resolve(cacheAge > fifteenMinutes);
      };
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error checking data staleness:', error);
    return true; // Default to fetching fresh data on error
  }
};

/**
 * Delete data from IndexedDB for a specific store and cache key
 * @param {string} storeName - Name of the IndexedDB store
 * @param {string} cacheKey - Cache key to delete
 * @returns {Promise<boolean>} - Success status
 */
const deleteFromIndexedDB = async (storeName, cacheKey) => {
  try {
    const tempIndexedDb = await initIndexedDB();

    return new Promise((resolve, reject) => {
      const transaction = tempIndexedDb.transaction(
        [storeName, 'timestamps', 'accessControl'],
        'readwrite'
      );

      const store = transaction.objectStore(storeName);
      const timestampStore = transaction.objectStore('timestamps');
      const accessControlStore = transaction.objectStore('accessControl');

      // Delete from all related stores
      const deleteData = store.delete(cacheKey);
      const deleteTimestamp = timestampStore.delete(cacheKey);
      const deleteAccess = accessControlStore.delete(cacheKey);

      // Handle success
      transaction.oncomplete = () => {
        console.log(`Successfully deleted data for ${storeName}:${cacheKey}`);
        resolve(true);
      };

      // Handle errors
      transaction.onerror = (error) => {
        console.error(`Error deleting from IndexedDB (${storeName}):`, error);
        reject(transaction.error);
      };

      // Handle individual request errors
      deleteData.onerror = () =>
        console.error('Error deleting data:', deleteData.error);
      deleteTimestamp.onerror = () =>
        console.error('Error deleting timestamp:', deleteTimestamp.error);
      deleteAccess.onerror = () =>
        console.error('Error deleting access control:', deleteAccess.error);
    });
  } catch (error) {
    console.error(`Failed to delete from IndexedDB (${storeName}):`, error);
    return false;
  }
};

const refreshAllData = async (
  orgId,
  currentUser,
  securityMeta,
  fetchEmployees,
  fetchFolders,
  fetchPractices
) => {
  if (orgId && currentUser && securityMeta) {
    if (fetchEmployees) {
      await fetchEmployees(true);
    }
    if (fetchFolders) {
      await fetchFolders(true);
    }
    if (fetchPractices) {
      await fetchPractices(true);
    }
  }
};

export {
  clearUserCaches,
  deleteFromIndexedDB,
  generateCacheKey,
  getFromIndexedDB,
  initIndexedDB,
  isDataStale,
  refreshAllData,
  saveToIndexedDB,
  validateAccess
};
