import { collection, getDocs, query, where } from '@firebase/firestore';
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Grow,
  TextField,
  Chip,
  ListItem,
  ListItemText
} from '@mui/material';
import { flatten } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';

const retrieveMyKnowledgeTests = async (org_ref, user_id) => {
  const communityRef = collection(db, `orgs/${org_ref}/knowledgeTests`);
  const q = query(communityRef, where('author_ref', '==', user_id));
  return getDocs(q)
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        return [];
      }
      return querySnapshot.docs.map((myTest) => ({
        ...myTest.data(),
        knowledge_test_id: myTest.id,
        org_ref
      }));
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const MyKnowledgeTestsSelect = ({ newProductValues, setNewProductValues }) => {
  MyKnowledgeTestsSelect.propTypes = {
    newProductValues: PropTypes.object,
    setNewProductValues: PropTypes.func
  };

  const { currentUser } = useCurrentUserContext();
  const [userKnowledgeTests, setUserKnowledgeTests] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetUserKnowledgeTests = async () => {
    setLoading(true);
    const { type } = currentUser;
    if (type === 'org') {
      const tests = await retrieveMyKnowledgeTests(
        currentUser.user_doc_id,
        currentUser.user_doc_id
      );
      setUserKnowledgeTests(tests);
    } else {
      const workplaces = currentUser.employee_work_places.map((workplace) =>
        retrieveMyKnowledgeTests(workplace.org_ref, currentUser.user_doc_id)
      );
      const tests = await Promise.all(workplaces);
      setUserKnowledgeTests(flatten(tests));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      currentUser &&
      newProductValues &&
      newProductValues.category.length > 0
    ) {
      const index = newProductValues.category.findIndex(
        (el) => el.value === 'knowledgeTests'
      );
      if (index > -1) {
        handleGetUserKnowledgeTests();
      }
    }
  }, [newProductValues, currentUser]);

  const getOrgName = (org_ref) => {
    if (currentUser.type === 'org') {
      return currentUser.org_name;
    }
    const workplaceDetails = currentUser.employee_work_places.find(
      (workplace) => workplace.org_ref === org_ref
    );
    return workplaceDetails ? workplaceDetails.org_name : '';
  };

  return (
    <Grow in={Boolean(true)}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          options={userKnowledgeTests}
          loading={loading}
          getOptionLabel={(option) => option?.knowledgeTest?.process_name || ''}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.knowledge_test_id}>
              <ListItemText
                primary={option.knowledgeTest.process_name}
                secondary={option.description}
                slotProps={{
                  secondary: {
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: 'calc(100% - 32px)'
                    }
                  }
                }}
              />
              <Chip
                color="secondary"
                variant="outlined"
                label={getOrgName(option.org_ref)}
              />
            </ListItem>
          )}
          onChange={(ev, newVal) => {
            setNewProductValues({
              ...newProductValues,
              knowledge_test_for_sale: newVal
            });
          }}
          isOptionEqualToValue={(option, value) =>
            option.knowledge_test_id === value.knowledge_test_id
          }
          value={newProductValues.knowledge_test_for_sale || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="בחר מבדק"
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: newProductValues.knowledge_test_for_sale ? (
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center'
                      }}
                    >
                      <Chip
                        label={getOrgName(
                          newProductValues.knowledge_test_for_sale.org_ref
                        )}
                        color="secondary"
                        // variant="outlined"
                        size="small"
                      />
                    </div>
                  ) : null,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }
              }}
            />
          )}
        />
      </Grid>
    </Grow>
  );
};

export default MyKnowledgeTestsSelect;
