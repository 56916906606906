import { Circle } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import submit from 'src/assets/mohcode/submit.svg';
import theme from 'src/theme';

const StepImportantNote = ({ notesArr }) => {
  StepImportantNote.propTypes = { notesArr: PropTypes.array };
  return (
    <Grid
      item
      xs={12}
      sx={{
        justifyContent: 'center',
        margin: 'auto',
        position: 'relative',
        zIndex: 2,
        display: 'flex'
      }}
    >
      <Card
        sx={{ background: theme.palette.background.paper, maxWidth: 1000 }}
        elevation={6}
      >
        <CardContent>
          <ListItem>
            <ListItemText
              primary={
                <Stack alignItems="center" direction="row">
                  <img
                    loading="lazy"
                    style={{
                      width: '80px',
                      height: '80px'
                    }}
                    src={submit}
                    alt="submit"
                  />
                  <Typography
                    sx={{ fontSize: '22px' }}
                    color="inherit"
                    fontWeight={800}
                  >
                    חשוב!
                  </Typography>
                </Stack>
              }
              slotProps={{
                primary: {
                  color: 'peru'
                }
              }}
            />
          </ListItem>
          <List>
            {notesArr.map((note) => (
              <Fragment key={uniqueId('note')}>
                <ListItem>
                  <ListItemAvatar
                    sx={{ width: 'fit-content', minWidth: '30px' }}
                  >
                    <Circle fontSize="10px" color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={note.description}
                    slotProps={{
                      primary: {
                        textAlign: 'justify'
                      }
                    }}
                  />
                </ListItem>
                <Divider width="50%" sx={{ margin: 'auto' }} />
              </Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StepImportantNote;
