/**
 * Moves the item to the new position in the input array. Useful for huge arrays where absolute performance is needed.
 * @param {*} array
 * @param {*} fromIndex
 * @param {*} toIndex
 * @returns Modifies the given array.
 */
export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

/**
 * Clones the given `array`, moves the item to a new position in the new array, and then returns the new array. The given `array` is not mutated.
 * @param {*} array
 * @param {*} fromIndex
 * @param {*} toIndex
 * @returns A new array with the item moved to the new position.
 */
export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}
