import {
  AddOutlined,
  BookmarkBorderOutlined,
  ReceiptLongOutlined,
  SearchOutlined
} from '@mui/icons-material';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Card,
  Divider,
  Grid,
  Hidden,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import theme from 'src/theme';
import marketplaceCategories from './marketplaceCategories';

const MarketplaceSearchField = () => {
  const [searchValues, setSearchValues] = useState({
    search: '',
    category: []
  });
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const handleChangeMarketplaceSearch = (e) => {
    const temp = { ...searchValues };
    temp[e.target.name] = e.target.value;
    setSearchValues(temp);
  };
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        width: matchMdDown ? '100%' : 'calc(100% - 12px)',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        p: '3px',
        borderRadius: 1
      }}
    >
      <Card sx={{ display: 'flex', flex: 1, py: 1 }}>
        <TextField
          label="קטגוריות"
          variant="standard"
          select
          name="category"
          value={searchValues.category}
          sx={{
            maxWidth: 180,
            minWidth: 80,
            width: '30%'
          }}
          slotProps={{
            input: {
              sx: {
                borderEndEndRadius: '0 !important',
                borderStartEndRadius: '0 !important',
                mt: '0 !important',
                height: '100% !important'
              },
              disableUnderline: true
            },

            select: {
              multiple: true,
              onChange: (e) => handleChangeMarketplaceSearch(e)
            },

            inputLabel: {
              sx: {
                pl: 1,
                mt: -1
              }
            }
          }}
        >
          {marketplaceCategories.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        <Divider orientation="vertical" />
        <TextField
          name="search"
          value={searchValues.search}
          onChange={(e) => handleChangeMarketplaceSearch(e)}
          sx={{
            paddingInlineStart: '4px',
            '& .MuiInputBase-root': {
              mt: searchValues.search !== '' ? '4px' : 1
            }
          }}
          variant="standard"
          fullWidth
          label="חפש ביריד שלנו"
          placeholder="הקלד כדי לחפש"
          autoFocus
          slotProps={{
            input: {
              sx: {
                borderEndStartRadius: '0 !important',
                borderStartStartRadius: '0 !important',
                height: '100%'
              },
              disableUnderline: true
            },

            inputLabel: {
              sx: {
                mt: -1,
                paddingInlineStart: '4px'
              },
              shrink: true
            }
          }}
        />
      </Card>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          my: 'auto'
        }}
      >
        <IconButton
          sx={{ maxWidth: 'fit-content', maxHeight: 'fit-content !important' }}
          color="primary"
        >
          <SearchOutlined />
        </IconButton>
      </Box>
    </Stack>
  );
};

const CommunityMarketPlaceToolbar = ({
  handleOpenAddProduct,
  savedProducts,
  openSavedProductsList,
  handleOpenPurchasesDialog
}) => {
  CommunityMarketPlaceToolbar.propTypes = {
    openSavedProductsList: PropTypes.func,
    handleOpenAddProduct: PropTypes.func,
    savedProducts: PropTypes.array,
    handleOpenPurchasesDialog: PropTypes.func
  };
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleOpenAddProduct(true);
          handleMobileMenuClose();
        }}
      >
        <IconButton
          onClick={() => {
            handleOpenAddProduct(true);
            handleMobileMenuClose();
          }}
          size="large"
          color="inherit"
        >
          <AddOutlined color="primary" />
        </IconButton>
        <p
          style={{
            color: theme.palette.primary.main
          }}
        >
          הצע למכירה
        </p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          openSavedProductsList();
          handleMobileMenuClose();
        }}
      >
        <IconButton
          onClick={() => {
            openSavedProductsList();
            handleMobileMenuClose();
          }}
          size="large"
          color="inherit"
        >
          <Badge
            badgeContent={savedProducts ? savedProducts.length : 0}
            color="error"
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top'
            }}
          >
            <BookmarkBorderOutlined />
          </Badge>
        </IconButton>
        <p>פרטים שנשמרו</p>
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleOpenPurchasesDialog();
          handleMobileMenuClose();
        }}
      >
        <IconButton
          onClick={() => {
            handleOpenPurchasesDialog();
            handleMobileMenuClose();
          }}
          size="large"
          color="inherit"
        >
          <ReceiptLongOutlined />
        </IconButton>
        <p>היסטוריית רכישות</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
      <Toolbar
        component={Grid}
        container
        spacing={matchMdDown ? 0 : 2}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid item sx={{ pb: 1, px: '0px !important' }} xs={9}>
          <Typography variant="h2">יריד מגדלור</Typography>
        </Grid>
        <Hidden mdUp>
          <Grid
            item
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            xs={3}
          >
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              {/* <Tooltip title="עגלת קניות">
                <IconButton
                  size="large"
                  sx={{
                    borderRadius: '8px !important',
                    border: `1px solid ${theme.palette.action.focus}`,
                    maxHeight: '50px'
                  }}
                  color="info"
                  aria-label="show cart"
                  //   color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <ShoppingCartOutlined />
                  </Badge>
                </IconButton>
              </Tooltip> */}
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                // sx={{
                //   borderRadius: '8px !important',
                //   border: `1px solid ${theme.palette.action.focus}`
                // }}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Grid>
        </Hidden>
        <Grid
          sx={{
            alignItems: 'center',
            display: 'flex',
            p: '0 !important',
            height: '100%'
          }}
          item
          xs={12}
          md={8}
        >
          <MarketplaceSearchField />
        </Grid>
        <Box sx={{ flexGrow: 1 }} />
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100%',
            pt: '0 !important'
          }}
          item
          md={4}
        >
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '4px' }}>
            <Tooltip title="פריטים שנשמרו">
              <IconButton
                sx={{
                  borderRadius: '8px !important',
                  border: `1px solid ${theme.palette.action.focus}`,
                  maxHeight: '50px'
                }}
                color="info"
                onClick={openSavedProductsList}
                size="large"
              >
                <Badge
                  badgeContent={savedProducts ? savedProducts.length : 0}
                  color="error"
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                  }}
                >
                  <BookmarkBorderOutlined />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="היסטוריית רכישות">
              <IconButton
                sx={{
                  borderRadius: '8px !important',
                  border: `1px solid ${theme.palette.action.focus}`,
                  maxHeight: '50px'
                }}
                color="info"
                onClick={handleOpenPurchasesDialog}
                size="large"
              >
                <ReceiptLongOutlined />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="עגלת קניות">
              <IconButton
                size="large"
                sx={{
                  borderRadius: '8px !important',
                  border: `1px solid ${theme.palette.action.focus}`,
                  maxHeight: '50px'
                }}
                color="info"
                aria-label="show cart"
                //   color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <ShoppingCartOutlined />
                </Badge>
              </IconButton>
            </Tooltip> */}
            <Button
              size="large"
              aria-label="הצע למכירה"
              // aria-controls={mobileMenuId}
              // aria-haspopup="true"
              // onClick={handleMobileMenuOpen}
              color="primary"
              variant="contained"
              sx={{
                maxHeight: '50px'
              }}
              onClick={() => handleOpenAddProduct()}
              endIcon={<AddOutlined fontSize="small" />}
            >
              הצע למכירה
            </Button>
          </Box>
        </Grid>
      </Toolbar>
      {renderMobileMenu}
    </Box>
  );
};
export default CommunityMarketPlaceToolbar;
