import { Box, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { lighten, styled } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import jobsTitles from 'src/common/jobsTitles';
import { useRbacDetails } from 'src/hooks/useRBAC/useRBAC';
import itemTypeChipsProps from './RoleDepartmentJobChipsProps';
import getJobTitlesAndRolesLabel from './getJobTitlesAndRolesLabel';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.grey[400], 0.6)
}));

const GroupItems = styled('ul')({
  padding: 0
});

const GroupedJobTitlesAndRolesAutoComplete = ({
  handleChange,
  value = [],
  alreadySelectedLabels = []
}) => {
  GroupedJobTitlesAndRolesAutoComplete.propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.array,
    alreadySelectedLabels: PropTypes.array
  };
  const { rbacDetails } = useRbacDetails();
  //   console.log(rbacDetails);
  const options = useMemo(() => {
    if (rbacDetails) {
      const jobs = jobsTitles.map((job) => ({
        ...job,
        item_type: 'job',
        id: job.name,
        name: job.name
      }));
      const roles =
        rbacDetails.roles && rbacDetails.roles.length > 0
          ? rbacDetails.roles.map((role) => ({
              ...role,
              item_type: 'role',
              id: role.role_id,
              name: role.role_id
            }))
          : [];
      return [...jobs, ...roles];
    }
    return [];
  }, [rbacDetails]);

  const getOptionLabel = (option, rbacDetailsToCheck) => {
    if (rbacDetails) {
      const labelToReturn = getJobTitlesAndRolesLabel(
        option,
        rbacDetailsToCheck
      );
      return labelToReturn;
    }
  };

  return (
    <Autocomplete
      id="groupedJobTitlesAndRolesAutoComplete"
      options={options}
      loading={!options || !rbacDetails}
      loadingText="נא להמתין"
      noOptionsText="אין תוצאות לחיפוש זה"
      multiple
      groupBy={(option) => option.item_type}
      renderGroup={({ key, children, group }) => (
        <li key={`${key}-${uniqueId()}`}>
          <GroupHeader>
            {group === 'role' && 'סמכויות'}
            {group === 'department' && 'מחלקות'}
            {group === 'job' && 'משרות'}
          </GroupHeader>
          <GroupItems>{children}</GroupItems>
        </li>
      )}
      getOptionDisabled={(option) => {
        const isSelectedInAnotherProcess = alreadySelectedLabels.includes(
          option.id
        );
        return isSelectedInAnotherProcess;
      }}
      disableCloseOnSelect={Boolean(true)}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      getOptionLabel={(option) => getOptionLabel(option, rbacDetails)}
      sx={{ maxWidth: 650, ml: 2 }}
      fullWidth
      value={value}
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const { key, ...optionProps } = props;
        const label = getOptionLabel(option, rbacDetails);
        // console.log(option);
        return (
          <Box
            key={`${key}-${uniqueId()}`}
            sx={{
              borderRadius: '8px',
              margin: '5px'
            }}
            component="li"
            {...optionProps}
          >
            {label}
          </Box>
        );
      }}
      onChange={(e, newVal) => {
        // console.log(newVal);
        handleChange(newVal);
      }}
      renderTags={(tagsValue, getTagProps) =>
        tagsValue.map((option, index) => {
          const label = getOptionLabel(option, rbacDetails);
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              {...itemTypeChipsProps[option.item_type]}
              label={label}
              key={key}
              {...tagProps}
              // {...props}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField {...params} label="למי המשוב מתאים ?" />
      )}
    />
  );
};

export default GroupedJobTitlesAndRolesAutoComplete;
