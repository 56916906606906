import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { auth, functions } from 'src/_firebase/firebase';
import genderes from 'src/common/genders';
import jobsTitles from 'src/common/jobsTitles';
import i18n from 'src/i18n';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import handleCreateNewPersonalAccount from 'src/utils/handleCreateNewPersonalAccount';
import { strengthColor, strengthIndicator } from 'src/utils/password-strength';
import * as Yup from 'yup';
import regex from './regex';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const PersonalRegisterForm = ({ loading, handleOpenVerifyCodeDialog }) => {
  PersonalRegisterForm.propTypes = {
    loading: PropTypes.bool,
    handleOpenVerifyCodeDialog: PropTypes.func
  };
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const [createUserWithEmailAndPassword] =
    useCreateUserWithEmailAndPassword(auth);
  // const theme = useTheme();
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();
  const [passwordShowing, setIsPasswordShowing] = useState(false);
  const [userAlreadyRegistered, setUserAlreadyRegistered] = useState(false);
  const [loadingToCheckId, setLoadingToCheckId] = useState(false);
  const [idBlockReason, setIdBlockReason] = useState(null);

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp, theme.palette));
  };
  const handleShowPassword = () => {
    setIsPasswordShowing(!passwordShowing);
  };

  const checkIfIdExistsAlready = async (id) => {
    setLoadingToCheckId(true);
    setIdBlockReason(null);

    // Validate numbers only
    if (!/^\d+$/.test(id)) {
      setIdBlockReason('numbersOnly');
      setLoadingToCheckId(false);
      return;
    }

    const checkIfIdExistsInAuth = httpsCallable(
      functions,
      'secondGencheckIfIdExistsInAuth'
    );

    try {
      const res = await checkIfIdExistsInAuth({ id });
      if (res.data.exists) {
        setUserAlreadyRegistered(true);
      } else if (res.data.blocked) {
        // Generic blocked reason without exposing details
        setIdBlockReason('blocked');
        setUserAlreadyRegistered(false);
      } else {
        setUserAlreadyRegistered(false);
      }
    } catch (err) {
      console.error('Error checking ID:', err);
      setIdBlockReason('error');
    } finally {
      setLoadingToCheckId(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: '',
          id: '',
          password: '',
          phone: '',
          policy: false,
          job_title: '',
          gender: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('validEmail'))
            .max(255)
            .required(t('emailRequired')),
          first_name: Yup.string().max(255).required(t('firstNameRequired')),
          last_name: Yup.string().max(255).required(t('lastNameRequired')),
          id: Yup.string()
            .max(9, t('idCantBeMoreThan9'))
            .required(t('idRequired')),
          phone: Yup.string()
            .max(40)
            .required(t('phoneRequired'))
            .matches(phoneRegExp, t('validPhone')),
          password: Yup.string()
            .min(6, 'הסיסמה חייבת להיות מעל 6 אותיות')
            .max(100)
            .required(t('passwordRequired'))
            .matches(
              /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d@$#%^)(-_=+!%*?&]*$/,
              'הסיסמה יכולה להיות רק באנגלית, ומכילה לפחות אות אחת גדולה, אות קטנה ומספר אחד'
            )
            .matches(/[0-9]/, 'הסיסמה חייבת להכיל לפחות מספר אחד')
            .matches(/[a-z]/, 'הסיסמה חייבת להכיל לפחות אות קטנה אחת')
            .matches(/[A-Z]/, 'הסיסמה חייבת להכיל לפחות אות גדולה אחת'),
          job_title: Yup.string().max(100).required(t('jobTitleRequired')),
          policy: Yup.boolean().oneOf([true], t('policyChecked')),
          gender: Yup.string()
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setValues
        }) => (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
              // handleCreateNewPersonalAccount(
              //   values,
              //   createUserWithEmailAndPassword,
              //   'personal'
              // );
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  error={Boolean(touched.first_name && errors.first_name)}
                  fullWidth
                  helperText={touched.first_name && errors.first_name}
                  label={t('firstName')}
                  margin="normal"
                  name="first_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  error={Boolean(touched.last_name && errors.last_name)}
                  fullWidth
                  helperText={touched.last_name && errors.last_name}
                  label={t('lastName')}
                  margin="normal"
                  name="last_name"
                  onBlur={handleBlur}
                  required
                  onChange={handleChange}
                  value={values.last_name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  error={Boolean(
                    (touched.id && errors.id) ||
                      userAlreadyRegistered ||
                      idBlockReason
                  )}
                  helperText={
                    touched.id && !userAlreadyRegistered && !idBlockReason
                      ? errors.id
                      : touched.id && userAlreadyRegistered
                      ? t('alreadyRegistered')
                      : touched.id && idBlockReason
                      ? t(`idErrors.${idBlockReason}`)
                      : null
                  }
                  fullWidth
                  label={t('id')}
                  margin="normal"
                  name="id"
                  id="id"
                  required
                  onBlur={(e) => {
                    handleBlur(e);
                    const value = e.target.value.trim();
                    if (value && value.length > 0) {
                      checkIfIdExistsAlready(value);
                    }
                  }}
                  onChange={(e) => {
                    // Only allow numbers
                    const value = e.target.value.replace(/[^\d]/g, '');
                    handleChange({
                      target: { name: 'id', value }
                    });
                    setIdBlockReason(null);
                  }}
                  type="text"
                  value={values.id}
                  variant="outlined"
                  sx={{ mt: 0 }}
                  slotProps={{
                    htmlInput: {
                      maxLength: 9,
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label={t('phone')}
                  margin="normal"
                  name="phone"
                  sx={{ mt: 0 }}
                  required
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    // setNewUserValues({
                    //   ...newUserValues,
                    //   phone: e.target.value
                    // });
                  }}
                  type="phone"
                  value={values.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="gender-simple-select-label">
                    {i18n.t('genders.gender')}
                  </InputLabel>
                  <Select
                    labelId="gender-simple-select-label"
                    id="gender-simple-select"
                    value={values.gender}
                    label={t('gender')}
                    fullWidth
                    name="gender"
                    onChange={(e) => {
                      const {
                        target: { name, value }
                      } = e;
                      setValues({ ...values, [name]: value });
                    }}
                  >
                    {genderes.map((title) => (
                      <MenuItem key={title.name} value={title.name}>
                        {title.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t('jobTitle')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.job_title}
                    label={t('jobTitle')}
                    fullWidth
                    name="job_title"
                    onChange={handleChange}
                  >
                    {jobsTitles.map((title) => (
                      <MenuItem key={title.name} value={title.name}>
                        {title.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    // setNewUserValues({
                    //   ...newUserValues,
                    //   email: e.target.value
                    // });
                  }}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={
                    (touched.password && errors.password) ||
                    t('passwordStrengthGuide')
                  }
                  label={t('password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    const onlyEnglish = e.target.value.replace(
                      regex.password,
                      ''
                    );
                    changePassword(onlyEnglish);
                  }}
                  type={passwordShowing ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                  slotProps={{
                    input: {
                      endAdornment: passwordShowing ? (
                        <VisibilityOffOutlined
                          sx={{ cursor: 'pointer' }}
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <VisibilityOutlined
                          sx={{ cursor: 'pointer', transition: 'ease-in 1s' }}
                          onClick={handleShowPassword}
                          color="action"
                        />
                      )
                    }
                  }}
                />
              </Grid>
              {strength !== 0 && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <Box
                          style={{
                            backgroundColor: level?.color,
                            width: '100%'
                          }}
                          sx={{ width: 85, height: 8, borderRadius: '7px' }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" fontSize="0.75rem">
                          {level?.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </FormControl>
                  {strength < 3 && values.password.length > 6 && (
                    <FormHelperText error>{t('passwordIsWeak')}</FormHelperText>
                  )}
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {t('iHaveReadThe')}
                    &nbsp;
                    <Link
                      color="primary"
                      component="a"
                      href={`/${routesConst.privacy}`}
                      // to={routesConst.privacy}
                      underline="always"
                      variant="h6"
                    >
                      {t('termsAndConditions')}
                    </Link>
                  </Typography>
                </Stack>
              </Grid>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>{errors.policy}</FormHelperText>
              )}
              <Grid
                sx={{ display: 'flex', justifyContent: 'center' }}
                item
                xs={12}
                md={12}
              >
                <Button
                  color="primary"
                  fullWidth
                  disabled={
                    loading ||
                    userAlreadyRegistered ||
                    !values.policy ||
                    (errors && Object.keys(errors).length > 0) ||
                    loadingToCheckId
                  }
                  size="large"
                  type="button"
                  onClick={() => {
                    // console.log(errors);
                    if (
                      !errors ||
                      (errors && Object.keys(errors).length === 0)
                    ) {
                      setLoadingToCheckId(true);
                      handleOpenVerifyCodeDialog(values, setLoadingToCheckId);
                    }
                  }}
                  variant="contained"
                  sx={{ width: '50%' }}
                >
                  {t('registerNow')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body1">
                  {t('haveAnAccount')}
                  &nbsp;
                  <Link
                    component={RouterLink}
                    to={`/${routesConst.login}`}
                    variant="h6"
                  >
                    {t('signIn')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Backdrop open={loadingToCheckId}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};
export default PersonalRegisterForm;
