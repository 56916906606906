let coursewaresPromise = null;

function getGlobalCoursewares() {
  if (!coursewaresPromise) {
    coursewaresPromise = import('./globalCoursewaresSlides').then(
      (module) => module.default
    );
  }
  return coursewaresPromise;
}

export default getGlobalCoursewares;
