import { QuizOutlined } from '@mui/icons-material';
import {
  Chip,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { alpha, lighten } from '@mui/material/styles';
import { bool, func, object } from 'prop-types';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';

const KnowledgeTestPreviewNameAndContentListItem = ({
  post,
  isBtn,
  showDivider,
  handleLoadFromPreviousSurvies
}) => {
  const { currentUser } = useCurrentUserContext();
  const shouldSeeLabels =
    currentUser.type === 'org' || post.author_ref === currentUser.user_doc_id;

  return (
    <>
      <ListItem
        secondaryAction={
          post.source === 'purchased' && (
            <Chip label="נרכש" variant="filled" color="secondary" />
          )
        }
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <ListItemButton
          sx={{
            cursor: isBtn && 'pointer',
            '&:hover': {
              backgroundColor:
                isBtn &&
                alpha(
                  theme.palette.action.hover,
                  theme.palette.action.hoverOpacity
                )
            }
          }}
          onClick={(e) => {
            if (handleLoadFromPreviousSurvies) {
              handleLoadFromPreviousSurvies(post);
            }
          }}
        >
          <ListItemIcon
            sx={{
              width: 'fit-content',
              height: 90,
              alignItems: 'center',
              maxWidth: 100
            }}
          >
            <QuizOutlined
              sx={{
                fill: lighten(
                  post.source === 'purchased'
                    ? theme.palette.secondary.main
                    : theme.palette.info.light,
                  0.5
                )
              }}
              fontSize="large"
            />
          </ListItemIcon>
          <ListItemText
            primary={post.knowledgeTest.process_name}
            secondary={`המבדק מכיל 
                          ${post.knowledgeTest?.pages?.length || 1} 
                        עמודים וסך של
                         ${post.knowledgeTest?.pages.reduce(
                           (acc, curr) => acc + curr.elements.length,
                           0
                         )} 
                         שאלות`}
          />
        </ListItemButton>
        {shouldSeeLabels && (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            sx={{ color: theme.palette.primary.main }}
          >
            <Typography>
              {`בחרת ב: 
                  ${post.labels ? post.labels.length : 0}
                  תפקידים ו- 
                  ${post.allowed_employees ? post.allowed_employees.length : 0} 
                  עובדים`}
            </Typography>
          </Stack>
        )}
      </ListItem>
      {showDivider && <Divider />}
    </>
  );
};

KnowledgeTestPreviewNameAndContentListItem.propTypes = {
  post: object,
  showDivider: bool,
  isBtn: bool,
  handleLoadFromPreviousSurvies: func
};

export default KnowledgeTestPreviewNameAndContentListItem;
