import { SearchOutlined } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import './searchField.css';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.default, 0.1),
  border: `2px solid ${theme.palette.primary.light}`,
  '&:hover': {
    border: `2px solid ${theme.palette.primary.dark}`,
    backgroundColor: alpha(theme.palette.action.focus, 0.025)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
    px: 1
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingInlineStart: `calc(0.5em + ${theme.spacing(4)})`,
    paddingInlineEnd: '0.5em',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '26ch'
      }
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      padding: '20px'
    }
  }
}));

const ExpandingSearchField = ({
  onChange,
  placeholder = 'השתמש במילות מפתח',
  onBlur
}) => {
  ExpandingSearchField.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string
  };
  return (
    <Search
      onChange={(e) => onChange(e)}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
    >
      <SearchIconWrapper>
        <SearchOutlined />
      </SearchIconWrapper>
      <StyledInputBase
        name={`expanding-search-${uniqueId('field')}`}
        placeholder={placeholder}
        inputProps={{
          'aria-label': 'search'
        }}
      />
    </Search>
  );
};

export default ExpandingSearchField;
