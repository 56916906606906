class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    }).catch((err) => {
      console.log(err);
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, viewerConfig, url, embedMode, file_name) {
    const isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isIphone) {
      console.log('iphone');
    }
    // console.log(/(iPhone)/i.test(navigator.userAgent));
    const config = {
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url
          }
        },
        metaData: {
          fileName: file_name || '  ',
          id: process.env.REACT_APP_ADOBE_CLIENT_ID
        }
      },
      {
        ...viewerConfig,
        embedMode: isIphone ? 'IN_LINE' : embedMode
      }
    );
    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId, filePromise, fileName) {
    this.adobeDCView = new window.AdobeDC.View({
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
      divId
    });
    this.adobeDCView.previewFile(
      {
        content: {
          promise: filePromise
        },
        metaData: {
          fileName
        }
      },
      {}
    );
  }
}

export default ViewSDKClient;
