import { PhoneOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Icon,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import lgihthouseFallback from 'src/assets/Brilliant Solution For Your Home/lighthouse-fallback.webp';
import mgdalorFull from 'src/assets/mgdalorFull.webp';
import shiningStar from 'src/assets/orgPage/shiningStars.svg';
import theme from 'src/theme';
import './style.css';

const MobileViewHeroSection = ({ orgPageValues, mainPic }) => {
  MobileViewHeroSection.propTypes = {
    orgPageValues: PropTypes.object,
    mainPic: PropTypes.object
  };

  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {orgPageValues &&
      orgPageValues.org_pictures &&
      orgPageValues.org_pictures.length > 0 ? (
        <>
          {mainPic ? (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                zIndex: 1,
                backgroundImage: 'linear-gradient(to bottom, red, black)'
              }}
              //   src={mainPic.url}
              //   component="img"
            >
              <img
                loading="lazy"
                style={{
                  position: 'absolute',
                  objectFit: 'contain',
                  height: '75vh'
                }}
                src={mainPic.url}
                alt="mainpic"
              />
            </Box>
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: '0 0 50% 50%' }}
            />
          )}
        </>
      ) : (
        <CardMedia
          sx={{
            backgroundImage: 'linear-gradient(to bottom, red, black)'
          }}
          component="img"
          src={mgdalorFull}
        />
      )}
      <Stack
        sx={{
          zIndex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Avatar
          sx={{
            width: '100px',
            height: '100px',
            boxShadow: theme.shadows[4]
          }}
          src={orgPageValues.avatar || mgdalorFull}
        />
        <Typography
          sx={{ color: theme.palette.primary.contrastText }}
          variant="h1"
          fontSize="3rem"
        >
          {orgPageValues.org_name}
        </Typography>
        <Typography
          variant="4"
          sx={{ color: theme.palette.primary.contrastText }}
        >
          {orgPageValues.description}
        </Typography>
      </Stack>
    </Stack>
  );
};

const DesktopViewHeroSection = ({ orgPageValues, mainPic }) => {
  DesktopViewHeroSection.propTypes = {
    orgPageValues: PropTypes.object,
    mainPic: PropTypes.object
  };
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Grid item md={6} xs={12}>
        {orgPageValues && (
          <Stack
            gap={2}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-around',
              height: '100%',
              mt: -2
            }}
          >
            <Avatar
              sx={{
                width: '170px',
                height: '160px',
                boxShadow: theme.shadows[4],
                objectFit: 'cover'
              }}
              src={orgPageValues.avatar || mgdalorFull}
              slotProps={{
                img: {
                  sx: {
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill'
                  }
                }
              }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
                px: 2,
                pt: 0
              }}
              variant="h1"
              fontSize="5rem"
            >
              {orgPageValues.org_name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider sx={{ margin: 'auto', borderWidth: '3px', mx: 2 }} />
              </Grid>
              <Grid item xs={12} container sx={{ mx: 2 }}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  sx={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Typography
                    variant="button"
                    sx={{
                      // textAlign: 'center',
                      // mx: 'auto',
                      // px: 2,
                      fontSize: '1.5rem'
                      //   color: theme.palette.primary.contrastText
                    }}
                    // width="90%"
                    component="a"
                    href="#orgLocation"
                  >
                    {orgPageValues.formattedAddress || <Skeleton />}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  item
                  xs={12}
                  sm={4}
                  md={6}
                >
                  <Button variant="text" sx={{ my: 0, pt: 0 }}>
                    <Typography
                      variant="button"
                      sx={{
                        textAlign: 'end',
                        mx: 'auto',
                        fontSize: '1.5rem'
                        //   color: theme.palette.primary.contrastText
                      }}
                      width="100"
                      component="a"
                      href={`tel:${orgPageValues.internal_phone}`}
                    >
                      {orgPageValues.internal_phone}
                    </Typography>
                    <Icon className="phonePulse" sx={{ width: 42, height: 42 }}>
                      <PhoneOutlined />
                    </Icon>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          margin: 'auto',
          position: 'relative'
        }}
      >
        {mainPic && (
          <>
            <img
              loading="lazy"
              src={shiningStar}
              alt="stars"
              style={{
                width: '160px',
                height: '160px',
                position: 'absolute',
                left: '80px',
                filter: 'contrast(1) brightness(2)',
                bottom: '-6vh'
              }}
            />
            <img
              loading="lazy"
              src={shiningStar}
              alt="stars"
              style={{
                width: '80px',
                height: '80px',
                position: 'absolute',
                left: '60px',
                top: '-6vh',
                filter: 'contrast(0.1)'
              }}
            />
            <img
              src={shiningStar}
              alt="stars"
              loading="lazy"
              style={{
                width: '120px',
                height: '120px',
                position: 'absolute',
                left: '1vw',
                top: '19vh',
                filter: 'contrast(8) hue-rotate(200deg)'
              }}
            />
          </>
        )}
        {mainPic ? (
          <Box
            component="img"
            sx={{
              width: matchDownMd ? '40vw' : '30vw',
              height: matchDownMd ? '270px' : '470px',
              objectFit: 'cover',
              borderRadius: '0 0 50% 50%',
              maxWidth: '500px',
              minWidth: '300px',
              margin: 'auto',
              zIndex: 1,
              boxShadow: theme.shadows[6]
            }}
            src={mainPic.url}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              width: '30vw',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '0 0 50% 50%',
              maxWidth: '550px',
              minWidth: '300px'
            }}
          />
        )}
        {/* {mainPic && (
          <Box
            sx={{
              width: matchDownMd ? '40vw' : '30vw',
              height: matchDownMd ? '270px' : '470px',
              objectFit: 'contain',
              borderRadius: '0 0 50% 50% !important',
              maxWidth: '500px',
              minWidth: '300px',
              margin: 'auto',
              position: 'absolute',
              zIndex: 0,
              top: 0,
              transform: 'scale(1) translateY(6px) translateX(12px)'
            }}
            className="bgPattern"
          />
        )} */}
      </Grid>
    </>
  );
};

const OrgPageHeroSlider = ({ orgPageValues }) => {
  OrgPageHeroSlider.propTypes = {
    orgPageValues: PropTypes.object
  };
  const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
  const [mainPic, setMainPic] = useState();

  useEffect(() => {
    if (orgPageValues) {
      if (orgPageValues.org_pictures && orgPageValues.org_pictures.length > 0) {
        const indexOfMain = orgPageValues.org_pictures.findIndex(
          (el) => el.metadata && el.metadata.mainPic === 'true'
        );
        if (indexOfMain > -1) {
          setMainPic(orgPageValues.org_pictures[indexOfMain]);
        } else {
          setMainPic(orgPageValues.org_pictures[0]);
        }
      } else {
        setMainPic({
          name: 'default-fallback-img',
          url: lgihthouseFallback
        });
      }
    }
  }, [orgPageValues]);

  return (
    <DesktopViewHeroSection mainPic={mainPic} orgPageValues={orgPageValues} />
  );
};

export default OrgPageHeroSlider;
