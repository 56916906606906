import {
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc
} from '@firebase/firestore';
import { FavoriteBorderOutlined } from '@mui/icons-material';
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack
} from '@mui/material';
import { deleteObject, ref } from 'firebase/storage';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { db, storage } from 'src/_firebase/firebase';
import EmptyState from 'src/components/shared/EmptyState/EmptyState';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import ChoosePostResponsiblesDialog from './CommunityPostItem/ChoosePostResponsiblesDialog';
import ChooseVisibileForDialog from './CommunityPostItem/ChooseVisibileForDialog';
import DeleteCommunityPostDialog from './CommunityPostItem/DeleteCommunityPostDialog';
import EditCommunityPostDialog from './CommunityPostItem/EditCommunityPostDialog';
import CommunityPostItemCard from './CommunityPostItemCard';
import BuildKnowledgeTestDialog from './KnowledgeTestDialog/BuildKnowledgeTestDialog';
import PostSignsDialog from './PostSignaturesDialog/PostSignsDialog';
import SignPostDialog from './SignPostDialog';

const deletePostFromDb = async (
  post_id,
  org_ref,
  gotFile,
  type = 'community'
) => {
  const postRef = `orgs/${org_ref}/${type}/${post_id}`;
  const postDocRef = doc(db, postRef);
  return deleteDoc(postDocRef)
    .then(async () => {
      if (gotFile) {
        const postStorageRef = ref(storage, postRef);
        return deleteObject(postStorageRef)
          .then(() => ({
            storage: 'deleted',
            db: 'deleted'
          }))
          .catch((err) => {
            console.log(err);
            return {
              storage: 'err',
              db: 'deleted'
            };
          });
      }
      return {
        storage: 'no-file',
        db: 'deleted'
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        db: 'err'
      };
    });
};

const PostsListSkeleton = () => (
  <List sx={{ pt: 0, mt: 0 }} component={Card}>
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular" width={52} height={52} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" width={100} />}
        secondary={<Skeleton width={110} variant="text" />}
      />
      <ListItemSecondaryAction>
        <Stack gap={0.5}>
          {[1, 2, 3].map((dot) => (
            <Skeleton key={dot} variant="circular" width={5} height={5} />
          ))}
        </Stack>
      </ListItemSecondaryAction>
    </ListItem>
    <Stack direction="row" gap={2} sx={{ pl: 3 }} alignItems="center">
      <FavoriteBorderOutlined color="disabled" />
      <Skeleton variant="text" width={80} height="1rem" />
    </Stack>
  </List>
);

const CommunityPostsList = ({
  posts,
  handleFavoriteItem,
  setPosts,
  handleViewCommunityAttachedFile,
  setSignViewIsOpen,
  setAttachedFileToView,
  signViewIsOpen,
  attachedFileToView
}) => {
  CommunityPostsList.propTypes = {
    posts: PropTypes.any,
    handleFavoriteItem: PropTypes.func,
    setSignViewIsOpen: PropTypes.func,
    setAttachedFileToView: PropTypes.func,
    signViewIsOpen: PropTypes.any,
    attachedFileToView: PropTypes.any,
    handleViewCommunityAttachedFile: PropTypes.func,
    setPosts: PropTypes.func
  };

  const { currentUser } = useCurrentUserContext();
  const { setSnackbarState, snackbarState } = useSnackbar();
  const [isEditPostOpen, setEditPostOpen] = useState();
  const [isDeletePostOpen, setDeletePostOpen] = useState();
  const [postSignsOpen, setPostSignsOpen] = useState();
  const [isChooseVisibileForDialogOpen, setChooseVisibileForDialogOpen] =
    useState(false);
  const [isOpenChooseResponsibleDialog, setOpenChooseResponsibleDialog] =
    useState();
  const [isBuildKnowledgeTestDialogOpen, setOpenBuildKnowledgeTestDialog] =
    useState(false);

  const handleCloseSignPostViewDialog = () => {
    setSignViewIsOpen(false);
  };

  const updateDbSignCollection = async (dataToSave, postId, orgRef) => {
    const signCollectionRef = doc(
      db,
      `orgs/${orgRef}/community/${postId}/signs/${currentUser.user_doc_id}`
    );
    setDoc(signCollectionRef, {
      ...dataToSave,
      signed_at: serverTimestamp()
    })
      .then(() => {
        handleCloseSignPostViewDialog();
        setSnackbarState({
          ...snackbarState,
          open: true,
          msg: 'החתימה נשמרה בהצלחה ',
          severity: 'success'
        });
      })
      .catch((err) => {
        setSnackbarState({
          ...snackbarState,
          open: true,
          msg: 'לא הצלחנו לשמור את החתימה, נא לנסות שוב מאוחר יותר',
          severity: 'error'
        });
        console.log(err);
      });
  };

  const handleSaveSign = (post) => {
    const { post_id, org_ref } = post;
    // console.log(org_ref, post_id);
    const temp = [...posts];
    const index = temp.findIndex((el) => el.post_id === post_id);
    const dataToSave = {
      user_id: currentUser.user_doc_id,
      signed_at: { seconds: moment().unix() },
      org_ref,
      post_id
    };
    updateDbSignCollection(dataToSave, post_id, org_ref);
    if (index > -1) {
      // save sign to post
      const signs = temp[index].signs || [];
      const indexOfSign = signs.findIndex(
        (el) => el.user_id === currentUser.user_doc_id
      );
      if (indexOfSign === -1) {
        // sign does not exist
        signs.unshift(dataToSave);
      } else {
        // sign exists, just modify time
        signs[index].signed_at = {
          seconds: moment().unix()
        };
      }
      temp[index].signs = signs;
      setPosts(temp);
    }
  };

  const handleSignCommunityPost = (postToSign, idInput) => {
    const { id } = currentUser;
    if (idInput === id) {
      handleSaveSign(postToSign);
    } else {
      setSnackbarState({
        ...snackbarState,
        open: true,
        msg: 'נא לוודא שהזנת ת.ז נכונה ',
        severity: 'warning'
      });
    }
  };
  const handleCloseEditPostDialog = () => {
    setEditPostOpen();
  };
  const handleCloseDeletePost = () => {
    setDeletePostOpen();
  };

  const handleArchievePost = async (postToArchieve) => {
    const { post_id, org_ref, knowledge_test_id } = postToArchieve;
    const temp = [...posts];
    const index = temp.findIndex((el) => el.post_id === post_id);
    if (index > -1) {
      temp[index].archived = true;
      const postRef = doc(
        db,
        `orgs/${org_ref}/${
          knowledge_test_id ? 'knowledgeTests' : 'community'
        }/${post_id}`
      );
      await updateDoc(postRef, {
        status: 'archieved',
        last_modified_at: serverTimestamp()
      })
        .then(() => {
          setPosts(temp);
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'success',
            msg: knowledge_test_id
              ? 'מבדק הידע הועבר לארכיון בהצלחה'
              : 'הפוסט הועבר לארכיון בהצלחה'
          });
          handleCloseDeletePost();
        })
        .catch((err) => {
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'error',
            msg: 'לא הצלחנו להעביר את הפוסט לארכיון'
          });
          console.log(err);
        });
    }
  };
  const handleDeletePost = async (post) => {
    const { post_id, org_ref, file, knowledge_test_id } = post;
    const deleteStatus = await deletePostFromDb(
      post_id,
      org_ref,
      post.file,
      knowledge_test_id && 'knowledgeTests'
    );
    console.log(deleteStatus);
    if (
      deleteStatus.storage !== 'deleted' &&
      deleteStatus.storage !== 'no-file'
    ) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'מחקנו את העדכון אך לא הצלחנו למחוק את הקובץ מהאחסון'
      });
    }
    if (deleteStatus.db === 'deleted' || deleteStatus.storge === 'no-file') {
      const items = [...posts];
      const index = items.findIndex((el) => el.post_id === post.post_id);
      if (index === 0) {
        items.shift();
      } else if (index === items.length - 1) {
        items.pop();
      } else if (index > 0 && index < items.length - 1) {
        items.splice(index, 1);
      }
      setPosts(items);
      handleCloseDeletePost();
      if (deleteStatus.storage === 'deleted') {
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'success',
          msg: 'הקובץ נמחק בהצלחה'
        });
      }
    }
  };

  const handleOpenPostSignaturesList = (postToViewSigns) => {
    // console.log(postToViewSigns);
    setPostSignsOpen(postToViewSigns);
  };
  const handleClosePostSignaturesList = (postToViewSigns) => {
    // console.log(postToViewSigns);
    setPostSignsOpen(false);
  };

  const handleOpenChooseKnowledgeTestVisibileForDialog = (post) =>
    setChooseVisibileForDialogOpen(post);
  const handleCloseChooseKnowledgeTestVisibileForDialog = () =>
    setChooseVisibileForDialogOpen(false);

  const handleCloseBuildKnowledgeTestDialog = () =>
    setOpenBuildKnowledgeTestDialog(false);

  const handleSaveNewKnowledgeTestToLocalState = (details) => {
    setSnackbarState({
      ...snackbarState,
      msg: 'עריכת מבדק ידע אינה זמינה כעת ותהיה בקרוב',
      severity: 'error',
      open: true
    });
    console.log(details);
  };

  const handleOpenEditKnowledgeTestDialog = (post) => {
    // console.log(post);
    setOpenBuildKnowledgeTestDialog(post);
  };

  const handleOpenChooseManagementResponsible = (post) => {
    setOpenChooseResponsibleDialog(post);
  };
  const handleCloseChooseManagementResponsible = (post) => {
    setOpenChooseResponsibleDialog();
  };
  const handleSaveNewDurationChange = async (
    newPostDetails,
    onCloseCallback
  ) => {
    // console.log(newPostDetails);
    const { duration, post_id, org_ref } = newPostDetails;
    const temp = [...posts];
    const index = temp.findIndex((el) => el.post_id === post_id);
    if (index > -1) {
      const dataToSave = {
        duration,
        last_modified_at: moment().unix(),
        last_modified_by: currentUser.user_doc_id
      };
      temp[index] = {
        ...temp[index],
        ...dataToSave
      };
      // console.log(temp);
      const postRef = doc(db, `orgs/${org_ref}/community/${post_id}`);
      await updateDoc(postRef, {
        ...dataToSave,
        last_modified_at: serverTimestamp()
      })
        .then(() => {
          setPosts(temp);
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'success',
            msg: 'השינוי נשמר בהצלחה'
          });
          if (onCloseCallback) {
            onCloseCallback();
          }
        })
        .catch((err) => {
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'error',
            msg: 'לא הצלחנו לשמור את השינוי'
          });
          console.log(err);
        });
    }
  };

  // console.log(posts);
  return (
    <>
      {posts && posts.length > 0 ? (
        <>
          {posts.map((post) => (
            <CommunityPostItemCard
              handleViewCommunityAttachedFile={handleViewCommunityAttachedFile}
              setDeletePostOpen={setDeletePostOpen}
              handleFavoriteItem={handleFavoriteItem}
              setEditPostOpen={setEditPostOpen}
              post={post}
              handleOpenPostSignaturesList={handleOpenPostSignaturesList}
              handleOpenChooseKnowledgeTestVisibileForDialog={
                handleOpenChooseKnowledgeTestVisibileForDialog
              }
              handleOpenEditKnowledgeTestDialog={
                handleOpenEditKnowledgeTestDialog
              }
              handleOpenChooseManagementResponsible={
                handleOpenChooseManagementResponsible
              }
              handleSaveNewDurationChange={handleSaveNewDurationChange}
              key={post.post_id}
            />
          ))}
        </>
      ) : posts && posts.length === 0 ? (
        <EmptyState
          key="community-empty-state"
          secondary="עדכונים שיתווספו יופיעו כאן"
        />
      ) : (
        <Grid item xs={12}>
          <PostsListSkeleton />
        </Grid>
      )}
      {signViewIsOpen && (
        <SignPostDialog
          open={Boolean(signViewIsOpen)}
          onClose={handleCloseSignPostViewDialog}
          post={signViewIsOpen}
          handleSignCommunityPost={handleSignCommunityPost}
        />
      )}
      {!!isEditPostOpen && (
        <EditCommunityPostDialog
          open={Boolean(isEditPostOpen)}
          onClose={handleCloseEditPostDialog}
          postToEdit={isEditPostOpen}
        />
      )}
      {isBuildKnowledgeTestDialogOpen && (
        <BuildKnowledgeTestDialog
          open={isBuildKnowledgeTestDialogOpen}
          onClose={handleCloseBuildKnowledgeTestDialog}
          handleSaveTest={handleSaveNewKnowledgeTestToLocalState}
          posts={posts}
        />
      )}
      {Boolean(isDeletePostOpen) && (
        <DeleteCommunityPostDialog
          open={Boolean(isDeletePostOpen)}
          onClose={handleCloseDeletePost}
          postToDelete={isDeletePostOpen}
          handleDeletePost={handleDeletePost}
          handleArchievePost={handleArchievePost}
        />
      )}
      {!!postSignsOpen && (
        <PostSignsDialog
          open={Boolean(postSignsOpen)}
          post={postSignsOpen}
          onClose={handleClosePostSignaturesList}
        />
      )}
      {Boolean(isChooseVisibileForDialogOpen) && (
        <ChooseVisibileForDialog
          open={Boolean(isChooseVisibileForDialogOpen)}
          onClose={handleCloseChooseKnowledgeTestVisibileForDialog}
          post={isChooseVisibileForDialogOpen}
        />
      )}
      {Boolean(isOpenChooseResponsibleDialog) && (
        <ChoosePostResponsiblesDialog
          open={Boolean(isOpenChooseResponsibleDialog)}
          posts={posts}
          setPosts={setPosts}
          post={isOpenChooseResponsibleDialog}
          onClose={handleCloseChooseManagementResponsible}
        />
      )}
    </>
  );
};

export default CommunityPostsList;
