import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import getGlobalCoursewares from './lazyGlobalCoursewares';

const GlobalCoursewaresContext = createContext();

export const GlobalCoursewaresProvider = ({ children }) => {
  GlobalCoursewaresProvider.propTypes = {
    children: PropTypes.any
  };
  const [globalCoursewares, setGlobalCoursewares] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getGlobalCoursewares()
      .then((slides) => {
        if (slides && Array.isArray(slides)) {
          setGlobalCoursewares(slides);
        } else {
          console.error('Invalid coursewares data:', slides);
          setGlobalCoursewares([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error loading coursewares:', error);
        setGlobalCoursewares([]);
        setIsLoading(false);
      });
  }, []);

  return (
    <GlobalCoursewaresContext.Provider
      value={{ globalCoursewares, setGlobalCoursewares, isLoading }}
    >
      {children}
    </GlobalCoursewaresContext.Provider>
  );
};

export const useGlobalCoursewares = () => useContext(GlobalCoursewaresContext);
