import {
  Button,
  Dialog,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'src/_firebase/firebase';
import theme from 'src/theme';
import retrieveReports from 'src/utils/retrieveReports';

const OrgReportsList = ({ orgId }) => {
  OrgReportsList.propTypes = {
    orgId: PropTypes.string
  };
  const { t } = useTranslation('translation', { keyPrefix: 'orgPage' });
  const [orgReports, setOrgReports] = useState();
  const [isReportOpened, setIsReportOpened] = useState({ state: false });

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (orgId) {
          retrieveReports(orgId)
            .then((reports) => {
              setOrgReports(reports);
              //   console.log(reports);
            })
            .catch((err) => {
              console.log(err);
              setOrgReports([]);
            });
        }
      } catch (error) {
        console.log('Error fetching reports', error);
      }
    };
    fetchReports();
  }, [orgId]);

  const handleShowReport = async (reportPath) => {
    // console.log(reportId);
    const storageRef = ref(storage, reportPath);
    getDownloadURL(storageRef)
      .then((url) => {
        // console.log(url);
        setIsReportOpened({ state: true, url });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {orgReports && orgReports.length === 0 ? (
        <p>הארגון טרם שיתף דוחות בקרה</p>
      ) : orgReports && orgReports.length > 0 ? (
        <List sx={{ maxHeight: '80vh', overflow: 'auto', width: '100%' }}>
          {orgReports
            .filter((el) => el.published === 'visible')
            .map((report) => {
              const isVisibile = report.published === 'visible';
              return (
                <ListItem
                  key={`org-reports-${uniqueId()}`}
                  sx={{
                    borderLeft: isVisibile
                      ? `5px solid ${theme.palette.success.light}`
                      : `5px solid ${theme.palette.warning.light}`,
                    borderRight: isVisibile
                      ? `5px solid ${theme.palette.success.light}`
                      : `5px solid ${theme.palette.warning.light}`,
                    my: 1,
                    boxShadow: theme.shadows[6]
                  }}
                >
                  <ListItemText
                    primary={report.name}
                    secondary={`תאריך הדוח: ${moment(
                      report.issued_at.seconds * 1000
                    ).format('MM/YYYY')}`}
                    slotProps={{
                      primary: {
                        color: isVisibile
                          ? theme.palette.success.main
                          : theme.palette.error.main
                      },

                      secondary: {
                        sx: {
                          mt: 2
                        }
                      }
                    }}
                  />
                  <ListItemSecondaryAction>
                    <Button
                      onClick={() => handleShowReport(report.full_path)}
                      variant="outlined"
                    >
                      {t('examine')}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      ) : (
        <LinearProgress />
      )}
      <Dialog
        onClose={() => setIsReportOpened({ state: false })}
        maxWidth="lg"
        fullWidth
        open={isReportOpened.state && Boolean(isReportOpened.url)}
        sx={{ height: '100%' }}
        PaperProps={{
          sx: {
            height: '100%'
          }
        }}
      >
        <iframe
          style={{
            height: '100%'
          }}
          src={isReportOpened.url}
          allowFullScreen
          title="דוח בקרה"
        />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIsReportOpened({ state: false })}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default OrgReportsList;
