import {
  ExpandLessOutlined,
  ExpandMoreOutlined,
  QuestionAnswerOutlined,
  QuestionMarkOutlined
} from '@mui/icons-material';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import orgQuestions from 'src/common/orgQuestions';

const CustomQuestionsAnswers = ({
  expandedArea,
  expandAnswerArea,
  customFaq
}) => {
  CustomQuestionsAnswers.propTypes = {
    expandedArea: PropTypes.object,
    expandAnswerArea: PropTypes.func,
    customFaq: PropTypes.array
  };
  return (
    <>
      {customFaq &&
        customFaq.map((customQ) => {
          const isExpanded =
            expandedArea.state && expandedArea.parent === customQ.name;
          const selectedLang = localStorage.getItem('lang');
          const isEnglish = selectedLang === 'en';
          let answer;
          let question;
          if (isEnglish) {
            answer = customQ.en_answer || '';
            question = customQ.en_question || '';
          } else {
            answer = customQ.answer || '';
            question = customQ.question || '';
          }
          return (
            <>
              <ListItem
                sx={{
                  bgcolor: (theme) =>
                    isExpanded && alpha(theme.palette.action.focus, 0.2)
                }}
                key={question.id}
              >
                <ListItemIcon>
                  <QuestionMarkOutlined
                    color={isExpanded ? 'primary' : 'warning'}
                    sx={{ transition: 'all 0.25s' }}
                  />
                </ListItemIcon>
                <ListItemButton
                  disableRipple
                  onClick={() => expandAnswerArea(customQ.name)}
                >
                  <ListItemText primary={question} />

                  <IconButton>
                    {isExpanded ? (
                      <ExpandLessOutlined />
                    ) : (
                      <ExpandMoreOutlined />
                    )}
                  </IconButton>
                </ListItemButton>
              </ListItem>
              <Collapse
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                in={isExpanded}
              >
                <ListItem>
                  <ListItemIcon sx={{ pl: 2 }}>
                    <QuestionAnswerOutlined
                      color={isExpanded ? 'primary' : 'warning'}
                      sx={{ transition: 'all 0.25s' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={answer}
                    slotProps={{
                      primary: {
                        sx: {
                          textAlign: 'justify'
                        }
                      }
                    }}
                  />
                </ListItem>
              </Collapse>
              <Divider />
            </>
          );
        })}
    </>
  );
};

const AnsweredFaq = ({ orgPageValues }) => {
  AnsweredFaq.propTypes = {
    orgPageValues: PropTypes.object
  };
  const [answers, setAnswers] = useState([]);
  const [expandedArea, setExpandedArea] = useState({ state: false });
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgPage'
  });
  useEffect(() => {
    if (
      orgPageValues &&
      orgPageValues.answered_faq &&
      orgPageValues.answered_faq.length > 0
    ) {
      const temp = [];
      const lang = localStorage.getItem('lang');
      if (lang === 'en') {
        for (
          let index = 0;
          index < orgPageValues.answered_faq.length;
          index++
        ) {
          const answeredQ = orgPageValues.answered_faq[index];
          if (answeredQ.en_answer && answeredQ.en_answer.trim() !== '') {
            // console.log(answeredQ);
            temp.push(answeredQ);
          } else {
            temp.push();
          }
        }
      } else {
        for (
          let index = 0;
          index < orgPageValues.answered_faq.length;
          index++
        ) {
          const answeredQ = orgPageValues.answered_faq[index];
          if (answeredQ.answer.trim() !== '') {
            temp.push(answeredQ);
          }
        }
      }
      setAnswers(temp);
    }
  }, [orgPageValues]);
  const expandAnswerArea = (name) => {
    // console.log(name);
    if (
      expandedArea.state &&
      expandedArea.parent &&
      expandedArea.parent === name
    ) {
      setExpandedArea({ state: false });
    } else {
      setExpandedArea({ state: true, parent: name });
    }
  };

  const getAnswerFromCurrentUser = (name) => {
    if (orgPageValues.answered_faq) {
      const index = orgPageValues.answered_faq.findIndex(
        (el) => el.name === name
      );
      if (index > -1) {
        if (localStorage.getItem('lang') === 'en') {
          return orgPageValues.answered_faq[index].en_answer;
        }
        return orgPageValues.answered_faq[index].answer;
      }
      return '';
    }
    return '';
  };
  const getQuestionLabel = (name) => {
    if (orgPageValues.answered_faq) {
      const index = orgQuestions.findIndex((el) => el.name === name);
      if (index > -1) {
        return orgQuestions[index].label;
      }
      return '';
    }
    return '';
  };
  return (
    <List>
      {answers &&
        answers.length > 0 &&
        answers
          .filter((filterNotCustom) => !filterNotCustom.custom)
          .map((question) => {
            const isExpanded =
              expandedArea.state && expandedArea.parent === question.name;
            return (
              <Fragment key={`${uniqueId('faq')}-${question.id}`}>
                <ListItem
                  sx={{
                    bgcolor: (theme) =>
                      isExpanded && alpha(theme.palette.primary.light, 0.1),
                    transition: 'all .2s'
                  }}
                  key={question.id}
                >
                  <ListItemIcon>
                    <QuestionMarkOutlined
                      color={isExpanded ? 'primary' : 'warning'}
                      sx={{ transition: 'all 0.25s' }}
                    />
                  </ListItemIcon>
                  <ListItemButton
                    disableRipple
                    onClick={() => expandAnswerArea(question.name)}
                  >
                    <ListItemText primary={getQuestionLabel(question.name)} />

                    <IconButton>
                      {isExpanded ? (
                        <ExpandLessOutlined />
                      ) : (
                        <ExpandMoreOutlined />
                      )}
                    </IconButton>
                  </ListItemButton>
                </ListItem>
                <Collapse
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  in={isExpanded}
                >
                  <ListItem>
                    <ListItemIcon sx={{ pl: 2 }}>
                      <QuestionAnswerOutlined
                        color={isExpanded ? 'primary' : 'warning'}
                        sx={{ transition: 'all 0.25s' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={getAnswerFromCurrentUser(question.name)}
                      slotProps={{
                        primary: {
                          sx: {
                            textAlign: 'justify'
                          }
                        }
                      }}
                    />
                  </ListItem>
                </Collapse>
                <Divider />
              </Fragment>
            );
          })}
      <CustomQuestionsAnswers
        key="custom-faq-in-org-page"
        customFaq={answers.filter((el) => el.custom).filter((el) => !el.hidden)}
        expandAnswerArea={expandAnswerArea}
        expandedArea={expandedArea}
      />
    </List>
  );
};

export default AnsweredFaq;
