import {
  CallOutlined,
  CorporateFareOutlined,
  DirectionsOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  ShareOutlined
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropType from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { RWebShare } from 'react-web-share';
import mgdalor from 'src/assets/mgdalor.png';
import routesConst from 'src/routesConst';
import calcNumberOfBedsInOrg from 'src/utils/calcNumberOfBedsInOrg';
import calcOccupancyPercent from 'src/utils/calcOccupancyPercent';
import getCityAndDistrictOfOption from 'src/utils/getCityAndDistrictOfOption';
import CustomizedSnackBar from '../snackbar/CustomizedSnackbar';

const ResultListItem = ({
  home,
  checkedToCompare,
  handleSelectHome,
  selectedToExamine,
  setSelectedToExamine,
  isResultCollapseOpen,
  setResultCollapseOpen,
  index,
  handleOpenDirectionsDialog
}) => {
  ResultListItem.propTypes = {
    home: PropType.object,
    checkedToCompare: PropType.array,
    handleSelectHome: PropType.func,
    selectedToExamine: PropType.object,
    setSelectedToExamine: PropType.func,
    isResultCollapseOpen: PropType.object,
    setResultCollapseOpen: PropType.func,
    handleOpenDirectionsDialog: PropType.func,
    index: PropType.number
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const snackbarInitState = {
    msg: '',
    open: false,
    severity: 'success'
  };

  const [snackbarState, setSnackbarState] = useState(snackbarInitState);
  const [localizedAddress, setLocalizedAddress] = useState('');
  // const [shareSuccess, setShareSuccess] = useState();
  const matchLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation('translation', { keyPrefix: 'searchResults' });

  const handleNavigateToOrgPage = () => {
    // console.log(home);
    if (home && home.type === 'org' && home.user_doc_id) {
      // good, navigate
      navigate(`/${routesConst.org}/${home.org_name}&uid=${home.user_doc_id}`, {
        state: {
          org_path: `users/${home.user_doc_id}`,
          ...home
        }
      });
    } else {
      // no path was found, error
      setSnackbarState({
        ...snackbarState,
        msg: t('errorOpeningOrg'),
        severity: 'error',
        open: true
      });
    }
  };

  const handleGetAdderss = async () => {
    getCityAndDistrictOfOption(home)
      .then((result) => setLocalizedAddress(result))
      .catch((err) => {
        console.log(err);
        setLocalizedAddress('');
      });
  };

  const handleOrgWasSelectedToExamine = (selectedHome) => {
    setSelectedToExamine(selectedHome);
    if (
      isResultCollapseOpen.state &&
      isResultCollapseOpen.parent === selectedHome.user_doc_id
    ) {
      setResultCollapseOpen({ state: false });
    } else {
      setResultCollapseOpen({ state: true, parent: selectedHome.user_doc_id });
    }
  };

  const getDistanceInKm = (algoliaRes) => {
    const {
      _rankingInfo: {
        matchedGeoLocation: { distance }
      }
    } = algoliaRes;
    return distance / 1000;
  };

  const checkAvailablityInOrgBed = () => {
    if (home) {
      const totalNumOfBedsInOrg = calcNumberOfBedsInOrg(
        home.number_of_beds_in_org
      );
      const { total_vacant } = calcOccupancyPercent(
        totalNumOfBedsInOrg,
        home.number_of_beds_in_org
      );
      // console.log(total_vacant);
      return total_vacant;
    }
    return 0;
  };
  const closeSnackBar = () => {
    setSnackbarState(snackbarInitState);
  };

  useEffect(() => {
    if (home) {
      handleGetAdderss();
    }
  }, [home]);
  return (
    <Stack sx={{ px: 2, flexDirection: 'column', alignItems: 'center' }}>
      <ListItem
        sx={{
          py: 0,
          pr: 0,
          display: 'flex',
          flexDirection: matchLgDown ? 'column' : 'row',
          justifyContent: 'space-between',
          width: '100%',
          borderRight:
            selectedToExamine &&
            selectedToExamine.user_doc_id === home.user_doc_id &&
            `3px groove ${theme.palette.primary.light}`,
          backgroundColor:
            selectedToExamine &&
            selectedToExamine.user_doc_id === home.user_doc_id &&
            'rgba(0,0,0,0.07)',
          transition: 'borderRight 0.1s linear',
          borderBottom: `1px solid ${theme.palette.divider}`
        }}
        key={home.user_doc_id}
      >
        <Stack
          sx={{ flex: 1, width: '100%', alignItems: 'center' }}
          direction="row"
        >
          <Tooltip arrow title={t('checkToCompare')}>
            <Checkbox
              edge="end"
              name={`checkbox-${home.org_name}`}
              checked={
                checkedToCompare.findIndex(
                  (el) => el.user_doc_id === home.user_doc_id
                ) !== -1
              }
              tabIndex={index}
              sx={{
                mr: 1,
                height: 'fit-content',
                width: 'fit-content'
              }}
              disableRipple
              onClick={() => handleSelectHome(home)}
            />
          </Tooltip>
          <ListItemButton
            sx={{ flex: 1, width: 'fit-content', minWidth: 200 }}
            onClick={() => {
              handleOrgWasSelectedToExamine(home);
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{ width: 42, boxShadow: theme.shadows[4] }}
                src={(home && home.avatar) || mgdalor}
                slotProps={{
                  img: {
                    loading: 'lazy'
                  }
                }}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ flex: 1 }}
              primary={home.org_name}
              secondary={localizedAddress}
              slotProps={{
                secondary: {
                  sx: {
                    fontSize: '90%',
                    maxWidth: '20vw'
                  }
                }
              }}
            />
          </ListItemButton>
        </Stack>
        {/* <ListItemSecondaryAction sx={{ width: 'fit-content' }}> */}
        <Stack
          direction="row"
          sx={{
            gap: 2,
            justifyContent: 'flex-end',
            width: 'fit-content',
            mb: 1
          }}
        >
          {/* <StyledListItemSecondaryAction> */}
          {/* </StyledListItemSecondaryAction> */}
          <Button
            onClick={() => {
              handleOrgWasSelectedToExamine(home);
            }}
            endIcon={
              isResultCollapseOpen.state &&
              isResultCollapseOpen.parent === home.user_doc_id ? (
                <ExpandLessOutlined />
              ) : (
                <ExpandMoreOutlined />
              )
            }
          >
            לבדיקת זמינות
          </Button>
          <Tooltip arrow title={t('clickToExamineOrg')}>
            <Button
              // onClick={() => {
              //   handleNavigateToOrgPage();
              // }}
              sx={{
                // fontSize: '60%',
                width: '15vw',
                maxWidth: 'fit-content',
                display: 'flex',
                justifySelf: 'flex-end',
                fontSize: '15px'
              }}
              variant="outlined"
              LinkComponent="a"
              target="_blank"
              href={`/${routesConst.org}/${home.id}`}
              endIcon={<CorporateFareOutlined />}
            >
              {t('seeOrgPage')}
              {/* <ChevronLeft /> */}
            </Button>
          </Tooltip>
        </Stack>
        {/* </ListItemSecondaryAction> */}
      </ListItem>
      <Collapse
        in={
          isResultCollapseOpen.state &&
          isResultCollapseOpen.parent === home.user_doc_id
        }
        sx={{
          width: '100%',
          margin: 'auto',
          mb:
            isResultCollapseOpen.state &&
            isResultCollapseOpen.parent === home.user_doc_id &&
            2
        }}
      >
        <Stack
          sx={{ paddingInlineStart: '60px', pt: 2, width: '100%' }}
          direction={{
            xs: 'column',
            md: 'row'
          }}
          justifyContent="space-between"
        >
          <Stack gap={1}>
            <Tooltip title="המרחק הוא אווירי">
              <Chip
                label={`${getDistanceInKm(home).toFixed(1)}
                    ${t('km')} מהחיפוש שלך`}
                variant="outlined"
                sx={{
                  fontSize: '0.9rem',
                  maxWidth: 'fit-content'
                }}
                color={
                  JSON.parse(localStorage.getItem('search')) &&
                  JSON.parse(localStorage.getItem('search')).length > 0 &&
                  JSON.parse(localStorage.getItem('search'))[0]
                    .distanceRadius &&
                  home._rankingInfo.matchedGeoLocation.distance / 1000 <
                    JSON.parse(localStorage.getItem('search'))[0].distanceRadius
                    ? 'success'
                    : 'warning'
                }
              />
            </Tooltip>
            {checkAvailablityInOrgBed() === 0 ? (
              <Alert
                severity="error"
                sx={{ width: 'fit-content', minWidth: '250px' }}
              >
                מקום זה נמצא בתפוסה מלאה
              </Alert>
            ) : checkAvailablityInOrgBed() > 0 &&
              checkAvailablityInOrgBed() <= 5 ? (
              <Alert
                severity="warning"
                sx={{ width: 'fit-content', minWidth: '250px' }}
              >
                {`${checkAvailablityInOrgBed()} מקומות אחרונים בארגון, כדאי למהר ולצור קשר`}
              </Alert>
            ) : (
              <Alert
                severity="success"
                sx={{ width: 'fit-content', minWidth: '250px' }}
              >
                יש מקומות בארגון
              </Alert>
            )}
          </Stack>
          <Stack
            direction={{ xs: 'row', md: 'column' }}
            justifyContent="center"
            gap={1}
          >
            <Button LinkComponent="a" href={`tel:${home.internal_phone}`}>
              <Typography variant="span">{home.internal_phone}</Typography>
              <CallOutlined />
            </Button>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              {process.env.REACT_APP_DEV === 'true' && (
                <Tooltip title="הוראות הגעה">
                  <IconButton
                    onClick={() =>
                      handleOpenDirectionsDialog(home, localizedAddress)
                    }
                  >
                    <DirectionsOutlined />
                  </IconButton>
                </Tooltip>
              )}
              <RWebShare
                data={{
                  text: home.description,
                  url:
                    process.env.REACT_APP_DEV === 'true'
                      ? `http://localhost:3000/${routesConst.org}/${home.id}`
                      : `https://www.mgdalor.com/${routesConst.org}/${home.id}`,
                  title: home.org_name
                }}
                closeText={t('close')}
                onClick={() => {
                  if (matchSmDown) {
                    setSnackbarState({
                      ...snackbarState,
                      msg: t('shareSuccess'),
                      open: true,
                      severity: 'success'
                    });
                  }
                }}
                key={`share-${home.org_name}`}
              >
                <Tooltip title="שיתוף הארגון">
                  <IconButton sx={{ width: 'fit-content' }}>
                    {/* <Typography variant="span">שיתוף ארגון</Typography> */}
                    <ShareOutlined />
                  </IconButton>
                </Tooltip>
              </RWebShare>
            </Stack>
          </Stack>
          <Divider sx={{ mt: 1 }} />
        </Stack>
      </Collapse>
      <CustomizedSnackBar
        msg={snackbarState.msg}
        open={snackbarState.open}
        severity={snackbarState.severity}
        setOpen={closeSnackBar}
        key="upload-avatar-snackbar"
      />
    </Stack>
  );
};

export default ResultListItem;
