import { GeoPoint } from '@firebase/firestore';
import { InfoOutlined } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import available_funds from 'src/common/availableFunds';
import orgsSizes from 'src/common/orgsSizes';
import routesConst from 'src/routesConst';
import getCityAndDistrictOfOption from 'src/utils/getCityAndDistrictOfOption';
import getMomentDateValue from 'src/utils/getMomentDateValue';
import PickAddressDialog from './PickAddressDialog';

const SecondStep = ({
  values,
  setValues,
  setActiveView,
  handleBlur,
  handleChange,
  errors,
  touched,
  handleOpenVerifyCodeDialog,
  minimalMapAutoCompleteOpen,
  setMinimalMapAutoCompleteOpen
}) => {
  SecondStep.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func,
    setActiveView: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleOpenVerifyCodeDialog: PropTypes.func,
    minimalMapAutoCompleteOpen: PropTypes.bool,
    setMinimalMapAutoCompleteOpen: PropTypes.func
  };
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const [addressToBeViewed, setAddressToBeViewed] = useState('');

  const [loadingToRegister, setLoadingToRegister] = useState(false);
  const handleOpenMinimalMap = () => {
    setMinimalMapAutoCompleteOpen(true);
  };

  const handleClosePickAddressDialog = () => {
    setMinimalMapAutoCompleteOpen(false);
  };

  const handleAddressPinChanged = (lnglat) => {
    const hash = new GeoPoint(lnglat.lat, lnglat.lng);
    setValues({ ...values, _geoloc: hash });
  };

  const handleReverseGeocodeCoordinates = async () => {
    if (values._geoloc && values._geoloc._lat && values._geoloc._long) {
      return getCityAndDistrictOfOption({
        _geoloc: { lat: values._geoloc._lat, lng: values._geoloc._long }
      })
        .then((val) => {
          setAddressToBeViewed(val);
          return val;
        })
        .catch((err) => {
          console.log(err);
          setAddressToBeViewed('');
          return '';
        });
    }
    setAddressToBeViewed('');
    return '';
  };

  useEffect(() => {
    if (values._geoloc && values._geoloc._lat && values._geoloc._long) {
      handleReverseGeocodeCoordinates();
    }
  }, [values]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel id="select-numberOfWorkers">
              {t('numberOfWorkers')}
            </InputLabel>
            <Select
              labelId="select-numberOfWorkers"
              id="select-numberOfWorkers"
              value={values.number_of_workers}
              label={t('numberOfWorkers')}
              fullWidth
              name="number_of_workers"
              onChange={handleChange}
              error={Boolean(
                touched.number_of_workers && errors.number_of_workers
              )}
            >
              {orgsSizes.map((tag) => (
                <MenuItem key={tag.name} value={tag.name}>
                  {tag.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={6}>
          <LocalizationProvider
            localeText={{
              okButtonLabel: 'אישור',
              cancelButtonLabel: 'ביטול'
            }}
            dateAdapter={AdapterMoment}
          >
            <MobileDatePicker
              showToolbar={false}
              views={['year', 'month']}
              label={t('dateOfEstablishment')}
              // value={moment(values.date_of_establishment).format()}
              value={getMomentDateValue(values?.date_of_establishment)}
              disableFuture
              name="date_of_establishment"
              onChange={(val) => {
                // console.log(values.dateOfEstablishment);
                setValues({
                  ...values,
                  date_of_establishment: moment(val).toDate()
                });
              }}
              inputFormat="MM/yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
              error={Boolean(
                touched.date_of_establishment && errors.date_of_establishment
              )}
              helperText={
                touched.date_of_establishment && errors.date_of_establishment
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            {/* new  */}
            <InputLabel id="demo-simple-select-label">
              {t('availableFundsSelect')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.available_funds}
              label={t('availableFundsSelect')}
              fullWidth
              name="available_funds"
              onChange={(e) => {
                setValues({
                  ...values,
                  available_funds:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value
                });
              }}
              error={Boolean(touched.available_funds && errors.available_funds)}
              multiple
              renderValue={(selected) => {
                const newSelected = selected.map((tag) => {
                  const index = available_funds.findIndex(
                    (el) => el.name === tag
                  );
                  return available_funds[index].label;
                });
                return newSelected.join(',');
              }}
            >
              {available_funds.map((fund) => (
                <MenuItem key={fund.name} value={fund.name}>
                  <Checkbox
                    checked={values.available_funds.indexOf(fund.name) > -1}
                  />
                  <ListItemText primary={fund.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="מצא את הכתובת שלך"
            placeholder="לדוגמא: שם רחוב, מס בית, עיר"
            fullWidth
            onClick={() => handleOpenMinimalMap()}
            value={addressToBeViewed}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            error={
              Boolean(touched.description && errors.description) ||
              (touched.description &&
                values.description &&
                values.description.trim().length < 20)
            }
            helperText={
              (touched.description && errors.description) ||
              (touched.description &&
                values.description &&
                values.description.trim().length < 20)
            }
            fullWidth
            multiline
            minRows={4}
            label={t('description')}
            sx={{ mt: 0 }}
            margin="normal"
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            variant="outlined"
            slotProps={{
              input: {
                endAdornment: (
                  <Tooltip title={t('descriptionTooltip')}>
                    <InfoOutlined />
                  </Tooltip>
                )
              }
            }}
          />
        </Grid>
        <Grid
          sx={{ display: 'flex', justifyContent: 'center' }}
          item
          xs={12}
          md={12}
        >
          <Button
            color="primary"
            fullWidth
            disabled={
              !values.description ||
              values.description.trim().length < 20 ||
              Object.keys(errors).length > 0
            }
            size="large"
            type="button"
            onClick={() => handleOpenVerifyCodeDialog(values)}
            variant="contained"
            sx={{ width: '50%' }}
          >
            {t('registerNow')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body1">
            {t('haveAnAccount')}
            &nbsp;
            <Link
              component={RouterLink}
              to={`/${routesConst.login}`}
              variant="h6"
            >
              {t('signIn')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {loadingToRegister && (
        <Backdrop open={loadingToRegister}>
          <CircularProgress />
        </Backdrop>
      )}
      {minimalMapAutoCompleteOpen && (
        <PickAddressDialog
          onClose={handleClosePickAddressDialog}
          open={minimalMapAutoCompleteOpen}
          handleAddressPinChanged={handleAddressPinChanged}
          defaultLocation={
            values._geoloc &&
            values._geoloc._lat &&
            values._geoloc._long && {
              lng: values._geoloc._long,
              lat: values._geoloc._lat
            }
          }
        />
      )}
    </>
  );
};

export default SecondStep;
