import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const enVar = process.env;
const firebaseConfig = {
  apiKey: enVar.REACT_APP_APIKEY,
  authDomain: enVar.REACT_APP_AUTHDOMAIN,
  projectId: enVar.REACT_APP_PROJECTID,
  storageBucket: enVar.REACT_APP_STORAGEBUCKET,
  messagingSenderId: enVar.REACT_APP_MESSAGINGSENDERID,
  appId: enVar.REACT_APP_APPID,
  measurementId: enVar.REACT_APP_MEASURMENTID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app, enVar.REACT_APP_STORAGEBUCKET);
const functions = getFunctions(app);
// console.log(db);
// connectFunctionsEmulator(functions, 'localhost', 5001);

export { auth, db, functions, storage };
