import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from '@firebase/firestore';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { db, storage } from 'src/_firebase/firebase';
import searchNotFound from 'src/assets/lottie/search-not-found.json';
import mgdalorFull from 'src/assets/mgdalorFull.webp';
import OrgPageLoader from 'src/components/OrgPage/OrgPageLoader';
import OrgProfile from 'src/components/OrgPage/Profile';
import routesConst from 'src/routesConst';
import handleGTag from 'src/utils/handleGTag';

function OrgPage() {
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(true);
  const [orgPageValues, setOrgPageValues] = useState();
  // const [orgAvatar, setOrgAvatar] = useState(mgdalorFull)
  const [orgExists, setOrgExists] = useState();
  const location = useLocation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgPage'
  });
  const navigate = useNavigate();

  const getOrgPicturesFromStorage = async (bucketRef, orgValuesWithoutImages) =>
    listAll(bucketRef)
      .then((list) => {
        if (list.items.length > 0) {
          const itemsUrls = list.items.map(async (image) => {
            const metadata = await getMetadata(image);
            const { customMetadata } = metadata;
            // console.log(metadata);
            const itemUrl = await getDownloadURL(image);
            return {
              url: itemUrl,
              name: image.name,
              metadata: customMetadata || {}
            };
          });
          return itemsUrls;
        }
        return [];
      })
      .then(async (urls) =>
        Promise.all(urls)
          .then((res) => ({ ...orgValuesWithoutImages, org_pictures: res }))
          .catch((err) => console.log('err while promise.all', err))
      )
      .catch((err) => console.log('catch list all', err))
      .finally(() => setLoading(false));

  const handleRetriveOrgDetails = async (orgPath) => {
    setLoading(true);
    const orgRef = doc(db, orgPath);
    console.log(orgPath);
    return getDoc(orgRef)
      .then(async (org) => {
        if (org.exists()) {
          const data = org.data();
          const { org_status } = data;
          if (org_status === 'pending' || org_status === 'freezed') {
            setOrgExists(false);
            setOrgPageValues(false);
            setLoading(false);
          } else {
            const storageRef = ref(storage, `orgs/${org.id}/orgPictures`);
            const orgValuesWithImages = await getOrgPicturesFromStorage(
              storageRef,
              { ...data }
            ).catch((err) => console.log('getorgpicturesfromstorage', err));
            setOrgExists(true);
            return orgValuesWithImages;
          }
        }
        setLoading(false);
        setOrgExists(false);
        // org does not exist
      })
      .catch((err) => console.log(err));
    // can access the orgpagevalues which contains every thing i need about the org
  };

  const getOrgRefById = async (org_id) => {
    const docRef = collection(db, 'users');
    const q = query(docRef, where('id', '==', org_id));
    return getDocs(q)
      .then((res) => {
        if (res.empty) {
          return null;
        }
        return res.docs[0].id;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        if (location.pathname) {
          const uid = location.pathname.split('&uid=')[1];
          // console.log(location.pathname, uid);
          if (uid) {
            handleRetriveOrgDetails(`users/${uid}`)
              .then((values) => {
                setLoading(false);
                setOrgPageValues(values);
              })
              .catch((err) => console.log(err));
          } else {
            // this is where the id is in the path and not the uid
            const org_id = location.pathname.split('/')[2];
            const org_ref = await getOrgRefById(org_id);
            if (org_ref) {
              handleRetriveOrgDetails(`users/${org_ref}`)
                .then((values) => {
                  setLoading(false);
                  setOrgPageValues(values);
                })
                .catch((err) => console.log(err));
            }
          }
        }
      } catch (error) {
        console.log('error fetching org details', error);
      }
    };
    fetchOrgDetails();
  }, [location.pathname]);
  // console.log(orgPageValues);
  const handleGoBack = () => {
    navigate(`/${routesConst.results}`);
  };

  return (
    <>
      <Helmet>
        <title>
          {`${
            orgPageValues && orgPageValues.org_name
              ? orgPageValues.org_name
              : ''
          } - ${t('orgPage')}`}
        </title>
        <meta
          name="description"
          content="מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta
          property="og:image"
          content={orgPageValues ? orgPageValues.avatar : mgdalorFull}
        />
        <meta
          name="title"
          content="מגדלור - מנוע חיפוש בתי האבות המוביל בישראל"
        />
        <meta
          name="description"
          content={orgPageValues && orgPageValues.description}
        />
        <meta
          name="og:title"
          content={`${
            orgPageValues && orgPageValues.org_name
              ? orgPageValues.org_name
              : ''
          } - ${t('orgPage')}`}
        />
        <meta
          name="og:description"
          content={orgPageValues && orgPageValues.description}
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="1 days" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
        {/* <link rel="canonical" href="https://www.mgdalor.com/org/:id" /> */}
      </Helmet>
      {!loading && orgPageValues && orgExists ? (
        <OrgProfile handleGoBack={handleGoBack} orgPageValues={orgPageValues} />
      ) : !loading && !orgPageValues && !orgExists ? (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', pb: 4 }}>
          <Lottie
            style={{
              width: matchDownSm ? '40vw !important' : '25vw !important',
              height: '20vw',
              minWidth: '150px !important'
            }}
            autoplay
            animationData={searchNotFound}
            loop
          />
          <Typography variant="h4" fontSize="1.1rem" fontWeight={600}>
            {t('sorryForThat')}
          </Typography>
          <Typography
            variant="h4"
            fontSize="1.1em"
            sx={{ pb: 6 }}
            fontWeight={600}
          >
            {t('nothingWasFoundHere')}
          </Typography>
        </Stack>
      ) : (
        <OrgPageLoader active={loading && !orgPageValues} />
      )}
      {/* {!loading && orgPageValues && (
          )} */}
      {/* </Card> */}
      {/* </Box> */}
    </>
  );
}

export default OrgPage;
