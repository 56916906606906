import {
  DeleteForeverOutlined,
  PictureAsPdfOutlined
} from '@mui/icons-material';
import {
  Alert,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import PropTypes from 'prop-types';
import theme from 'src/theme';
import bytesToMB from 'src/utils/bytesToMb';

const AttachedFileList = ({
  attachedFiles,
  handleRemoveAttachedFile,
  setAttachedFiles,
  setNewPostValues,
  newPostValues,
  deleteIconVisible = true,
  infoVisible = true,
  contentEditable = true,
  secondaryPhrase
}) => {
  AttachedFileList.propTypes = {
    attachedFiles: PropTypes.object,
    handleRemoveAttachedFile: PropTypes.func,
    setNewPostValues: PropTypes.func,
    newPostValues: PropTypes.object,
    setAttachedFiles: PropTypes.func,
    deleteIconVisible: PropTypes.bool,
    infoVisible: PropTypes.bool,
    contentEditable: PropTypes.bool,
    secondaryPhrase: PropTypes.any
  };
  return (
    <>
      {attachedFiles &&
        attachedFiles.type &&
        (attachedFiles.type.split('/')[0] === 'image' ||
          attachedFiles.type === 'application/pdf') && (
          <List
            component={Card}
            sx={{
              maxWidth: theme.breakpoints.values.md
            }}
            disablePadding
            elevation={infoVisible ? 4 : 0}
          >
            <CardHeader
              title={infoVisible && 'קובץ מצורף:'}
              sx={{
                py: 0
              }}
              slotProps={{
                title: {
                  sx: {
                    fontSize: '16px',
                    color: theme.palette.action.active
                  }
                }
              }}
            />
            <ListItem
              sx={{
                pt: '0px !important'
              }}
              disablePadding
              secondaryAction={
                deleteIconVisible && (
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveAttachedFile()}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                )
              }
            >
              <ListItemIcon
                sx={{
                  width: 'fit-content',
                  height: 90,
                  alignItems: 'center',
                  maxWidth: 100
                }}
              >
                {attachedFiles.type.split('/')[0] === 'image' && (
                  <img
                    alt={attachedFiles.name}
                    src={
                      attachedFiles &&
                      attachedFiles.preview &&
                      attachedFiles.preview
                    }
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                )}
                {attachedFiles.type === 'application/pdf' && (
                  <PictureAsPdfOutlined
                    sx={{
                      fill: lighten(theme.palette.error.light, 0.5)
                    }}
                    fontSize="large"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  newPostValues
                    ? newPostValues.file
                      ? newPostValues.file.file_name || newPostValues.file.name
                      : newPostValues.file_name
                    : attachedFiles.file
                    ? attachedFiles.file.name
                    : attachedFiles.name
                }
                secondary={
                  <>
                    {secondaryPhrase ? (
                      <Typography
                        variant="inherit"
                        color={theme.palette.success.main}
                        fontSize="0.825rem"
                        fontWeight={400}
                        component="span"
                      >
                        {secondaryPhrase}
                      </Typography>
                    ) : attachedFiles && attachedFiles.size ? (
                      bytesToMB(attachedFiles.size)
                    ) : null}
                  </>
                }
                slotProps={{
                  primary: {
                    fontWeight: 600,
                    contentEditable: Boolean(contentEditable),
                    suppressContentEditableWarning: Boolean(contentEditable),
                    onBlur: (e) => {
                      if (contentEditable) {
                        setNewPostValues({
                          ...newPostValues,
                          file_name: e.target.innerText
                        });
                      }
                    }
                  }
                }}
              />
            </ListItem>
            {infoVisible && (
              <CardActions sx={{ px: 2, pt: 0 }}>
                <Alert severity="info" sx={{ width: '100%' }}>
                  לחץ על שם הקובץ כדי לשנות את השם שיופיע לצופים
                </Alert>
              </CardActions>
            )}
          </List>
        )}
    </>
  );
};
export default AttachedFileList;
