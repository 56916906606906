import { Box, Icon, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';

const DefaultInfoCard = ({
  color = 'info',
  icon,
  title,
  description,
  direction = 'left',
  small = false
}) => (
  <Box
    lineHeight={1}
    // p={direction === 'center' ? 2 : 0}
    textAlign={direction}
    sx={({ shadows, palette }) => ({
      '&:hover': {
        boxShadow: shadows[12],
        transition: 'all 1s',
        px: 2,
        bgcolor: alpha(palette.grey[400], 0.5)
      },
      height: 190,
      p: 1,
      boxShadow: shadows[8],
      userSelect: 'none',
      borderRadius: 1,
      bgcolor: alpha(palette.grey[200], 0.5)
    })}
  >
    {typeof icon === 'string' ? (
      <Typography
        display="block"
        variant={direction === 'center' ? 'h2' : 'h3'}
        color={color}
      >
        <Icon>{icon}</Icon>
      </Typography>
    ) : (
      <Icon sx={{ mx: 2, mt: 1 }}>{icon}</Icon>
    )}
    <Typography
      display="block"
      variant="h5"
      fontWeight="bold"
      mt={direction === 'center' ? 1 : 2}
      mb={1.5}
    >
      {title}
    </Typography>
    <Typography
      display="block"
      variant={small ? 'button' : 'body1'}
      color="text"
    >
      {description}
    </Typography>
  </Box>
);

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark'
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  small: PropTypes.bool
};

export default DefaultInfoCard;
