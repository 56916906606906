import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import retrieveReports from 'src/utils/retrieveReports';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const reportsContext = createContext();

export const ReportsProvider = ({ children }) => {
  const [reports, setReports] = useState();
  const { currentUser } = useCurrentUserContext();

  const reportsData = useMemo(() => ({ reports, setReports }), [reports]);

  useEffect(() => {
    const handleRetrieveReports = async () => {
      try {
        const res = await retrieveReports(currentUser.user_doc_id);
        setReports(res);
      } catch (err) {
        console.error('Error while retrieving reports', err);
        setReports([]);
      }
    };

    if (currentUser && currentUser.type === 'org') {
      handleRetrieveReports();
    }
  }, [currentUser]);

  return (
    <reportsContext.Provider value={reportsData}>
      {children}
    </reportsContext.Provider>
  );
};

ReportsProvider.propTypes = {
  children: PropTypes.any
};

export const useReports = () => useContext(reportsContext);
