import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import relations from 'src/common/relations';

const filter = createFilterOptions();

const RelationShipFields = ({ setValues, newValues, errors }) => {
  RelationShipFields.propTypes = {
    setValues: PropTypes.func,
    newValues: PropTypes.object,
    errors: PropTypes.object
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'relations'
  });

  const checkLabelOfRelation = () => {
    if (newValues.relation && newValues.relation.name) {
      const indexOfJob = relations.findIndex(
        (el) => el.name === newValues.relation.name
      );
      if (indexOfJob > -1) {
        return relations[indexOfJob].label;
      }
      return newValues.relation.name;
    }
    return '';
  };

  const clearSelection = () => {
    const temp = { ...newValues };
    delete temp.relation;
    setValues(temp);
  };

  return (
    <Autocomplete
      value={checkLabelOfRelation()}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValues({
            ...newValues,
            relation: {
              ...newValues.relation,
              name: newValue
            }
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValues({
            ...newValues,
            relation: {
              ...newValues.relation,
              name: newValue.inputValue
            }
          });
        } else {
          setValues({
            ...newValues,
            relation: {
              ...newValues.relation,
              name: newValue.name
            }
          });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `${t('addRelation')} "${inputValue}"`
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      // componentName="relation"
      id="relationship-autocomplete"
      options={relations}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      freeSolo
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={t('relation')}
          name="relation"
          placeholder={t('selectYourRelationOrAddANewOne')}
          error={
            errors &&
            errors.relation &&
            errors.relation.msg &&
            errors.relation.msg
          }
          helperText={
            errors &&
            errors.relation &&
            errors.relation.msg &&
            errors.relation.msg
          }
          slotProps={{
            formHelperText: {
              sx: {
                color: (theme) =>
                  errors &&
                  errors.relation &&
                  errors.relation.msg &&
                  theme.palette.error.dark
              }
            }
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          onClick: () => clearSelection()
        }
      }}
    />
  );
};

export default RelationShipFields;
