import { GlobalStyles as MuiGlobalStyles } from '@mui/material';
import belleFair from 'src/assets/font/Bellefair/Bellefair-Regular.ttf';

const globalStyles = (
  <MuiGlobalStyles
    styles={{
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      '@font-face': {
        fontFamily: 'Bellefair',
        src: `url(${belleFair}) format('truetype')`,
        fontDisplay: 'swap'
      },
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%'
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      borderRadius: 8
    }}
  />
);

const GlobalStyles = () => globalStyles;

export default GlobalStyles;
