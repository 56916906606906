import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField
} from '@mui/material';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import i18n from 'src/i18n';

const htmlTemplate = (
  user_email,
  user_id,
  reason,
  patientName,
  contactPhone,
  description,
  address,
  patientAge,
  currentPlace
) =>
  `<table style="background:#EEE;padding:40px;border:1px solid #DDD;margin:0 auto;font-family:calibri;">
  <tr>
    <td>
      <table style="background:#FFF;width:100%;border:1px solid #CCC;padding:0;margin:0;border-collapse:collapse;max-width:100%;width:60vw;border-radius:10px;">
        <!-- Logo -->
        <tr>
          <td style="padding:10px 30px;text-align:center;margin:0">
            <p>
              <a href="#">
                <!--                 <img src="http://mailchimp.com/assets/images/freddie-719ecb5b.svg" width="100"> -->
                   <svg width="92" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.987 31.5841C4.92849 31.5841 0 26.626 0 20.5323C0 14.4385 4.92899 9.48041 10.987 9.48041C17.045 9.48041 21.974 14.4385 21.974 20.5323C21.974 26.626 17.0459 31.5841 10.987 31.5841ZM10.987 10.536C5.50765 10.536 1.04938 15.0196 1.04938 20.5318C1.04938 26.044 5.50765 30.5275 10.987 30.5275C16.4663 30.5275 20.9251 26.0429 20.9251 20.5308C20.9251 15.0186 16.4673 10.536 10.987 10.536Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M18.96 21.0225C18.6182 19.7483 15.4851 19.6108 13.6203 20.0779C12.6437 20.3235 11.6456 20.6428 10.6162 20.8265C11.3697 21.4989 12.1788 22.135 13.34 22.2932C16.2211 22.6842 18.0112 21.775 18.96 21.0225Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M13.34 22.2932C12.1764 22.135 11.3697 21.4989 10.6162 20.8265C9.45013 19.7857 8.41298 18.6579 6.37723 19.0823C3.14069 19.7572 2.71488 23.6081 5.21404 26.0828C6.28706 27.2131 7.66455 28.0041 9.17779 28.3586C10.691 28.7132 12.2742 28.616 13.7333 28.079C15.1924 27.5419 16.4641 26.5883 17.3925 25.3352C18.3209 24.0819 18.8656 22.5835 18.96 21.0235C18.0112 21.775 16.221 22.6842 13.34 22.2932Z"
                fill={theme.palette.secondary.main}
            />
            <path
                d="M15.034 13.9586C14.6301 14.8295 18.2304 15.7957 18.6611 18.6879C18.8687 15.8409 15.5335 12.882 15.034 13.9586Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M7.46619 17.5935C8.11524 17.3231 8.42345 16.5746 8.15463 15.9217C7.8858 15.2688 7.14167 14.9587 6.49262 15.2292C5.84357 15.4996 5.53536 16.2481 5.80418 16.9011C6.07306 17.5539 6.81714 17.8639 7.46619 17.5935Z"
                fill={theme.palette.secondary.main}
            />
            <path
                d="M10.3549 14.08C10.6585 13.7746 10.6585 13.2795 10.3549 12.9741C10.0513 12.6687 9.55909 12.6687 9.25551 12.9741C8.95194 13.2795 8.95194 13.7746 9.25551 14.08C9.55909 14.3854 10.0513 14.3854 10.3549 14.08Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M13.1014 9.05206C14.2245 5.7149 13.4696 3.04871 11.1614 1.78241C9.58359 2.10513 8.647 2.87335 8.12549 3.93383C11.2204 3.68185 13.1844 5.63041 13.1014 9.05206Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M25.6983 6.13641C20.1389 4.1294 16.6304 4.81756 16.0786 9.39055C19.2648 12.6973 22.474 11.1146 25.6983 6.13641Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M21.2765 4.32541C21.5343 3.21728 21.6681 1.90776 21.6881 0.41748C15.9226 1.70883 13.3224 4.17658 15.2839 8.33846C15.3816 8.36203 15.4754 8.38119 15.5696 8.40085C16.0281 5.14422 18.0463 3.93835 21.2765 4.32541Z"
                fill={theme.palette.primary.main}
            />
        </svg>
              </a>
            </p>
          </td>
        </tr>

        <!-- Welcome Salutation -->
        <tr>
          <td style="padding:10px 30px;margin:0;font-size:2.5em;color:#4A7BA5;text-align:center;">
            פנייה חדשה במגדלור
          </td>
        </tr>
        <!-- User Msg -->
        <tr>
          <td style="padding:10px 30px;margin:0;text-align:right;">
            <p>שלום,</p>
            <p>מצורפת פנייה חדשה שנשלחה ממערכת מגדלור ונשלחה על ידי</p>
            <p>${user_email}</p>
            <p>ת.ז ${user_id}</p>
          </td>
        </tr>
        <!-- Link Button -->
        <!-- // <tr>
        //   <td style="padding:10px 30px;margin:0;text-align:center;">
        //     <p><a href="#" style="background:#4A7BA5;color:#FFF;padding:10px;text-decoration:none;">Activate Profile</a></p>
        //   </td>
        // </tr> -->
        <!-- Any Banner Image 
        // <tr>
        //   <td style="padding:10px 30px;margin:0">
        //     <p>Lorem Ipsum has been the industry's standard dummy text ever since the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
        //   </td>
        // </tr> -->
        <!-- Colorful Steps Data -->
        <tr>
          <td style="padding:10px 30px;margin:0;background: #F36565;color: #FFFFFF;">
            <table style="width:100%;padding:10px;text-align:center;">
              <tr>
                <td colspan="2" style="font-size:1.8em">פרטי ההצעה</td>
              </tr>
              <tr>
                <td style="width:80px;font-size:1.4em;">סיבה</td>
                <td style="text-align:right;">
                  <p>${reason}</p>
                </td>
              </tr>
              <tr>
                <td style="width:80px;font-size:1.4em;">שם המטופל</td>
                <td style="text-align:right;">
                  <p>${patientName}</p>
                </td>
              </tr>
              <tr>
                <td style="width:80px;font-size:1.4em;">הטלפון של איש הקשר</td>
                <td style="text-align:right;">
                  <p>${contactPhone}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!-- Some more content -->
        <tr>
          <td style="padding:10px 30px;margin:0">
            <p style="font-size:1.1em;font-weight: bold;">פרטים נוספים</p>
            <ul>
            <li>כתובת: <br/>
            ${address}
            </li>
            <li>גיל המטופל: <br/>
            ${patientAge}
            </li>
            <li>מקום נוכחי<br/>
            ${currentPlace}
            </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td style="padding:10px 30px;margin:0">
            <p style="font-size:1.1em;font-weight: bold;">פירוט / תיאור</p>
            <p>${description}</p>
          </td>
        </tr>
        <!-- Footer Content -->
        <tr>
          <td style="padding:10px 30px;margin:0;background:#555;color:#FFF;border-top:1px solid #CCC;">
            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p> -->
            <p>נא ללחוץ על הלינק כדי להיכנס למגדלור <a href="https://www.mgdalor.com/login" target="_blank" rel="noreferrer noopener" style="color:#FFF;">כניסה</a></p>
            <p style="margin: 50px 0;text-align: center;">
              <a style="display: inline-block;padding-right: 10px;" href="https://www.facebook.com/">
                <svg style="width: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><title>Facebook</title><path style="fill: #FFF;"d="M45.353 0h-42.706a2.625 2.625 0 0 0-2.647 2.647v42.706a2.625 2.625 0 0 0 2.647 2.647h23.008v-18.569h-6.268v-7.241h6.229v-5.333c0-6.19 3.776-9.577 9.3-9.577a56.108 56.108 0 0 1 5.606.273v6.462h-3.851c-3 0-3.581 1.44-3.581 3.543v4.632h7.163l-.934 7.241h-6.229v18.569h12.263a2.625 2.625 0 0 0 2.647-2.647v-42.706a2.625 2.625 0 0 0-2.647-2.647z"></path></svg>
</a>
              <a style="display: inline-block;padding-right: 10px;" href="https://twitter.com/">
              <svg style="width: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><title>Twitter</title><path style="fill: #FFF;"d="M48 9.117a19.935 19.935 0 0 1-5.658 1.55 9.855 9.855 0 0 0 4.324-5.441 19.789 19.789 0 0 1-6.234 2.374 9.866 9.866 0 0 0-17.045 6.739 9.238 9.238 0 0 0 .252 2.234 27.932 27.932 0 0 1-20.287-10.267 9.555 9.555 0 0 0-1.333 4.937 9.815 9.815 0 0 0 4.36 8.18 9.851 9.851 0 0 1-4.469-1.223v.108a9.858 9.858 0 0 0 7.89 9.656 10.248 10.248 0 0 1-2.595.36 9.932 9.932 0 0 1-1.838-.18 9.946 9.946 0 0 0 9.189 6.847 19.745 19.745 0 0 1-12.213 4.216 16.691 16.691 0 0 1-2.343-.144 28 28 0 0 0 15.1 4.437c18.09 0 28-14.991 28-28 0-.432 0-.865-.036-1.261a20.192 20.192 0 0 0 4.936-5.122z"></path></svg>
</a>
              <a style="display: inline-block;padding-right: 10px;" href="https://www.instagram.com/">
                <svg style="width: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><title>Instagram</title><path style="fill: #FFF;"d="M24 4.324c6.408 0 7.167.024 9.7.14a13.28 13.28 0 0 1 4.456.826 7.436 7.436 0 0 1 2.76 1.8 7.436 7.436 0 0 1 1.8 2.76 13.28 13.28 0 0 1 .826 4.456c.115 2.531.14 3.29.14 9.7s-.024 7.167-.14 9.7a13.28 13.28 0 0 1-.826 4.456 7.948 7.948 0 0 1-4.555 4.555 13.28 13.28 0 0 1-4.456.826c-2.53.115-3.289.14-9.7.14s-7.168-.024-9.7-.14a13.28 13.28 0 0 1-4.456-.826 7.436 7.436 0 0 1-2.76-1.8 7.436 7.436 0 0 1-1.8-2.76 13.28 13.28 0 0 1-.825-4.457c-.115-2.531-.14-3.29-.14-9.7s.024-7.167.14-9.7a13.28 13.28 0 0 1 .826-4.456 7.436 7.436 0 0 1 1.8-2.76 7.436 7.436 0 0 1 2.76-1.8 13.28 13.28 0 0 1 4.45-.82c2.531-.115 3.29-.14 9.7-.14m0-4.324c-6.518 0-7.335.028-9.9.144a17.615 17.615 0 0 0-5.821 1.116 11.764 11.764 0 0 0-4.251 2.768 11.764 11.764 0 0 0-2.768 4.251 17.615 17.615 0 0 0-1.116 5.821c-.116 2.565-.144 3.382-.144 9.9s.028 7.335.144 9.9a17.616 17.616 0 0 0 1.116 5.821 11.765 11.765 0 0 0 2.768 4.251 11.764 11.764 0 0 0 4.251 2.768 17.615 17.615 0 0 0 5.821 1.116c2.565.116 3.382.144 9.9.144s7.335-.028 9.9-.144a17.616 17.616 0 0 0 5.826-1.116 12.272 12.272 0 0 0 7.019-7.019 17.616 17.616 0 0 0 1.111-5.821c.116-2.565.144-3.382.144-9.9s-.028-7.335-.144-9.9a17.616 17.616 0 0 0-1.116-5.821 11.764 11.764 0 0 0-2.768-4.251 11.764 11.764 0 0 0-4.251-2.768 17.616 17.616 0 0 0-5.821-1.116c-2.565-.116-3.382-.144-9.9-.144zm0 11.676a12.324 12.324 0 1 0 12.324 12.324 12.324 12.324 0 0 0-12.324-12.324zm0 20.324a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm12.811-23.691a2.88 2.88 0 1 0 2.88 2.88 2.88 2.88 0 0 0-2.88-2.88z"></path></svg>
</a>
              <a style="display: inline-block;padding-right: 10px;" href="https://www.linkedin.com/">
                <svg style="width: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><title>LinkedIn</title><path style="fill: #FFF;"d="M0 0v48h48v-48h-48zm14.8 40.64h-6.92v-22.24h6.92v22.24zm-3.48-25.28a4 4 0 1 1 4-4 3.986 3.986 0 0 1-4 4zm22.44 25.28v-10.8c0-2.56-.04-5.88-3.6-5.88-3.6 0-4.16 2.8-4.16 5.72v11h-6.88v-22.28h6.64v3.04h.08a7.21 7.21 0 0 1 6.52-3.6c7 0 8.28 4.6 8.28 10.6v12.2h-6.88z"></path></svg>
</a>
              <a style="display: inline-block;padding-right: 10px;" href="https://www.youtube.com/"><svg style="width: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 721"><title>YouTube</title>
  <path style="fill: #FFF;"d="M1013,156.3c0,0-10-70.4-40.6-101.4C933.6,14.2,890,14,870.1,11.6C727.1,1.3,512.7,1.3,512.7,1.3h-0.4
    c0,0-214.4,0-357.4,10.3C135,14,91.4,14.2,52.6,54.9C22,85.9,12,156.3,12,156.3S1.8,238.9,1.8,321.6v77.5
    C1.8,481.8,12,564.4,12,564.4s10,70.4,40.6,101.4c38.9,40.7,89.9,39.4,112.6,43.7c81.7,7.8,347.3,10.3,347.3,10.3
    s214.6-0.3,357.6-10.7c20-2.4,63.5-2.6,102.3-43.3c30.6-31,40.6-101.4,40.6-101.4s10.2-82.7,10.2-165.3v-77.5
    C1023.2,238.9,1013,156.3,1013,156.3z M407,493l0-287l276,144L407,493z"></path>
</svg>
</a>
            </p>
            
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>`;

const reasons = [
  {
    label: i18n.t('appSidebar.referralDialog.productSuggestion'),
    value: 'productSuggestion'
  },
  {
    label: i18n.t('appSidebar.referralDialog.newPatient'),
    value: 'newPatient'
  }
];
const AuthorizedPersonalContactWithMgdalorDialog = ({ open, onClose }) => {
  AuthorizedPersonalContactWithMgdalorDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'appSidebar.referralDialog'
  });
  const initContactState = {
    reason: '',
    patientName: '',
    description: '',
    contactPhone: '',
    address: '',
    patientAge: '',
    currentPlace: ''
  };
  const { currentUser } = useCurrentUserContext();
  const [isButtonDisabled, setDisabledButton] = useState(true);
  const [contactValues, setContactValues] = useState(initContactState);
  const handleDisableSendButton = () => {
    if (contactValues.reason !== '' && contactValues.reason === 'newPatient') {
      if (
        contactValues.patientName === '' ||
        contactValues.contactPhone === '' ||
        contactValues.reason === '' ||
        contactValues.contactPhone.length < 6
      ) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    }
    if (
      contactValues.reason !== '' &&
      contactValues.reason === 'productSuggestion' &&
      contactValues.description === ''
    ) {
      setDisabledButton(true);
    }
  };
  const handleChange = (e) => {
    setContactValues({ ...contactValues, [e.target.name]: e.target.value });
  };
  const handleSendEmail = () => {
    const ticketsRef = collection(db, 'tickets');
    const mailRef = collection(db, 'mail');
    const userEmail = currentUser.email;
    const indexOfReason = reasons.findIndex(
      (el) => el.value === contactValues.reason
    );
    const created_at = serverTimestamp();
    addDoc(ticketsRef, {
      ...contactValues,
      created_at,
      referral_user_doc_id: currentUser.user_doc_id
    }).then(async () => {
      await addDoc(mailRef, {
        // to:
        //   contactValues.reason === 'newPatient'
        //     ? commonConfig.ticketsMail
        //     : commonConfig.supportMail,
        to: commonConfig.supportMail,
        message: {
          subject: 'לקוח רשום פנה עם הפנייה חדשה',
          html: htmlTemplate(
            userEmail,
            currentUser.id,
            indexOfReason > -1
              ? reasons[indexOfReason].label
              : contactValues.reason,
            contactValues.patientName,
            contactValues.contactPhone,
            contactValues.description,
            contactValues.address,
            contactValues.patientAge,
            contactValues.currentPlace
          )
        }
      }).then(() => {
        setContactValues(initContactState);
        onClose();
      });
    });
  };

  useEffect(() => {
    handleDisableSendButton();
  }, [contactValues]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={() => onClose()}
      open={Boolean(open)}
    >
      <DialogTitle>{t('dialogTitle')}</DialogTitle>
      <Divider />
      <DialogContent sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              sx={{ maxWidth: 550 }}
              fullWidth
              required
              id="outlined-select-reason"
              onChange={handleChange}
              label={t('reason')}
              value={contactValues.reason}
              name="reason"
              helperText={t('pleaseChooseAReason')}
            >
              {reasons.map((option) => (
                <MenuItem
                  sx={{ display: 'flex', justifyContent: 'flex-start' }}
                  key={option.value}
                  style={{ direction: 'rtl' }}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {contactValues && contactValues.reason === 'newPatient' && (
            <>
              <Grid item xs={12} md={6} xl={4}>
                <TextField
                  id="outlined-patientName"
                  fullWidth
                  required
                  label={t('patientName')}
                  value={contactValues.patientName}
                  onChange={handleChange}
                  name="patientName"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <TextField
                  id="outlined-contactPhone"
                  required
                  fullWidth
                  label={t('contactPhone')}
                  value={contactValues.contactPhone}
                  onChange={(e) => {
                    const replaced = e.target.value.replace(/[^\d]+/g, '');
                    handleChange({
                      target: { name: 'contactPhone', value: replaced }
                    });
                  }}
                  name="contactPhone"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <TextField
                  id="outlined-patientAge"
                  fullWidth
                  label={t('patientAge')}
                  value={contactValues.patientAge}
                  onChange={handleChange}
                  name="patientAge"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <TextField
                  id="outlined-currentPlace"
                  fullWidth
                  label={t('currentPlace')}
                  value={contactValues.currentPlace}
                  onChange={handleChange}
                  name="currentPlace"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <TextField
                  id="outlined-address"
                  fullWidth
                  label={t('address')}
                  value={contactValues.address}
                  onChange={handleChange}
                  name="address"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <TextField
              id="outlined-description"
              fullWidth
              required={contactValues.reason === 'productSuggestion'}
              multiline
              rows={4}
              label={t('description')}
              value={contactValues.description}
              onChange={handleChange}
              name="description"
              helperText={t('pleaseDescripeALittle')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSendEmail()}
          disabled={isButtonDisabled}
          variant="contained"
        >
          שליחה
        </Button>
        <Button onClick={() => onClose()} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthorizedPersonalContactWithMgdalorDialog;
