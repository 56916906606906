import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { LanguageOutlined } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Hidden,
  IconButton,
  Link as MuiLink,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LanguagePopperMenu from '../shared/LanguagePopper/LanguagePopperMenu';
import DefaultNavbarDropdown from './DefaultNavbarDropDown';

function DefaultNavbarMobile({ navbarRoutes, open, setOpen }) {
  const theme = useTheme();
  const [collapse, setCollapse] = useState('');
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);

  const handleSetCollapse = (name, routeCollapses) => {
    if (collapse === name || !routeCollapses) {
      setCollapse('');
    } else {
      setCollapse(name);
    }
  };

  const handleLanguageMenuOpen = (e) => {
    setLanguageMenuAnchor(languageMenuAnchor ? null : e.currentTarget);
  };
  const renderNavbarItems = navbarRoutes.map(
    ({
      name,
      icon,
      collapse: routeCollapses,
      href,
      route,
      collapse: navCollapse
    }) => (
      <DefaultNavbarDropdown
        key={name}
        name={name}
        icon={icon}
        collapseStatus={name === collapse}
        onClick={() => {
          handleSetCollapse(name, routeCollapses);
          if (!routeCollapses) {
            // close the menu on click
            setOpen(!open);
          }
        }}
        href={href}
        route={route}
        collapse={Boolean(navCollapse)}
      >
        <Box sx={{ height: '15rem', maxHeight: '15rem', overflowY: 'scroll' }}>
          {routeCollapses &&
            routeCollapses.map((item) => (
              <Box key={item.name} px={2}>
                {item.collapse ? (
                  <>
                    <Typography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                    >
                      {item.name}
                    </Typography>
                    {item.collapse.map((el) => (
                      <Typography
                        key={el.name}
                        component={el.route ? Link : MuiLink}
                        to={el.route ? el.route : ''}
                        href={el.href ? el.href : ''}
                        target={el.href ? '_blank' : ''}
                        rel={el.href ? 'noreferrer' : 'noreferrer'}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="regular"
                        py={0.625}
                        px={2}
                        onClick={() => setOpen(false)}
                        sx={{
                          borderRadius: 2,
                          cursor: 'pointer',
                          transition: 'all 300ms linear',
                          '&:hover': {
                            backgroundColor: theme.palette.grey[200],
                            color: theme.palette.grey[900]
                          }
                        }}
                      >
                        {el.name}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Box
                    key={item.key}
                    display="block"
                    component={item.route ? Link : MuiLink}
                    to={item.route ? item.route : ''}
                    href={item.href ? item.href : ''}
                    target={item.href ? '_blank' : ''}
                    rel={item.href ? 'noreferrer' : 'noreferrer'}
                    sx={{
                      borderRadius: 2,
                      cursor: 'pointer',
                      transition: 'all 300ms linear',
                      py: 1,
                      px: 1.625,

                      '&:hover': {
                        backgroundColor: theme.palette.grey[200],
                        color: theme.palette.grey[900],
                        '& *': {
                          color: theme.palette.grey[900]
                        }
                      }
                    }}
                    onClick={() => {
                      console.log(open);
                      setOpen(false);
                    }}
                  >
                    <Typography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      display="block"
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      sx={{ transition: 'all 300ms linear' }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      </DefaultNavbarDropdown>
    )
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <Box width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
        <Hidden smUp>
          <IconButton
            sx={{
              transition: 'all 100ms linear',
              '&:hover': {
                transform: 'scale(1.2)'
              },
              display: 'block',
              position: 'relative',
              marginLeft: 'auto'
              // bottom: 0/
            }}
            onClick={(e) => handleLanguageMenuOpen(e)}
          >
            <LanguageOutlined size="small" color="primary" />
          </IconButton>
        </Hidden>
      </Box>
      <LanguagePopperMenu
        languageMenuAnchor={languageMenuAnchor}
        setLanguageMenuAnchor={setLanguageMenuAnchor}
      />
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  navbarRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  setOpen: PropTypes.func
};

export default DefaultNavbarMobile;
