import {
  FaxOutlined,
  MailOutlineSharp,
  PhoneOutlined
} from '@mui/icons-material';
import { Box, Card, Icon, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropType from 'prop-types';
import { Helmet } from 'react-helmet';
import mgdalorFull from 'src/assets/mgdalorFull.webp';

const PolicyHeader = ({ children, customSx }) => {
  PolicyHeader.propTypes = {
    children: PropType.any,
    customSx: PropType.object
  };
  return (
    <Typography
      sx={{ mt: 2, ...customSx }}
      variant="subtitle1"
      fontWeight="bold"
    >
      {children}
    </Typography>
  );
};

const PolicyParagraph = ({ children }) => {
  PolicyParagraph.propTypes = {
    children: PropType.any
  };
  return (
    <Typography variant="overline" sx={{ fontSize: '125%' }} lineHeight={1.5}>
      {children}
    </Typography>
  );
};
const PrivacyPolicy = ({ pageCustomSx, showSpacingBox = true }) => {
  PrivacyPolicy.propTypes = {
    pageCustomSx: PropType.any,
    showSpacingBox: PropType.bool
  };

  return (
    <>
      <Helmet>
        <meta name="googlebot" content="index" />
        <title>תקנון האתר</title>
        <meta name="robots" content="index" />
        <link rel="canonical" href="https://www.mgdalor.com/privacy" />
      </Helmet>
      {showSpacingBox && <Box minHeight="10rem" width="100%" />}
      <Stack
        minHeight="15rem"
        width="100%"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          placeItems: 'center',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%'
        }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <img
          loading="lazy"
          src={mgdalorFull}
          alt="mgdalor logo"
          height="40%"
          width="40%"
        />
        <Stack>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: 200,
              gap: 2
            }}
          >
            <a style={{ marginRight: 'auto' }} href="tel:0557111106">
              055-711-1106
            </a>
            <Icon>
              <PhoneOutlined />
            </Icon>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: 200,
              gap: 2
            }}
          >
            <a style={{ marginRight: 'auto' }} href="tel:0733752320">
              073-375-2320
            </a>
            <Icon>
              <FaxOutlined />
            </Icon>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: 200
            }}
          >
            <Typography>support@mgdalor.com</Typography>
            <Icon>
              <MailOutlineSharp />
            </Icon>
          </Box>
        </Stack>
      </Stack>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mb: 4,
          backgroundColor: (theme) => alpha(theme.palette.common.white, 0.8),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: (theme) => theme.shadows[6],
          p: 4,
          textAlign: 'justify',
          ...pageCustomSx
        }}
      >
        <PolicyHeader>מגדלור ייעוץ והכוונה | תנאי שימוש</PolicyHeader>
        <PolicyParagraph>
          אצלנו אין אותיות קטנות – הכל בגדול ומפורט לפרטי פרטים. ברוכים הבאים
          לאתר האינטרנט של מגדלור ייעוץ והכוונה להלן: &quot;האתר״. האמור בתנאי
          שימוש אלה בלשון זכר – אף לשון נקבה במשמע, וכן להיפך. האמור בלשון יחיד
          – אף לשון רבים במשמע, וכן להיפך. הכותרות הינן לנוחיות בלבד ואין לייחס
          להן משמעות אחרת.
        </PolicyParagraph>
        <PolicyHeader variant="subtitle1" fontWeight="bold">
          הגדרת מושגים
        </PolicyHeader>
        <PolicyParagraph variant="overline" lineHeight={1.5}>
          גולשים יקרים, לפני שתתחילו את החוויה ותגלשו באתר ועוד לפני שתשתמשו
          במידע, בתכנים, בשירותים ובכלים המוצעים או המוצגים בו או המתאפשרים
          דרכו, אתם מתבקשים לקרוא בעיון את תנאי השימוש המפורטים בהמשך, כמו גם את
          התנאים וההנחיות המופיעים בדפי ועמודי האתר השונים. תנאי השימוש מטרתם
          להסדיר את היחסים בין אתר &quot;מגדלור ייעוץ והכוונה״ לבין כל גולש ו/או
          כל העושה שימוש בשירותים המוצעים, במידע הקיים או במידע כזה או אחר שהשיג
          באמצעות האתר. מגדלור ייעוץ והכוונה בין היתר האתר, מנהלי, עובדי, בעלי,
          מפעילי האתר וכל הקשור לו שכתובתו באינטרנט
          <a href="https://www.mgdalor.com">www.mgdalor.com</a>
          וכל כתובת אחרת הקשורה לו, בין היתר כל עמודי המשנה הקשורים אליו וכל
          כתובות הדואר האלקטרוני וכל התוכנות וממשקי הפעילות שנועדו לשימוש הגולש
          ולשימוש מפעילי האתר בין היתר הממשקים החינמיים, התכנים, הכלים,
          והשירותים המוצעים, או מוצגים בו ודרכו, או המתאפשרים באמצעותו, או כל
          מידע דיגיטלי, סיסמאות מאגרי נתונים וקודי המקור, נוסף לכך עיצוב האתר
          העמודים והפרסומים והממשקים בו, התכנים והרעיונות, בין אם בצורה כתובה או
          בצורה חזותית וכל פעולה ו/או יצירה שיש בהם זכות יוצרים, כל אלו שייכים
          למגדלור ייעוץ והכוונה ולבעליו החוקיים ולנוחות ההגדרות במסמך זה נקראים
          להלן: מגדלור ייעוץ והכוונה ו/או האתר ו/או מפעיל האתר.
          &quot;הגולש&quot; או &quot;המשתמש&quot; במגדלור ייעוץ והכוונה הנו כל
          העושה שימוש כזה או אחר בעמודי האתר, במידע שבו, בעיצוב האתר, העמודים
          והפרסומים והממשקים בו, התכנים והרעיונות, בין אם בצורה כתובה או בצורה
          חזותית, בשלמותם ו/או בחלקים מהם, כל שימוש שהוא בין אם זה שימוש סביל
          כעיון או צפיה ו/או פעיל כפעולת הדפסה, אחסון, שמירה, העברה לאחרים,
          השתתפות בפורום, שימוש בממשקי האתר השונים, מילויי טפסי פעולה
          אינטראקטיביים כלשהם וכ&quot;ו במצב מקוון כשהוא מחובר לרשת האינטרנט
          ו/או במצב לא מקוון כשהוא גולש באתר וממשקיו ממחשבו אף באם הנו מנותק
          מרשת האינטרנט. בין היתר גולש יחשב גם כל מי שמשתמש בחלקי מידע, תוכנה,
          קבצים או ממשקים שהגיעו אליו בכל דרך אף אם הגיעו אליו או למחשבו לאחר
          עיבוד כלשהו ו/או במנותק מהקשרם התוכני, הכתובים ו/או הנראים כפי שהם
          מופיעים באתר.
        </PolicyParagraph>

        <PolicyHeader>גילוי נאות:</PolicyHeader>
        <PolicyParagraph variant="overline" lineHeight={1.5}>
          האתר מציג את התקנון הזה, וחובה על כל משתמש לעיין בו ובכך הוא מקבל את
          תנאי האתר ומגבלותיו. התכנים המוצעים באתר מוצעים בכפוף ובהתניה יסודית
          של הסכמת המשתמשים לאמור בתקנון זה. כל המשתמש באתר זה, מצהיר כי קרא את
          תוכן התקנון, וכי הוא מסכים בצורה חד משמעית כי לא תהיה לו ו/או למי
          מטעמו, כל טענה ו/או דרישה ו/או תביעה, במישרין ו/או בעקיפין, כנגד האתר
          ו/או מי מבעליו ו/או מי ממנהליו ו/או מי מטעמו ו/או האתר .
        </PolicyParagraph>

        <PolicyHeader>השימוש באתר כפוף לתנאים המפורטים להלן:</PolicyHeader>
        <PolicyParagraph>
          כניסה וגלישה באתר ו/או שימוש בשירותים, מידע או יישומים המוצגים בו,
          מהווה ומבטאת הסכמה מצד הגולש או המשתמש לתנאי השימוש, ובך הינו מצהיר כי
          קרא את תנאי השימוש, הינו מודע ומסכים להם, ומסכים לפעול לפיהם. היות
          וטבעו של המידע המגיע ממקורות שונים ובעולמינו כמשתנה מרגע לרגע, אין
          האתר מתיימר לוודא את נכונות ואמיתות המידע המוצג באתר. יתרה מכך, המידע
          נמסר כמידע לידיעה כללית בלבד ואינו מהווה המלצה או אישור לעשות פעילות
          זו או אחרת, ולבחור במקום זה או אחר . על הגולש חלה החובה החוקית לוודא
          את מהימנות המידע, לנקוט כל צעד הנדרש ע&quot;פ ההוראות והנהלים השונים
          לשם הבטחת שלומו הגופני ולעשות שיקול דעת לפני כל פעולה ומעשה. מגדלור
          ייעוץ והכוונה לא תישא באחריות לשום צעד שנעשה על בסיס פריט מידע כזה או
          אחר שהושג כתוצאה מצפיה, גלישה ושימוש באתר ובמידע שהופץ דרכו.
        </PolicyParagraph>

        <PolicyHeader>זכויות יוצרים וקניין רוחני</PolicyHeader>
        <PolicyParagraph>
          למפעיל האתר קיימת זכות יוצרים על כל מידע ו/או תצוגה ו/או תוכנה כהגדרתה
          בחוק המחשבים, תשנ&quot;ה – 1995 לרבות עיצוב גרפי, סימן מסחרי וסודות
          מסחריים, שפותחו ו/או תוכננו ו/או חוברו ו/או קודדו ו/או נוצרו על ידי
          מפעיל האתר בעצמו או שהזכויות בהם הועברו למפעיל האתר מצד שלישי. אין
          להעתיק ו/או לשכפל ו/או להפיץ ו/או לפרסם ו/או להשתמש בתכנים המוצגים
          באתר ו/או לעשות בהם כל פעולה, במישרין או בעקיפין, המהווה הפרה או פגיעה
          בקניין הרוחני של מפעיל האתר, אלא אם מפעיל האתר התיר זאת במפורש בכתב
          ומראש. מגדלור ייעוץ והכוונה שומר לעצמו את הזכות הבלעדית לעדכן, לשנות,
          למחוק, להוסיף תנאים לתנאי השימוש הנ&quot;ל בכל עת וללא הודעה מוקדמת
          מראש ועל הגולש חלה האחריות לעיין בו כל פעם מחדש בטרם יגלוש וישתמש
          באתר. מפעיל האתר רואה בחומרה פגיעה בזכויותיו הקנייניות של אחר ויראה
          בחומרה כל ניסיון להשתמש באתר כאמצעי להפרה או פגיעה בזכויותיו הקנייניות
          שלו. הגולש מצהיר בזאת כי הוא בעל מלוא זכויות היוצרים ובעל הזכות
          המוסרית במידע ובתכנים שהעלה בתגובותיו ו/או שמסר באמצעות האתר, וכי הוא
          מתיר למגדלור ייעוץ והכוונה, בעליו ומפעיליו לעשות כל שימוש בתכנים,
          לרבות פרסומם בכל אמצעי ממוחשב, מודפס או מוצג במדיות התקשורת השונות,
          כמו גם כי הוא מתיר להעלותם ו/או להסירם מאמצעים ו/או מדיות אלו על פי
          שיקול דעתו הבלעדי של מפעילי ומנהלי האתר. הגולש מצהיר בזאת כי בעצם
          מסירת התכנים והמידע ותגובותיו באתר הנו מוותר על כל זכות בהם וכל שימוש
          שמנהלי האתר, מפעיליו וצדדים שלישיים הקשורים בו שיעשו שימוש במידע זה,
          הגולש לא יתבע דבר בגינם.
        </PolicyParagraph>

        <PolicyHeader>פרסום באתר</PolicyHeader>
        <PolicyParagraph>
          פרסומות המוצגת באתר במידה ויהיו הינם קניינו של המפרסם ולפיכך אין לעשות
          בפרסומת שימוש המהווה הפרה של זכות המפרסם בפרסומת. האתר ובעליו ומי
          מטעמו אינם אחראים לתוכן מודעות, &quot;באנרים&quot; או לכל חומר פרסומי
          באתר. האחריות לכך על המפרסמים בלבד. הימצאותם של קישורים
          (&quot;לינקים&quot;) לאתרים אחרים אינם מהווים ערובה לתכנים באתרים אלה
          מבחינת מהימנותם, שלמותם, או מכל בחינה אחרת. המשתמש מאשר למפעיל האתר
          לשלוח לתיבת הדואר האלקטרוני של המשתמש עדכונים בתחומים שונים. בצמוד
          לעדכונים אלו יופיעו, מעת לעת, פרסומות. מפעיל האתר לא ישא באחריות לתוכן
          פרסומי או מידע מסחרי אחר שיפורסם באתר ו/או ישלח לתיבת הדואר האלקטרוני
          של המשתמש. פרסום באתר אינו מהווה המלצה או חוות דעת או הבעת דעה או
          עידוד, מצד מפעיל האתר, לרכוש את המוצר המוצג בפרסומת ו/או לעשות שימוש
          בשירות או מידע המוצג בפרסומת ואין הנהלת האתר, צד בעניין בהתקשרות זו.
          והאחריות חלה על המפרסם ועל המשתמש בלבד . כל הסתמכות של משתמש ו/או צד
          שלישי כלשהו, על כל תוכן, מידע, פרסומות, מוצרים, שירותים דעות ועמדות,
          המוצגים או המפורסמים באתר, נעשה על פי שיקול דעת המשתמש ועל אחריותו
          הבלעדית.
        </PolicyParagraph>

        <PolicyHeader>
          שימוש באתר ו/או בפורומים ו/או הוספת תגובות ו/או הודעות
        </PolicyHeader>
        <PolicyParagraph>
          מגדלור ייעוץ והכוונה שומר לעצמו את הזכות לא לפרסם, לשדר, להעלות לאתר
          בכל פורום שהוא, חומר, מידע, תכנים, שלא על פי חוקי מדינת ישראל, או
          חומר, מידע ותכנים העלולים לפגוע בפרטיותם של המשתמשים, או מידע לא מוסרי
          העלול לפגוע באופן ישיר/עקיף במשתמשים ובמפרסמים באתר. האתר רשאי להגביל
          או לאפשר נגישות למידע למי מהגולשים ומהמפרסמים, בין אם זה ליחיד ובין אם
          זה לרבים להגביל ו/או למנוע גישה למידע ו/או לתכנים ו/או לשימוש בכלים
          ו/או במוצרים הקשורים לאתר אם לגולש זה או אחר ואף אם לכלל ציבור הגולשים
          באתר על פי שיקול דעתו הבלעדי, כמו גם רשאי מגדלור ייעוץ והכוונה ו/או
          עורכיו ו/או מפעיליו להתנות את הגישה והשימוש הנ&quot;ל ברישום ומסירת
          מידע כפי שימצא לנכון לדרוש. לא תקנה לגולש ולמפרסם, גלישה ו/או שימוש
          כלשהו באתר, כל זכות לחייב את האתר או מפעיליו לספק לו שרות, או מידע, או
          מוצר, או נגישות לנ&quot;ל . האתר רשאי לשנות מעת לעת את המגבלות
          הנ&quot;ל ולא תהיה לגולש ולמפרסם כל זכות או טענה בשל כך. האתר יהיה
          רשאי לדרוש את חידוש הרישום ו/או הפרטים מגולשי האתר ו/ או משתמשי האתר
          לפי שיקול דעת מנהליו או עובדיו הבלעדי. הגולש יהיה רשאי לבקש שינויי או
          ביטול אפשרות השימוש במידע ששלח לאתר על ידי משלוח דואר אלקטרוני מפורש
          עם פרטיו ובקשתו לכתובת הדואר האלקטרוני הראשית של האתר. הגולש מצהיר
          בזאת כי הנו מתיר למגדלור ייעוץ והכוונה לעשות כל שימוש במידע ובפרטים
          שהוא מוסר לידיו בין היתר לצורכי משלוח מידע על שירותי האתר וצדדים
          שלישיים הקשורים בו כמו גם שתילת קבצי טקסט ותוכנה במחשבו ״עוגיות&quot;
          המאפשרים את זיהויו. הגולש מאשר כי הוא מאשר למגדלור ייעוץ והכוונה
          להעביר לצד שלישי את פרטיו ואת הפרטים אשר מסר לצוות מגדלור ייעוץ
          והכוונה אשר מפעיל האתר לרבות פרטי יצירת קשר של הגולש או מי מטעמו,
          פרטים אודות תיאור השיחה הטלפונית בין הגולש לבין צוות האתר, פרטים אודות
          מצבו הרפואי או מצבים תפקודיים אשר עלו בשיחה וכל פרט אחר אשר עלה בשיחה.
          בפנייתו למפרסמים המופיעים בדפי האתר השונים, באמצעות תיבת מסירת פרטים
          אישיים, מתיר בזאת הגולש לאתר להעביר את פרטיו למפרסמים על מנת שאלו יפנו
          אליו במידע ו/או בהצעות מחיר בדבר העסק ו/או החברה אותה הם מפרסמים.
          הגולש מאשר בזאת לאתר להעביר את פרטיו המלאים לאתר, למוסדות המפורסמים
          באתר ו/או לכל גוף אחר. יחד עם זאת, האתר אינו מתחייב להעביר פרטים אלה
          למוסדות ו/או לגופים אחרים. גולש שאינו מעוניין בכך רשאי ומתבקש לשלוח
          הודעה בכתב לדואר האלקטרוני של האתר והאתר ישתדל שפרטיו ימחקו ממאגר
          הכתובות. לצורך שיפור השירותים, המידע והתכנים שמגדלור ייעוץ והכוונה
          ו/או צדדים שלישיים מציעים ו/או יציעו למשתמש באופן אישי ו/או לכלל ו/או
          לחלק ממנויי אתר מגדלור ייעוץ והכוונה, ולצורך יידוע את המשתמש בדבר
          מוצרים ושירותים שונים העשויים לעניין את המשתמש והנמכרים על ידי האתר
          ו/או על ידי צדדים שלישיים, במקרה זה מגדלור ייעוץ והכוונה יהיה רשאי
          להביא לידיעת המשתמש מידע באמצעות: כתובת הדואר האלקטרוני שנמסרה על ידי
          המשתמש, לצג הטלפון הסלולרי של המשתמש באמצעות שירות הודעות כתובות על צג
          הטלפון, לתיבת הדואר הקולי של מכשיר הטלפון הנייד שברשות המשתמש, כתובת
          מגורים או כתובת אחרת, ככל שכל הפרטים הנ&quot;ל יימסרו על ידי המשתמש
          באופן ישיר או באופן עקיף בזמן הביקור באתר לצורך ניתוח ומסירת מידע
          סטטיסטי או אחר לצדדים שלישיים לרבות מפרסמים ליצירת קשר עם המשתמש במקרה
          הצורך. לצורך תחזוקת השירות שניתן על ידי אתר מגדלור ייעוץ והכוונה.
          לצורך מעקב אחר הפעילות של המשתמש באתר. בכפוף להוראות הסכם זה ובכפוף
          לכל דין. האתר יעשה שימוש בפרטים הנמסרים על ידי משתמש בהתאם להוראות כל
          דין ולרבות הוראות חוק הגנת הפרטיות, התשמ&quot;א – 1981, וכן בהתאם
          להסכמות והאישורים הניתנים על ידי המשתמש בטופס. אם יבקש המשתמש להפסיק
          לקבל מידע כלשהו ממגדלור ייעוץ והכוונה בהתאם למפורט לעיל, יוכל לפנות
          בכל עת לאתר למגדלור ייעוץ והכוונה ישתדל להיענות לפנייה בכתובת הדואר
          האלקטרוני info@mgdalor.com . הגולש אחראי באופן בלעדי לנכונות המידע
          אותו מפרסם או מעביר באמצעות האתר. מגדלור ייעוץ והכוונה אינו מקבל על
          עצמו כל אחריות לתכנים המפורסמים או מועברים בין המנויים. כמו כן, אין
          לעשות באתר כל שימוש מסחרי בדרך של משלוח פרסומות או בכל דרך אחרת. אין
          לפרסם או להעביר באמצעות האתר כל מידע שהוא שקרי, מעליב, משמיץ, מאיים,
          פוגע בפרטיות הזולת, פורנוגרפי, בעל אופי מיני, גזעני, או בלתי חוקי.
          &quot;מגדלור ייעוץ והכוונה&quot; אינו יכול לפקח/לשלוט על הקישורים
          ואינו מתחייב כי הקישורים הנוספים יובילו את המשתמשים לאתר/ים פעילים,
          אין לראות בקישורים אל האתר ודרכו לקישורים אחרים, משום מתן חסות,
          מחויבות, או תמיכה. על הגולשים חלה מלוא האחריות לגבי כל שימוש בתכנים
          שבאתר, לרבות שימוש באתרי משנה ובאתרים המקושרים ממנו. למען הסר ספק,
          האתר אינו אחראי לכל נזק, ישיר או עקיף, מכל סוג שייגרם למשתמשים כתוצאה
          משימוש והסתמכות על החומר המופיע באתר. המידע ו/או התכנים המופיעים באתר
          הנם באחריות המפרסם בלבד ולא תהיה לגולשים כל זכות או טענה נגד
          &quot;מגדלור ייעוץ והכוונה&quot; ו/או מנהליו ו/או עובדיו, בגין כל פגם
          ו/או ליקויי ו/או פגיעה כלשהי שנגרמה עקב פרסומם ו/או מכירתם ו/או שיווקם
          ו/או המלצה לשימוש כלשהו בהם. יכול ותהיה נגישות באתר גם למידע או לתכנים
          או לכלים או למוצרים שמקורם בצדדים שלישים, לרבות פרסומים וקישורים,
          תגובות ,צ&apos;טים ופורומים של משתמשים. הגולש מצהיר ומסכים בזאת, כי
          מגדלור ייעוץ והכוונה אינו אחראי למידע או לתכנים שמקורם בצדדים שלישים,
          לרבות לעניין פגיעה בפרטיות, הוצאת דיבה, לשון הרע, הפרת זכויות יוצרים
          או זכות מוסרית, כמו גם שאינו אחראי לאמינות, מהימנות, אמיתות או נכונות
          המידע או התכנים או הכלים או המוצרים הנ&quot;ל. האחריות הבלעדית תחול על
          הצדדים השלישים ועל הגולש העושה בהם שימוש. חוות הדעת אינן מסופקות ו/או
          נערכות על ידי מפעיל האתר והן מוצגות כפי שהן, מבלי שמפעיל האתר בודק את
          מהימנותן, דיוקן ו/או נכונותן, ומפעיל האתר לא ישא באחריות לכל אי התאמה,
          טעות, אי דיוקים או לכל נזק עקיף או ישיר שיגרם לכל צד שהוא כתוצאה מחוות
          הדעת. מבלי לגרוע ממה שהוזכר מקודם, ידוע למשתמש, כי מפעיל האתר רשאי
          לערוך, לשנות, לתקן או למחוק חוות דעת, בשלמותן ו/או בחלקן, ו/או כל חומר
          ו/או מידע על פי שיקול דעתו הבלעדי והמוחלט, ו/או שלא להציגן כלל, וזאת
          מבלי לתת על כך הודעה מראש למשתמש. הגולש מתחייב לפצות ו/או לשפות את
          מפעיל האתר בגין כל הוצאה ו/או נזק שיגרמו לו עקב הפרת הוראות תנאי שימוש
          אלה ו/או פגיעה בזכויות צד שלישי כלשהו בשל פרסום חוות הדעת על ידו.
          מובהר בזאת, כי מפעיל האתר יהיה רשאי לנקוט כנגד משתמש שיפר תנאי שימוש
          אלה בכל האמצעים העומדים לרשותו על פי דין, וכן יהיה רשאי להעביר את
          פרטיו לכל גורם שייפגע מפרסום חוות הדעת. מפעיל האתר יהיה רשאי להפסיק את
          פעילות חוות הדעת ללא כל הודעה מוקדמת, ולמשתמש לא תהיה כל טענה כלפי
          מפעיל האתר בעניין זה. רישומי המחשב של מפעיל האתר בדבר הפעולות המתבצעות
          דרך האתר יהוו ראיה לכאורה לנכונות הפעולות.
        </PolicyParagraph>

        <PolicyHeader>נזק והגבלת אחריות</PolicyHeader>
        <PolicyParagraph>
          אתר מגדלור ייעוץ והכוונה מכיל בין היתר מנועי חיפוש, קישורים אל מקורות
          מידע, משאבים ושירותים נוספים המתפרסמים חלקם ע&quot;י מפעיל האתר וחלקם
          על ידי צדדים אחרים. כל הנתונים והפרמטרים המוצגים באתר מתייחסים להרכב
          השירותים והמתקנים המצויים במוסדות המפורסמים באתר, לרבות ההתייחסות
          למחירי השירותים ולדמי השימוש במתקנים הנ&quot;ל אינם מחייבים אף אחד
          מהצדדים. פרסום הפרמטרים, השירותים, המחירים והמתקנים, הינו באחריות
          המפרסמים בלבד, ואינו מחייב בכל אופן שהוא את האתר גולשי ומשתמשי האתר.
          בכוונת הנהלת האתר כי האתר ושירותיו יהיו זמינים בכל עת. יחד עם זאת אין
          ביכולת הנהלת האתר להתחייב לזמינות רצופה ללא תקלות וללא
          &quot;נפילות&quot;. כמו כן רשאית הנהלת האתר להפסיק את השימוש באתר מעת
          לעת לצורכי תחזוקתו וארגונו. לא יינתן כל פיצוי כספי/זיכוי בשל תקלות או
          הפסקות בשירות. הגולש באתר מצהיר, כי ידוע לו והוא מסכים לכך, שהוא עשוי
          להינזק בנסיבות ובמגבלות הגלישה באינטרנט, בין בשליטת האתר ובין שלא
          בשליטתו, יתכנו שיבושים, תקלות, מידע מוטעה, אבדן מידע, אובדן אפשרות
          הגלישה לרבות נפילת שרתים, הפסקות חשמל, האקרים, וירוסים, פריצות מחשבים,
          גניבת מידע אישי או כלכלי ושימוש במידע זה ו/או בפרטים שנמסרו על ידי
          הגולש לאתר, ועוד חבלות ו/או טעויות אנוש וכל תקלה שהיא ולמרות ידיעה
          והכרה באפשרות הגולש להינזק עקב הנ&quot;ל, הגולש מסכים כי אין האתר ו/או
          צדדים שלישיים הקשורים בו, אחראים בכל צורה שהיא לנזקים אפשריים אלו
          והגולש מתחייב שלא לתבוע ו/או לטעון כנגד האתר ו/או צד שלישי בגין תקלות
          או נזקים אלו. למען הסר ספק, מפעיל האתר אינו אחראי לכל נזק הנגרם, או
          העלול להיגרם, כתוצאה של משלוח מידע חסוי, פרטי, או אישי דרך האתר. הגולש
          באתר מצהיר כי כל הפרטים אותם רשם בטופס ההרשמה הם נכונים ומדויקים,
          לרבות שם, מין , תאריך לידה, דואר אלקטרוני, כתובת ישוב, מצב משפחתי,
          מספר טלפון וכן כל שאר הפרטים אותם ממלאים הנרשמים לאתר ו/או המתעניינים
          בפתרונות דיור לגיל השלישי ו/או לצורך השמת כח אדם בטופס הרישום. בנוסף
          לכך, חל על גולש האתר איסור מוחלט לרשום אדם אחר לאתר ללא הסכמתו ואין
          לציין דואר אלקטרוני אחר, מספר טלפון אחר, כתובת או תמונה או כל פרט מזהה
          של אדם אחר ללא הסכמתו. הגולש באתר מצהיר כי לא יהיה ראשי למכור מוצרים
          או שירותים כלשהם במסגרת השירות. וידוע לו כי חל עליו איסור מוחלט לעסוק
          בפרסום או בשכנוע חברים/מנויים/גולשים אחרים, לקנות או למכור מוצרים או
          שירותים מכל סוג שהוא. בנוסף לכך לא יהיה ראשי להעביר מכתבי שרשרת, ו/או
          דואר זבל לחברים אחרים באתר. כנגד התנהגות כאמור לעיל. הגולש מתחייב
          שהפרטים שהנו מעלה לאתר באמצעים המתאימים לכך כגון טפסים, מאמרים, קבצים,
          טקסטים, נתונים וכו&apos;, הנם בהתאם לכל דין, ללא הפרת זכויות יוצרים,
          ללא הפרת זכותם המוסרית של אחרים, ללא הפרת צו איסור פרסום, ללא לשון הרע
          ו/או הוצאת דיבה ו/או פגיעה בפרטיות. הגולש מצהיר ומסכים בזאת כי הנו
          אחראי בלעדי לכל התכנים ו/או המידע שנמסר על ידו ומתחייב לפצות מיידית את
          האתר בגין כל נזק ישיר או עקיף ( לרבות הוצאות משפטיות ) שיגרם לאתר
          כתוצאה מהפרת תנאי השימוש ו/או מתביעה כלשהי ו/או מפרסום מוטעה ו/או
          מהפרת החוק. המשתמשים באתר מסכימים בזאת, כי כל תביעה /ואו דרישה נגד
          האתר תוגבל לתקופה של 6 חודשים ממועד הופעתה, והצדדים רואים בכך הסכם
          לתקופת התיישנות כמשמעו בסעיף 91 לחוק ההתיישנות תשי&quot;ח – 1985, וזאת
          לאור אופי השימוש הייחודי באתר אינטרנט. בכל מקרה, המשתמש פוטר בזאת את
          מנהליה ו/או עובדיה ו/או נושאי משרה ו/או הבעלים של האתר מאחריות כלשהי
          לרבות מאחריות אישית . כל תביעה כנגד &quot;מגדלו ייעוץ והכוונה&quot; ,
          תתברר אך ורק בבתי המשפט המוסמכים בישראל, סמכות השיפוט המקומית בכל
          הקשור לתנאי השימוש והנובע מהם, היא אך ורק לבית המשפט המוסמך בחיפה.
          מגדלור ייעוץ והכוונה שומר לעצמו את הזכות לאסוף מידע, תכנים, חומר,
          פרסומים, הנוגעים לדפוסי השימוש באתר ולהשתמש במידע לצורך שיפור השירותים
          הניתנים באתר והפעלתו.
        </PolicyParagraph>

        <PolicyHeader customSx={{ textAlign: 'center', mt: 4 }}>
          בכבוד רב,
        </PolicyHeader>
        <PolicyHeader customSx={{ textAlign: 'center' }}>
          הנהלת אתר מגדלור ייעוץ והכוונה
        </PolicyHeader>
      </Card>
    </>
  );
};

export default PrivacyPolicy;
