import { FormControl, InputLabel, Select } from '@mui/material';
import { uniqueId } from 'lodash';
import { array, func } from 'prop-types';
import KnowledgeTestPreviewNameAndContentListItem from '../KnowledgeCommunity/InternalCommunity/KnowledgeTestPreviewNameAndContentListItem';

const LoadFromOtherSurveySelectField = ({
  handleLoadFromPreviousSurvies,
  previousSurvies
}) => (
  <FormControl fullWidth sx={{ maxWidth: 350 }}>
    <InputLabel id="load-from-previous-tests-select-label">
      האם לטעון נתונים ממבדק קודם?
    </InputLabel>
    <Select
      labelId="load-from-previous-tests-select-label"
      id="load-from-previous-tests-select"
      label="האם לטעון נתונים ממבדק קודם?"
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 400
          }
        },
        disablePortal: true
      }}
      //   onChange={handleLoadFromPreviousSurvies}
    >
      {previousSurvies &&
        previousSurvies.length > 0 &&
        previousSurvies.map((previousSurvey) => (
          <KnowledgeTestPreviewNameAndContentListItem
            key={`${uniqueId(previousSurvey.post_id)}`}
            isBtn={Boolean(true)}
            showDivider={Boolean(true)}
            handleLoadFromPreviousSurvies={handleLoadFromPreviousSurvies}
            post={previousSurvey}
          />
        ))}
    </Select>
  </FormControl>
);

LoadFromOtherSurveySelectField.propTypes = {
  handleLoadFromPreviousSurvies: func,
  previousSurvies: array
};

export default LoadFromOtherSurveySelectField;
