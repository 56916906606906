import { isDate } from 'lodash';
import moment from 'moment';

const getMomentDateValue = (date, format = 'DD/MM/YYYY') => {
  if (!date) return null;

  if (moment.isMoment(date)) {
    return date;
  }

  if (isDate(date)) {
    return moment(date);
  }

  if (date.seconds || date._seconds) {
    return moment.unix(date.seconds || date._seconds);
  }

  return moment();
};

export default getMomentDateValue;
