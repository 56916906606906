import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { uniqueId } from 'lodash';
import { func } from 'prop-types';
import { useEffect, useRef } from 'react';
import theme from 'src/theme';

const ExcellenceSection = ({ onScorllToContactForm }) => {
  ExcellenceSection.propTypes = {
    onScorllToContactForm: func
  };
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const cardsRef = useRef([]);

  useEffect(() => {
    const cards = cardsRef.current;

    // Fix the title animation
    gsap.fromTo(
      titleRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 70%',
          end: 'top 40%',
          toggleActions: 'play complete none none',
          // markers: true, // Uncomment for debugging
          scrub: false
        }
      }
    );

    // Fix the cards animation
    cards.forEach((card, index) => {
      gsap.fromTo(
        card,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.6,
          delay: index * 0.15,
          scrollTrigger: {
            trigger: card,
            start: 'top 85%',
            end: 'top 65%',
            toggleActions: 'play complete none none',
            scrub: false
          }
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const excellenceItems = [
    {
      title: 'מעודדים יציבות תעסוקתית',
      id: 'stability',
      color: '#4F86C6',
      description:
        'ככל שהעובדים נמצאים יותר זמן וצוברים וותק בארגון ולא נודדים ממקום למקום יקבלו ניקוד יותר גבוה'
    },
    {
      title: 'מעודדים השכלה',
      id: 'education',
      color: '#6FB3B8',
      description: 'ככל שתצבור יותר ידע ולמידה תקבל ניקוד יותר גבוה'
    },
    {
      title: 'מעודדים התקדמות',
      id: 'progress',
      color: '#388697',
      description: 'ככל שמאיישים תפקידים יותר גבוהים, כך צוברים ניקוד יותר גבוה'
    },
    {
      title: 'מעודדים הישגיות',
      id: 'achievement',
      color: '#1C448E',
      description: 'ככל שתצבור יותר ניקוד, תדורג יותר גבוה בהשוואה לאחרים'
    }
  ];

  return (
    <Box
      ref={sectionRef}
      sx={{
        py: { xs: 8, md: 12 },
        position: 'relative',
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.common.white,
          0.9
        )}, ${alpha(
          theme.palette.common.white,
          0.95
        )}), url(https://images.pexels.com/photos/12301178/pexels-photo-12301178.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          ref={titleRef}
          sx={{
            textAlign: 'center',
            mb: 6
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 800,
              color: theme.palette.primary.dark,
              display: 'inline-block',
              position: 'relative',
              pb: 2,
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '80px',
                height: '4px',
                backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`
              }
            }}
          >
            עקרונות המצוינות שלנו
          </Typography>

          <Typography
            variant="h6"
            sx={{
              mt: 2,
              maxWidth: '700px',
              mx: 'auto',
              color: theme.palette.text.secondary,
              fontWeight: 400
            }}
          >
            אנו מאמינים בפיתוח מתמיד ובתגמול על מצוינות בכל היבטי העבודה שלנו
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {excellenceItems.map((item, index) => (
            <Grid key={`${item.id}-${uniqueId()}`} item xs={12} sm={6} md={3}>
              <Card
                ref={(el) => (cardsRef.current[index] = el)}
                sx={{
                  height: '100%',
                  borderRadius: '16px',
                  overflow: 'hidden',
                  boxShadow: `0 10px 20px ${alpha(
                    theme.palette.common.black,
                    0.08
                  )}`,
                  transition: 'all 0.3s ease',
                  position: 'relative',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: `0 15px 30px ${alpha(
                      theme.palette.common.black,
                      0.12
                    )}`
                  },
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '6px',
                    background: item.color
                  }
                }}
              >
                <CardContent sx={{ p: 3 }}>
                  <Box
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '12px',
                      background: alpha(item.color, 0.15),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 2
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        color: item.color
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>

                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      mb: 1.5,
                      color: theme.palette.text.primary
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.text.secondary,
                      lineHeight: 1.6
                    }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            mt: 8,
            pt: 4,
            textAlign: 'center',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100px',
              height: '2px',
              background: alpha(theme.palette.primary.main, 0.3)
            }
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.dark,
              mb: 2
            }}
          >
            הצטרפו אלינו במסע למצוינות
          </Typography>

          <Typography
            variant="body1"
            sx={{
              maxWidth: '600px',
              mx: 'auto',
              mb: 4,
              color: theme.palette.text.secondary
            }}
          >
            אנו מזמינים אתכם להיות חלק מהמהפכה בטיפול בגיל השלישי, ולהיות שותפים
            ביצירת סטנדרט חדש של מצוינות
          </Typography>

          <Box
            component="button"
            sx={{
              padding: '12px 30px',
              borderRadius: '50px',
              background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
              color: 'white',
              fontWeight: 600,
              fontSize: '1rem',
              border: 'none',
              cursor: 'pointer',
              boxShadow: `0 10px 20px ${alpha(
                theme.palette.primary.main,
                0.3
              )}`,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: `0 15px 30px ${alpha(
                  theme.palette.primary.main,
                  0.4
                )}`
              }
            }}
            onClick={onScorllToContactForm}
          >
            צרו קשר עוד היום
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ExcellenceSection;
