import {
  ArrowForward,
  FavoriteBorderOutlined,
  InfoOutlined
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  Icon,
  Skeleton,
  Slide,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import Lottie from 'lottie-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fallbackImage1 from 'src/assets/Brilliant Solution For Your Home/1.png';
import homeLottie from 'src/assets/home.json';
import calendarLottie from 'src/assets/lottie/calendar.json';
import flower from 'src/assets/orgPage/flower.svg';
import prints from 'src/assets/orgPage/prints.svg';
import treeBranches from 'src/assets/orgPage/treeBranches.svg';
import available_funds from 'src/common/availableFunds';
import kosherOptions from 'src/common/kosherOptions';
import theme from 'src/theme';
import calcNumberOfBedsInOrg from 'src/utils/calcNumberOfBedsInOrg';
import getCityAndDistrictOfOption from 'src/utils/getCityAndDistrictOfOption';
import MinimalOrgPageMap from '../map/MinimalOrgPageMap/MinimalOrgPageMap';
import OrgPageContactForm from '../shared/OrgPageContactForm/OrgPageContactForm';
import AnsweredFaq from './AnsweredFaq';
import AvailableOrgDepartments from './AvailableOrgDepartments';
import GradientButton from './GradientButton';
import OrgDetailsTableInOrgPage from './OrgDetailsTableInOrgPage';
import OrgImgArc from './OrgImgArc';
import OrgPageHeroSlider from './OrgPageHeroSlider';
import OrgPicsSliderShow from './OrgPicsSliderShow';
import OrgReportsList from './OrgReportsList';
import PricesCardsInOrgPage from './PricesCardsInOrgPage';
import bed from './orgDetailsIcons/bed.json';
import cctv from './orgDetailsIcons/cctv.json';
import fall from './orgDetailsIcons/fall.json';
import ivdrip from './orgDetailsIcons/ivdrip.json';
import kitchen from './orgDetailsIcons/kitchen.json';
import kosher from './orgDetailsIcons/kosher.json';
import './orgPage.scss';
import './style.css';

const SectionHeader = ({ children, customSx, secondaryUpperLabel }) => {
  SectionHeader.propTypes = {
    children: PropTypes.node,
    customSx: PropTypes.object,
    secondaryUpperLabel: PropTypes.string
  };
  return (
    <Stack>
      {secondaryUpperLabel && (
        <Typography sx={{ color: alpha('#0a474a', 0.5) }}>
          {secondaryUpperLabel}
        </Typography>
      )}
      <Typography
        sx={{
          textAlign: 'center',
          mb: 2,
          fontSize: '3rem',
          color: '#0a474a',
          ...customSx
        }}
        variant="h3"
        fontWeight={600}
      >
        {children}
      </Typography>
    </Stack>
  );
};

const OrgProfile = ({ orgPageValues, handleGoBack }) => {
  OrgProfile.propTypes = {
    orgPageValues: PropTypes.object,
    handleGoBack: PropTypes.func
  };
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', { keyPrefix: 'orgPage' });
  const [mainEntryDetailsCardsValues, setMainEntryDetailsCardsValues] =
    useState({ residents: 0, exp: 0 });

  const calcExperienceYears = (date) => {
    const yearNow = new Date().getFullYear();

    const year_of_establishment = moment(date.seconds * 1000)
      .toDate()
      .getFullYear();
    // console.log(yearNow - year_of_establishment);
    let calc = 0;
    if (!yearNow || !year_of_establishment) {
      calc = 1;
    } else if (
      yearNow &&
      year_of_establishment &&
      yearNow - year_of_establishment <= 1
    ) {
      calc = 1;
    } else {
      calc = yearNow - year_of_establishment;
    }
    return calc;
  };

  const findImgOfThisOrder = (orderToFind) => {
    if (
      orgPageValues &&
      orgPageValues.org_pictures &&
      orgPageValues.org_pictures.length > 0
    ) {
      const indexOfFirst = orgPageValues.org_pictures.findIndex(
        (el) => el.metadata.order === orderToFind.toString()
      );
      // console.log(orgPageValues.org_pictures[0]);
      if (indexOfFirst > -1) {
        return orgPageValues.org_pictures[indexOfFirst].url;
      }
      return orgPageValues.org_pictures[0].url;
    }
    return fallbackImage1;
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        if (orgPageValues) {
          const tempAddress = await getCityAndDistrictOfOption({
            _geoloc: {
              lat: orgPageValues._geoloc.lat,
              lng: orgPageValues._geoloc.lng
            }
          });
          // console.log(tempAddress);
          orgPageValues.formattedAddress = tempAddress;
          // setFormattedOrgAddress(tempAddress);
          const numOfBeds = calcNumberOfBedsInOrg(
            orgPageValues.number_of_beds_in_org
          );
          const exp = calcExperienceYears(orgPageValues.date_of_establishment);
          setMainEntryDetailsCardsValues({
            ...mainEntryDetailsCardsValues,
            residents: numOfBeds,
            exp
          });
        }
      } catch (error) {
        console.log('Error fetching org data', error);
      }
    };
    fetchOrgData();
  }, [orgPageValues]);
  return (
    <Box component="section">
      <Box
        minHeight="7rem"
        width="100%"
        sx={{
          backgroundColor: '#8eb59e',
          minHeight: '9rem',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          px: 4
        }}
      >
        <Button
          sx={{
            zIndex: 2,
            color: theme.palette.primary.contrastText,
            fontSize: '1rem'
          }}
          onClick={() => handleGoBack()}
        >
          <ArrowForward />
          {t('back')}
        </Button>
      </Box>
      <Grid
        sx={{ position: 'relative' }}
        container
        justifyContent="center"
        // py={6}
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sx={{
            height: matchDownMd ? '130vh' : 'calc(100vh - 64px)',
            // height:
            backgroundColor: '#8eb59e',
            pt: 3,
            // pb: ,
            minHeight: 'fit-content',
            position: 'relative'
          }}
          container
        >
          {/* </Box> */}
          <OrgPageHeroSlider orgPageValues={orgPageValues} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          // spacing={2}
          sx={{
            py: 0,
            mt: 0,
            height: 500,
            // height: matchDownMd ? 1000 : 500,
            // flexDirection: matchDownMd ? 'column-reverse' : 'row',
            pt: '0 !important',
            width: '100%',
            backgroundSize: matchDownMd && '100% 120%',
            backgroundImage: matchDownMd && `url(${findImgOfThisOrder(1)})`,
            // background
            // background:
            //   matchDownMd &&
            //   `url(${findImgOfThisOrder(1)}) no-repeat fixed`,
            px: matchDownMd && 2
          }}
        >
          <Stack
            // sx={{ width: '100%' }}
            direction="row"
            gap={2}
            sx={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Hidden mdDown>
              <CardMedia
                // component="img"
                image={findImgOfThisOrder(1)}
                sx={{
                  // width: !matchDownMd ? '50%' : '100%',
                  // height: matchDownMd ? '50%' : '100%',
                  width: 600,
                  objectFit: 'fill',
                  // height: '100%',
                  // maxHeight: '700px',
                  // height: '100%',
                  backgroundSize: '100%',
                  backgroundPositionY: '55%'
                  // maxWidth: 500
                }}
              />
            </Hidden>
            {/* <Stack
              sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
            > */}
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Card
                elevation={8}
                sx={{
                  minHeight: 300,
                  maxHeight: 400,
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  maxWidth: 700
                }}
              >
                <SectionHeader>{t('aLittleAboutUs')}</SectionHeader>
                <CardContent sx={{ maxHeight: 380, overflow: 'auto' }}>
                  <Typography
                    sx={{
                      overflowY: 'auto',
                      textAlign: 'justify',
                      mx: 'auto'
                    }}
                  >
                    {orgPageValues.description}
                  </Typography>
                </CardContent>
                <Box>
                  <Divider />
                  <CardActions>
                    <Stack gap={1} direction="row">
                      <Icon>
                        <InfoOutlined />
                      </Icon>
                      <Typography
                        sx={{ color: theme.palette.primary.main }}
                        variant="body1"
                      >
                        {`התיאור שמופיע נמסר ומתוחזק על ידי הארגון ${orgPageValues.org_name}`}
                      </Typography>
                    </Stack>
                  </CardActions>
                </Box>
              </Card>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            // mt: !matchDownMd && -8,
            width: '100%',
            backgroundColor: '#8eb59e',
            position: 'relative'
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: '30vh',
              backgroundColor: '#8eb59e',
              position: 'relative'
            }}
          >
            <SectionHeader>{t('aGlimpse')}</SectionHeader>
            <CardMedia
              // component="img"
              alt="shapes"
              image={flower}
              sx={{
                position: 'absolute',
                bottom: -4,
                right: 0,
                width: '70px',
                height: '70px'
                // transform: 'rotate(15deg)'
              }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{ px: 2, mt: -8, position: 'relative', zIndex: 2 }}
          >
            {orgPageValues ? (
              <Grid
                container
                spacing={5}
                item
                xs={12}
                sx={{
                  width: '90%',
                  justifyContent: 'center',
                  // height: '420px',
                  alignItems: 'center'
                  // my: 3
                  // mt: -2
                }}
              >
                {[
                  {
                    description: 'מיטות',
                    lottie: homeLottie,
                    name: 'residents'
                  },
                  {
                    description: 'שנים וותק',
                    lottie: calendarLottie,
                    name: 'exp'
                  }
                ].map((card) => (
                  <Grid
                    key={`count-card-${card.name}-${uniqueId('card')}`}
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    sx={{ maxWidth: '400px' }}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        backgroundColor: '#ecf1eb',
                        height: '150px'
                      }}
                      elevation={8}
                      className="entryDetailCard"
                    >
                      <Avatar
                        sx={{
                          width: 100,
                          height: 100,
                          backgroundColor: 'rgba(0,0,0,0)'
                        }}
                        variant="circular"
                        className="entryDetailIcon"
                      >
                        <Lottie
                          style={{
                            width: '70% !important',
                            height: '120px',
                            minWidth: '70px'
                          }}
                          autoplay
                          animationData={card.lottie}
                        />
                      </Avatar>
                      <Stack sx={{ color: '#8fa8a2', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontSize: '3rem',
                            color: '#8fa8a2',
                            fontWeight: 800
                          }}
                        >
                          {mainEntryDetailsCardsValues[card.name]}
                        </Typography>
                        <Typography
                          sx={{ fontSize: '2rem', textAlign: 'center' }}
                        >
                          {card.description}
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '100%' }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          sx={{ mb: '30px', py: 6, backgroundColor: '#8eb59e' }}
          container
          item
          xs={12}
        >
          <Grid item xs={12} sx={{ height: 'fit-content' }}>
            <SectionHeader>{`${t('suitableFor')} ?`}</SectionHeader>
          </Grid>
          <AvailableOrgDepartments orgPageValues={orgPageValues} />
        </Grid>
        <Grid key="availableFunds-container" container item xs={12} py={3}>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {orgPageValues && orgPageValues.org_pictures ? (
                <>
                  {orgPageValues && orgPageValues.org_pictures.length > 0 ? (
                    <OrgImgArc imgUrl={findImgOfThisOrder(2)} />
                  ) : (
                    <OrgImgArc imgUrl={fallbackImage1} />
                  )}
                </>
              ) : (
                <Skeleton
                  variant="circular"
                  sx={{ borderRadius: '100%', width: '200px', height: '200px' }}
                />
              )}
            </Grid>
          </Hidden>
          <Grid container sx={{ px: 4 }} item xs={12} md={6}>
            <Grid item xs={12} sx={{ height: 'fit-content' }}>
              <SectionHeader
                secondaryUpperLabel={t('whatDoWeReceive')}
                customSx={{ textAlign: 'start' }}
              >
                {t('availableFunds')}
              </SectionHeader>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              {orgPageValues.available_funds &&
                orgPageValues.available_funds.length > 0 &&
                orgPageValues.available_funds.map((fund) => {
                  const index = available_funds.findIndex(
                    (el) => el.name === fund
                  );
                  if (index > -1) {
                    return (
                      <Grid
                        key={`${uniqueId('fund')}-${fund.name}`}
                        item
                        xs={12}
                      >
                        <Stack
                          gap={1}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Avatar
                            variant="rounded"
                            sx={{
                              boxShadow: theme.shadows[8],
                              // backgroundColor: '#a4c2c4',
                              color: theme.palette.primary.contrastText,
                              p: 4,
                              backgroundImage:
                                'linear-gradient(35deg, #a4c2c4, #8eb59e)',
                              backgroundAttachment: 'fixed'
                            }}
                          >
                            {available_funds[index].icon}
                          </Avatar>
                          <Typography
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              textAlign: 'center'
                            }}
                            variant="h4"
                          >
                            {available_funds[index].label}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  }
                  return (
                    <Stack gap={1} direction="column" alignItems="center">
                      <Avatar
                        variant="rounded"
                        sx={{
                          boxShadow: theme.shadows[8],
                          backgroundColor: theme.palette.primary.main
                        }}
                      >
                        <FavoriteBorderOutlined />
                      </Avatar>
                      <Typography
                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                        variant="subtitle2"
                      >
                        .
                      </Typography>
                    </Stack>
                  );
                })}
            </Grid>
          </Grid>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {orgPageValues && orgPageValues.org_pictures ? (
                <>
                  {orgPageValues && orgPageValues.org_pictures.length > 0 ? (
                    <OrgImgArc imgUrl={orgPageValues.org_pictures[0].url} />
                  ) : (
                    <OrgImgArc imgUrl={fallbackImage1} />
                  )}
                </>
              ) : (
                <Skeleton
                  variant="circular"
                  sx={{ borderRadius: '100%', width: '200px', height: '200px' }}
                />
              )}
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            backgroundColor: '#8eb59e',
            mt: 2
          }}
          container
          spacing={4}
        >
          <Grid item xs={12}>
            <SectionHeader
              customSx={{
                color: '#b6492d'
              }}
            >
              תמונות מהארגון
            </SectionHeader>
          </Grid>
          <OrgPicsSliderShow orgPageValues={orgPageValues} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          // md={10}
          py={3}
          // mx={{ xs: 'auto', sm: 3, md: 1 }}
          spacing={2}
          sx={{
            // mt: 4,
            backgroundColor: '#a4c2c4',
            position: 'relative'
          }}
        >
          <CardMedia
            // component="img"
            image={prints}
            component="img"
            loading="lazy"
            alt="shapes"
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 0,
              width: '170px',
              height: '150px',
              backgroundPositionX: '20px',
              backgroundOrigin: 'border-box'
              // filter: 'brightness(0) invert(1)'
              // transform: 'rotate(15deg)',
            }}
          />
          <Grid sx={{ position: 'relative', zIndex: 2 }} item xs={12}>
            <SectionHeader>{t('aLittleMoreAboutUs')}</SectionHeader>
          </Grid>
          <Grid
            item
            xs={10}
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: 'auto'
            }}
            spacing={2}
          >
            <OrgDetailsTableInOrgPage
              orgPageValuesToRender={[
                {
                  icon: kosher,
                  value: orgPageValues.kosher,
                  label: t('kosher'),
                  border: `6px solid ${alpha(
                    theme.palette.primary.light,
                    0.3
                  )}`,
                  description:
                    kosherOptions.find(
                      (el) => el.name === orgPageValues.kosher
                    ) &&
                    kosherOptions.find((el) => el.name === orgPageValues.kosher)
                      .label
                      ? kosherOptions.find(
                          (el) => el.name === orgPageValues.kosher
                        ).label
                      : t('orgDidntTellUs')
                },
                {
                  icon: ivdrip,
                  value: orgPageValues.iv_treatment,
                  label: t('iv'),
                  border: orgPageValues.iv_treatment
                    ? `6px solid ${alpha(theme.palette.success.light, 0.3)}`
                    : `6px solid ${alpha(theme.palette.error.light, 0.3)}`,
                  description: orgPageValues.iv_treatment
                    ? t('youCan')
                    : t('youCant')
                },
                {
                  icon: cctv,
                  value: orgPageValues.cctv,
                  label: t('cctv'),
                  border: orgPageValues.cctv
                    ? `6px solid ${alpha(theme.palette.success.light, 0.3)}`
                    : `6px solid ${alpha(theme.palette.error.light, 0.3)}`,
                  description: orgPageValues.cctv
                    ? t('weHave')
                    : t('weDontHave'),
                  isArray: true,
                  name: 'cctv'
                },
                {
                  icon: kitchen,
                  value: orgPageValues.kitchen,
                  label: t('kitchen'),
                  border: `6px solid ${alpha(
                    theme.palette.primary.light,
                    0.3
                  )}`,
                  description: orgPageValues.kitchen
                    ? t(orgPageValues.kitchen)
                    : t('orgDidntTellUs')
                },
                {
                  icon: bed,
                  value: calcNumberOfBedsInOrg(
                    orgPageValues.number_of_beds_in_org
                  ),
                  label: t('beds'),
                  border: `6px solid ${alpha(
                    theme.palette.primary.light,
                    0.3
                  )}`,
                  description: `${t('weHave')} ${calcNumberOfBedsInOrg(
                    orgPageValues.number_of_beds_in_org
                  )}`
                },
                {
                  icon: fall,
                  value: orgPageValues.fall_prevention,
                  label: t('fallPrevention'),
                  border: orgPageValues.fall_prevention
                    ? `6px solid ${alpha(theme.palette.success.light, 0.3)}`
                    : `6px solid ${alpha(theme.palette.error.light, 0.3)}`,
                  description: orgPageValues.fall_prevention
                    ? t('weHaveAdvanced')
                    : t('weDontHave')
                }
              ]}
            />
          </Grid>
          <CardMedia
            // component="img"
            image={prints}
            component="img"
            loading="lazy"
            alt="shapes"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '170px',
              height: '150px',
              backgroundPositionX: '20px',
              backgroundOrigin: 'border-box',
              filter: 'brightness(0) invert(1)'
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          // md={10}
          py={3}
          spacing={2}
        >
          <Grid item xs={12}>
            <SectionHeader>{t('prices')}</SectionHeader>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <PricesCardsInOrgPage
              tags={orgPageValues.tags}
              orgPrices={orgPageValues.pricing}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mx: 'auto',
            position: 'relative',
            height: 'fit-content',
            // backgroundColor: '#8eb59e',
            my: 3
          }}
        >
          <GradientButton />
        </Grid>
        {orgPageValues.answered_faq && (
          <Slide in={Boolean(orgPageValues.answered_faq)} direction="up">
            <Grid
              container
              item
              xs={12}
              md={10}
              py={3}
              mx={{ xs: 'auto', sm: 3, md: 1 }}
              spacing={2}
              sx={{ position: 'relative' }}
            >
              <CardMedia
                component="img"
                alt="shapes"
                loading="lazy"
                src={treeBranches}
                sx={{
                  position: 'absolute',
                  width: '120px',
                  height: '60px',
                  // mx: 'auto',
                  zIndex: 1,
                  top: '-5px',
                  left: '47%'
                }}
              />
              <Grid item xs={12}>
                <SectionHeader>{t('FAQ')}</SectionHeader>
              </Grid>
              <Grid item xs={12}>
                <AnsweredFaq orgPageValues={orgPageValues} />
              </Grid>
            </Grid>
          </Slide>
        )}
        <Grid
          container
          item
          xs={12}
          // md={10}
          py={3}
          // mx={{ xs: 'auto', sm: 3, md: 1 }}
          sx={{
            backgroundColor: '#f4f2f2'
          }}
        >
          <Grid item xs={12}>
            <SectionHeader>{t('reports')}</SectionHeader>
          </Grid>
          <Grid
            item
            xs={10}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              width: '100%',
              pb: 2
            }}
          >
            <OrgReportsList
              key="org-page-reports-list"
              orgId={orgPageValues.user_doc_id}
            />
          </Grid>
        </Grid>
        {orgPageValues && orgPageValues._geoloc ? (
          <Grid
            container
            item
            xs={12}
            md={10}
            py={3}
            mx={{ xs: 'auto', sm: 3, md: 1 }}
            spacing={2}
          >
            <Grid item xs={12}>
              <SectionHeader>{t('location')}</SectionHeader>
            </Grid>
            <Grid item xs={12} md={10} sx={{ mx: 'auto' }}>
              <Card id="orgLocation" sx={{ width: '100%' }} elevation={18}>
                <MinimalOrgPageMap
                  key="map-in-org-page"
                  selectedOrg={orgPageValues._geoloc}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Skeleton />
        )}
        <Grid
          id="contact"
          sx={{
            position: 'relative'
          }}
          item
          xs={12}
        >
          <svg
            style={{
              position: 'absolute',
              width: '200px',
              height: '200px',
              left: '130px',
              top: '150px',
              filter: 'opacity(0.5)'
            }}
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#FF0066"
              d="M56.1,-48.8C66.6,-31.5,64.9,-8.6,57.7,9.4C50.6,27.4,38.2,40.4,22.3,49.2C6.4,57.9,-12.9,62.5,-24,55.1C-35,47.8,-37.8,28.5,-41.8,9.8C-45.8,-8.8,-51.1,-26.9,-44.4,-43.3C-37.6,-59.7,-18.8,-74.4,2,-76C22.8,-77.5,45.6,-66,56.1,-48.8Z"
              transform="translate(100 100)"
            />
          </svg>
          <OrgPageContactForm
            orgToContact={orgPageValues}
            customSx={{
              backdropFilter: 'blur(20px)'
            }}
            key="org-page-contact-form"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrgProfile;
