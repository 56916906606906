import {
  collection,
  getDocs,
  orderBy,
  query,
  where
} from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { db, functions } from 'src/_firebase/firebase';
import {
  deleteFromIndexedDB,
  generateCacheKey,
  getFromIndexedDB,
  isDataStale,
  saveToIndexedDB
} from 'src/utils/dashboard_layout_helper';
import getOrgRef from 'src/utils/getOrgRef';
import getUserJobTitle from 'src/utils/getUserJobTitle';
import { useSelectedOrg } from '../useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const PracticesContext = createContext();

export const PracticesProvider = ({ children }) => {
  PracticesProvider.propTypes = {
    children: PropTypes.any
  };
  const [practices, setPractices] = useState([]);
  const [foldersOptions, setFoldersOptions] = useState(null);
  const [isLoadingPracticesData, setIsLoadingPracticesData] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();

  const orgId = useMemo(() => {
    if (currentUser?.type === 'org') {
      return currentUser.user_doc_id;
    }
    if (
      currentUser?.type === 'personal' &&
      selectedOrg &&
      selectedOrg !== 'null'
    ) {
      return selectedOrg;
    }
    return null;
  }, [currentUser, selectedOrg]);

  const userLabels = useMemo(() => {
    if (currentUser?.type === 'org') {
      return [];
    }
    if (currentUser && currentUser.type === 'personal') {
      const userJobTitle = getUserJobTitle(currentUser, selectedOrg);
      return userJobTitle ? [userJobTitle] : ['other'];
    }
    return [];
  }, [currentUser, selectedOrg]);

  const securityMeta = useMemo(() => {
    if (!currentUser || !orgId) return null;

    return {
      userId: currentUser.user_doc_id,
      orgId,
      userType: currentUser.type,
      userLabels
    };
  }, [currentUser, orgId, userLabels]);

  const cacheKeys = useMemo(() => {
    if (!securityMeta) return {};
    return {
      folders: generateCacheKey(
        'folders',
        securityMeta.orgId,
        securityMeta.userId,
        { labels: securityMeta.userLabels }
      ),
      practices: generateCacheKey(
        'practices',
        securityMeta.orgId,
        securityMeta.userId,
        { labels: securityMeta.userLabels }
      )
    };
  }, [securityMeta]);

  const getPracticesCountOfFolder = useCallback(
    async (file_path) => {
      if (!currentUser || !selectedOrg) return 0;

      try {
        const ref = collection(db, `${file_path}/files`);
        if (currentUser.type === 'org') {
          const files = await getDocs(ref);
          return files.size || 0;
        }

        if (userLabels.length > 0) {
          const q = query(
            ref,
            where('labels', 'array-contains-any', userLabels)
          );
          const snap = await getDocs(q);
          return snap.size || 0;
        }
        return 0;
      } catch (error) {
        console.error('Error getting practices count:', error);
        return 0;
      }
    },
    [currentUser, selectedOrg, userLabels]
  );

  const fetchFolders = useCallback(
    async (forceFresh = false) => {
      if (!orgId || !currentUser || !securityMeta) return;

      try {
        const cacheKey = cacheKeys.folders;

        if (!forceFresh) {
          const isStale = await isDataStale(cacheKey);
          if (!isStale) {
            const cachedData = await getFromIndexedDB(
              'folders',
              cacheKey,
              securityMeta
            );
            if (cachedData) {
              setFoldersOptions(cachedData);
              return;
            }
          }
        }

        setIsLoadingPracticesData(true);
        const org_ref = getOrgRef(currentUser, selectedOrg);
        const foldersRef = collection(db, `orgs/${org_ref}/practices`);
        const orderedFolders = query(foldersRef, orderBy('order', 'asc'));
        const foldersDocs = await getDocs(orderedFolders);

        if (foldersDocs.empty) {
          setFoldersOptions([]);
          return;
        }

        const folderPromises = foldersDocs.docs.map(async (folder) => ({
          ...folder.data(),
          folder_id: folder.id,
          full_path: folder.ref.path,
          practices_count: await getPracticesCountOfFolder(folder.ref.path)
        }));

        const foldersData = await Promise.all(folderPromises);
        setFoldersOptions(foldersData);

        await saveToIndexedDB('folders', cacheKey, foldersData, securityMeta);
      } catch (error) {
        console.error('Error fetching folders:', error);
        setFoldersOptions([]);
      } finally {
        setIsLoadingPracticesData(false);
      }
    },
    [
      currentUser,
      selectedOrg,
      orgId,
      securityMeta,
      getPracticesCountOfFolder,
      cacheKeys.folders
    ]
  );

  const fetchPractices = useCallback(
    async (forceFresh = false) => {
      if (!orgId || !currentUser || !securityMeta) return;

      try {
        const cacheKey = cacheKeys.practices;

        if (!forceFresh) {
          const isStale = await isDataStale(cacheKey);
          if (!isStale) {
            const cachedData = await getFromIndexedDB(
              'practices',
              cacheKey,
              securityMeta
            );
            if (cachedData) {
              setPractices(cachedData);
              return;
            }
          }
        }

        setIsLoadingPracticesData(true);
        const retrievePracticesFunc = httpsCallable(
          functions,
          'secondGenretrievePractices'
        );

        const res = await retrievePracticesFunc({
          org_id: orgId,
          user_doc_id: currentUser.user_doc_id,
          labels: userLabels
        });

        let practicesData = [];
        if (res.data && res.data.filesDetails) {
          practicesData = res.data.filesDetails;
          setPractices(practicesData);
        } else {
          setPractices([]);
        }

        await saveToIndexedDB(
          'practices',
          cacheKey,
          practicesData,
          securityMeta
        );
      } catch (error) {
        console.error('Error fetching practices:', error);
        setPractices([]);
      } finally {
        setIsLoadingPracticesData(false);
      }
    },
    [
      currentUser,
      selectedOrg,
      orgId,
      securityMeta,
      userLabels,
      cacheKeys.practices
    ]
  );

  const refreshFoldersAndPractices = useCallback(async () => {
    if (!securityMeta || !cacheKeys) return;

    try {
      await Promise.all([
        deleteFromIndexedDB('folders', cacheKeys.folders),
        deleteFromIndexedDB('practices', cacheKeys.practices)
      ]);

      await Promise.all([fetchFolders(true), fetchPractices(true)]);
    } catch (error) {
      console.error('Error refreshing data:', error);
      throw error;
    }
  }, [fetchFolders, fetchPractices, securityMeta, cacheKeys]);

  useEffect(() => {
    if (currentUser && selectedOrg) {
      fetchFolders();
      fetchPractices();
    }
  }, [currentUser, selectedOrg, fetchFolders, fetchPractices]);

  const practicesData = useMemo(
    () => ({
      practices,
      setPractices,
      foldersOptions,
      setFoldersOptions,
      isLoadingPracticesData,
      refreshFoldersAndPractices,
      fetchFolders,
      fetchPractices
    }),
    [
      practices,
      setPractices,
      foldersOptions,
      setFoldersOptions,
      isLoadingPracticesData,
      refreshFoldersAndPractices,
      fetchFolders,
      fetchPractices
    ]
  );

  return (
    <PracticesContext.Provider value={practicesData}>
      {children}
    </PracticesContext.Provider>
  );
};

export const usePractices = () => useContext(PracticesContext);
