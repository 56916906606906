import {
  Anchor,
  CellTowerOutlined,
  HighQualityOutlined
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { uniqueId } from 'lodash';
import { useEffect, useRef } from 'react';
import theme from 'src/theme';

// Our Commitment Section
const OurCommitmentSection = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const cardsRef = useRef([]);

  useEffect(() => {
    const cards = cardsRef.current;

    gsap.fromTo(
      titleRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 70%',
          end: 'top 40%',
          toggleActions: 'play complete none none',
          scrub: false
        }
      }
    );

    cards.forEach((card, index) => {
      gsap.fromTo(
        card,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.6,
          delay: index * 0.15,
          scrollTrigger: {
            trigger: card,
            start: 'top 85%',
            end: 'top 65%',
            toggleActions: 'play complete none none',
            scrub: false
          }
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const commitments = [
    {
      title: 'שקיפות',
      id: 'transparency',
      icon: <CellTowerOutlined size={32} />,
      description: 'ערך השקיפות מנחה את כל פעילותנו והתקשורת שלנו'
    },
    {
      title: 'מקצועיות',
      id: 'professionalism',
      icon: <Anchor size={32} />,
      description: 'אנו מחויבים לסטנדרטים מקצועיים גבוהים בכל שירותינו'
    },
    {
      title: 'איכות',
      id: 'quality',
      icon: <HighQualityOutlined size={32} />,
      description:
        'הצבת מדדי איכות וסטנדרטים חדשים שיטיבו עם תנאי החיים של הדיירים'
    },
    {
      title: 'אכפתיות ודאגה',
      id: 'caring',
      icon: <CellTowerOutlined size={32} />,
      description: 'כולל יחס אישי אנושי קשוב ותומך'
    },
    {
      title: 'יעילות והתייעלות',
      id: 'efficiency',
      icon: <Anchor size={32} />,
      description: 'תמיד נשאף ללמוד ולהשתפר ולמצל את המשאבים בחוכמה'
    }
  ];

  return (
    <Box
      ref={sectionRef}
      sx={{
        py: { xs: 8, md: 12 },
        position: 'relative',
        background: theme.palette.background.default,
        overflow: 'hidden'
      }}
    >
      {/* Decorative wave elements */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0.05,
          zIndex: 0,
          background:
            'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="%23000000" fill-opacity="1" d="M0,192L48,208C96,224,192,256,288,234.7C384,213,480,139,576,117.3C672,96,768,128,864,138.7C960,149,1056,139,1152,144C1248,149,1344,171,1392,181.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"%3E%3C/path%3E%3C/svg%3E")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          transform: 'scaleY(-1)'
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          ref={titleRef}
          sx={{
            textAlign: 'center',
            mb: 6,
            position: 'relative'
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 800,
              color: theme.palette.primary.main,
              display: 'inline-block',
              position: 'relative',
              pb: 2,
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '80px',
                height: '4px',
                backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`
              }
            }}
          >
            עובדי מגדלור ייעוץ והכוונה מתחייבים ל
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {commitments.map((commitment, index) => (
            <Grid
              key={`${commitment.id}-${uniqueId()}`}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <Card
                ref={(el) => (cardsRef.current[index] = el)}
                sx={{
                  borderRadius: '16px',
                  height: '100%',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease-in-out',
                  overflow: 'hidden',
                  position: 'relative',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: `0 20px 40px ${alpha(
                      theme.palette.primary.main,
                      0.15
                    )}`
                  },
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '5px',
                    background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`
                  }
                }}
              >
                <CardContent sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      mb: 2,
                      background: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main
                    }}
                  >
                    {commitment.icon}
                  </Box>

                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      mb: 1.5,
                      color: theme.palette.text.primary
                    }}
                  >
                    {commitment.title}
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.text.secondary,
                      lineHeight: 1.6
                    }}
                  >
                    {commitment.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurCommitmentSection;
